import { createSlice } from '@reduxjs/toolkit'

export const sidebarSizeSlice = createSlice({
    name: 'sidebar',
    initialState: {
        value: 'lg',
    },
    reducers: {
        setSidebarSize: (state, action) => {
            if (document.documentElement) {
                document.documentElement.setAttribute("data-sidebar-size", action.payload)
            }
            state.value = action.payload
        },
    }
})

export const { setSidebarSize } = sidebarSizeSlice.actions

export default sidebarSizeSlice.reducer