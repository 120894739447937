import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import axios from "axios"
import qs from 'qs'
import Swal from 'sweetalert2'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {
  MRT_Localization_ID,
} from 'material-react-table/locales/id'
import {
  MRT_Localization_EN,
} from 'material-react-table/locales/en'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  hidePreloader,
  showPreloader,
  twoDecimalFormat,
  decimalFormat,
  dateSortingFn,
} from '../library/helper'

import DateCell from '../components/DateCell'

export default function Emission() {
  const { t, i18n } = useTranslation()
  const account = useSelector((state) => state.account.value)

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    showPreloader()

    const getResponse = async () => {
      const data = {}

      const options = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          gwp: new URLSearchParams(window.location.search).get('gwp'),
          ...data,
        }),
        url: `${process.env.REACT_APP_BASE_SERVER}/emission/get_all`
      };
      const response = await axios(options)
      if (response.data.success) {
        const record = response.data.data
        setTableData(record)
      }
    }

    getResponse()

    hidePreloader()
  }, [])

  const onDeleteCall = async (data) => {
    const options = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission/delete`
    };
    const response = await axios(options);
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  }

  const onDelete = async (id) => {
    let invalidData = true;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          'id': id,
        };
        const result = await onDeleteCall(data)
        if (result) {
          invalidData = false;
        }

        if (invalidData) {
          Swal.fire({
            title: '',
            html: '<p>Cannot delete data, please try again later</p>',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        } else {
          Swal.fire({
            title: 'Deleted',
            text: "Your data has been deleted.",
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        }
      }
    })
  }

  const columns = useMemo(
    () => {
      return [
        {
          header: t('emission.table.label1'),
          size: 100,
          accessorKey: 'record_date',
          // Transform data before processing so sorting works
          accessorFn: (row) => new Date(row.record_date_time),
          Cell: ({ row }) => <DateCell dateTime={row.original.record_date_time} />,
          sortingFn: dateSortingFn,
        },
        {
          accessorKey: 'record_location',
          header: t('emission.table.label2'),
          size: 100,
          Cell: ({ cell }) => cell.getValue(), // optional custom cell render
        },
        {
          accessorKey: 'record_category',
          header: t('emission.table.label3'),
          Cell: ({ cell, row }) => <>
            {i18n.language === 'id' ? row.original.record_category_id : cell.getValue()}
          </>,
        },
        {
          accessorKey: 'record_assets',
          header: t('emission.table.label4'),
          size: 100,
        },
        {
          accessorKey: 'record_total',
          header: t('emission.table.label7'),
          size: 100,
          Cell: ({ cell, row }) => <div className="d-flex flex-column flex-wrap gap-2">
            <span>
              {twoDecimalFormat(cell.getValue(), i18n.language)} tCO<sub>2</sub>e
            </span>
          </div>, // optional custom cell render
        },
        {
          accessorKey: 'record_amount',
          header: t('emission.table.label5'),
          size: 100,
          Cell: ({ cell, row }) => <div className="d-flex flex-column flex-wrap gap-2">
            <span>
              {decimalFormat(row.original.record_amount, i18n.language)} {row.original.record_unit}
            </span>
          </div>, // optional custom cell render
        },
        {
          accessorKey: 'record_uuid',
          header: t('emission.table.label6'),
          enableSorting: false,
          enableColumnFilter: false,
          enableGrouping: false,
          size: 100,
          Cell: ({ cell }) => <div className="d-flex flex-wrap gap-2">
            <Link to={'/emission/update/' + cell.getValue()} className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-pencil-fill"></i></Link>
            <button onClick={() => onDelete(cell.getValue())} type="button" className="btn btn-warning btn-icon waves-effect waves-light"><i className="ri-delete-bin-2-fill"></i></button>
          </div>,
        },
      ]
    },
    [i18n.language],
  )

  // Pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data: tableData, // must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableGrouping: true,
    groupedColumnMode: 'reorder',
    enableColumnOrdering: true, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? MRT_Localization_ID : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
    },
  })

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{t('menu.label21')} - Scope 1 & 2</h4>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{t('general.read')}</h5>
                <br /><br />
                <div className="d-flex flex-wrap gap-2">
                  <Link to={'/emission/create'} className="btn btn-success">
                    {t('general.btnCreate')}
                  </Link>
                  {account['client_subscription'] > 1 ? <Link to={'/emission/create-in-bulk'} className="btn btn-success">
                    {t('general.btnCreateInBulk')}
                  </Link> : ""}
                </div>
              </div>
              <div className="card-body">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MaterialReactTable table={table} />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
