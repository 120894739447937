import React, {
  useState,
  useEffect,
} from 'react'
import {
  Navigate,
  useParams,
} from 'react-router-dom'
import axios from "axios"
import DatePicker from "react-datepicker"
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_PATHS,
} from '../../../library/helper'

import Attachment from '../../../components/Attachment'
import NotFound from '../../../components/NotFound'
import LoadingCard from '../../../components/LoadingCard'

const AttachmentChildren = ({
  isLoading,
  attachmentData,
  setSelectedFile,
  onAttachFile,
  selectedFile,
  onChangeFile,
}) => {
  const { t } = useTranslation()
  const [attachment, setAttachment] = useState({})

  useEffect(() => {
    if (attachmentData.type) {
      setAttachment(attachmentData)
    } else {
      setAttachment({})
    }
  }, [attachmentData])

  useEffect(() => {
    if (attachment.blob) {
      const file = new File(
        [attachment.blob],
        attachment.name,
        { type: attachment.type },
      )
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      document.getElementById('formFile').files = dataTransfer.files
      setSelectedFile(file)
    }
  }, [attachment])

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '7rem auto',
        }}
      >
        <div
          style={{
            padding: '5px 0',
          }}
        >
          <button
            onClick={onAttachFile}
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{
              width: '100%',
            }}
            disabled={isLoading}
          >
            {t('emission.form.label17')}
          </button>
        </div>
        <div
          style={{
            padding: '5px 8px 5px',
            alignSelf: 'center',
          }}
        >
          {isLoading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="text-muted">
              {selectedFile?.name}
            </span>
          )
          }
        </div>
        <div
          style={{
            padding: '5px 0',
          }}
        >
          {attachment?.url ? (
            <a
              className={`btn btn-info ${isLoading ? 'btn-soft-dark pe-none' : ''}`}
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              href={attachment.url}
              target="_blank"
              rel="noreferrer"
              download={attachment.name}
            >
              {t('dashboard.btnDownload')}
            </a>
          ) : (
            <p>{t('emission.noAttachment')}</p>
          )}
        </div>
      </div>
      <input
        onChange={onChangeFile}
        style={{ display: "none" }}
        className="form-control"
        type="file"
        id="formFile"
        accept="image/*,.pdf,.doc"
      />

    </>
  )
}

export default function EmissionScope3Category6Update() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()

  const [redirectEmission, setRedirectEmission] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [selectedCompanyId, setSelectedCompanyId] = useState(0)
  const [locationList, setLocationList] = useState([])
  const [selectedLocationId, setSelectedLocationId] = useState(0)
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)
  const [activity, setActivity] = useState('')
  const [countryList, setCountryList] = useState([])
  const [transportationModeNameList, setTransportationModeNameList] = useState([])
  const [selectedTransportationModeName, setSelectedTransportationModeName] = useState('')
  const [transportationModeUnitList, setTransportationModeUnitList] = useState([])
  const [selectedTransportationModeUnitId, setSelectedTransportationModeUnitId] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [distance, setDistance] = useState(0)
  const [selectedStartDate, setSelectedStartDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [numberOfHotelRooms, setNumberOfHotelRooms] = useState(0)
  const [departureCity, setDepartureCity] = useState('')
  const [departureCountryId, setDepartureCountryId] = useState(0)
  const [destinationCity, setDestinationCity] = useState('')
  const [destinationCountryId, setDestinationCountryId] = useState(0)
  const [attachmentId, setAttachmentId] = useState('')

  const [isNotFound, setIsNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getIntitialData = async () => {

      try {
        setIsLoading(true)
        const responseInitialData = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            uuid: id,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/business-travel-emission-record-lookup`
        })
        if (responseInitialData.data.ok) {
          const {
            record,
            company_list,
            location_list,
            business_travel_category,
            country_list,
            all_transportation_mode_list,
            transportation_mode_unit_list,
            transportation_mode_name_list,
          } = responseInitialData.data.data

          setActivity(record.record_assets)

          setSelectedCategoryId(business_travel_category.category_id)

          setSelectedTransportationModeName(record.transport_mode_emission_name)
          setTransportationModeNameList(transportation_mode_name_list || [])

          setSelectedTransportationModeUnitId(record.transport_mode_unit_id)

          const transportationFactorIds = all_transportation_mode_list
            .filter(list => list.emission_name === record.transport_mode_emission_name)
            .map(list => list.emission_factor_id)

          setTransportationModeUnitList(transportation_mode_unit_list
            .filter(list => transportationFactorIds.includes(list.unit_factor)))

          setDistance(record.record_distance)

          setQuantity(record.record_quantity)

          setAttachmentId(record.attachment_id)

          const datePickDeparture = new Date(
            new Date(record.record_date).getFullYear(),
            new Date(record.record_date).getMonth(),
            new Date(record.record_date).getDate(),
          )
          setSelectedStartDate(datePickDeparture || null)

          if (record.record_misc?.end_date) {
            const datePickEnd = new Date(
              new Date(record.record_misc.end_date).getFullYear(),
              new Date(record.record_misc.end_date).getMonth(),
              new Date(record.record_misc.end_date).getDate(),
            )
            setSelectedEndDate(datePickEnd || null)
          }

          setNumberOfHotelRooms(record.record_number_of_rooms || 0)

          setDepartureCity(record.record_misc?.['departure_city'] || '')

          setDepartureCountryId(record.record_misc?.['departure_country_id'] || '')

          setDestinationCity(record.record_misc?.['destination_city'] || '')

          setDestinationCountryId(record.hotel_stay_emission_country || 0)

          setCompanyList(company_list)

          setSelectedCompanyId(record.company_id)

          setSelectedLocationId(record.office_id)

          setLocationList(location_list)

          setCategoryList([business_travel_category])

          setCountryList(country_list)
        }
      } catch (error) {
        if (error?.response?.data?.status === 404) {
          setIsNotFound(true)
        } else {
          let message
          if (typeof error.response?.data?.message === 'string') {
            message = error.response.data.message
          } else if (error.message) {
            message = error.message
          }
          Swal.fire({
            icon: 'error',
            title: '',
            html: `<p style="font-size:0.75rem">${message}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    getIntitialData()
  }, [])

  const onSaveRecord = async (e) => {
    try {
      let data = new FormData()
      data.append('uuid', id)
      data.append('companyId', selectedCompanyId)
      data.append('locationId', selectedLocationId)
      data.append('categoryId', selectedCategoryId)
      data.append('departureCity', departureCity)
      data.append('departureCountryId', departureCountryId)
      data.append('destinationCity', destinationCity)
      data.append('destinationCountryId', destinationCountryId)
      data.append('activity', activity)
      data.append('transportationModeName', selectedTransportationModeName)
      data.append('transportationModeUnitId', selectedTransportationModeUnitId)
      data.append('quantity', quantity)
      data.append('distance', distance)
      data.append('startDate', selectedStartDate)
      data.append('endDate', selectedEndDate)
      data.append('numberOfHotelRooms', numberOfHotelRooms)
      if (selectedFile) data.append('attachment', selectedFile)

      const response = await axios({
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: data,
        url: `${process.env.REACT_APP_BASE_SERVER}/business-travel-emission-record-submission`
      })

      if (response.data.ok) {
        Swal.fire({
          title: '',
          html: '<p>Successfully record emissions</p>',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            setRedirectEmission(true)
          }
        })
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onAttachFile = async () => {
    document.getElementById('formFile').click()
  }

  const onChangeFile = async (e) => {
    const files = e.target.files
    setSelectedFile(files?.[0])
  }

  const onSelect = async (e) => {
    try {
      const selectedValue = Number(e.target.value)
      if (e.target.name === 'companyId') {
        setSelectedCompanyId(selectedValue)
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            companyId: selectedValue,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/client-office-lookup`
        })
        if (response.data.ok) {
          setLocationList(response.data.data)
          setSelectedLocationId(0)
        }
      } else if (e.target.name === 'locationId') {
        setSelectedLocationId(selectedValue)
      } else if (e.target.name === 'categoryId') {
        setSelectedCategoryId(selectedValue)
        setSelectedTransportationModeName('')
      } else if (e.target.name === 'departureCountryId') {
        setDepartureCountryId(selectedValue)
      } else if (e.target.name === 'destinationCountryId') {
        setDestinationCountryId(selectedValue)
      } else if (e.target.name === 'transportationModeName') {
        setSelectedTransportationModeName(e.target.value)
        setSelectedTransportationModeUnitId(0)
        const unitLookup = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            emissionName: e.target.value,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/emission-factor-unit-lookup`
        })
        if (unitLookup.data.ok) {
          setTransportationModeUnitList(unitLookup.data.data)
        }
      } else if (e.target.name === 'transportationModeUnitId') {
        setSelectedTransportationModeUnitId(selectedValue)
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onInputChange = (e) => {
    if (e.target.name === 'activity') {
      setActivity(e.target.value)
    } else if (e.target.name === 'departureCity') {
      setDepartureCity(e.target.value)
    } else if (e.target.name === 'destinationCity') {
      setDestinationCity(e.target.value)
    }
  }

  const onInputNumericFromatChange = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      if (sourceInfo.event.target.name === 'quantity') {
        setQuantity(values.value)
      } else if (sourceInfo.event.target.name === 'distance') {
        setDistance(values.value)
      } else if (sourceInfo.event.target.name === 'numberOfHotelRooms') {
        setNumberOfHotelRooms(values.value)
      }
    }
  }

  let distanceUnit = ''
  let transportationApproach = ''
  let quantityLabel = ''
  if (selectedTransportationModeUnitId) {
    const selectedTransportationModeUnit = transportationModeUnitList
      .find(list => list.unit_id === selectedTransportationModeUnitId)
    if (selectedTransportationModeUnit) {
      const splitLabel = selectedTransportationModeUnit.unit_label
        .split("-") // Split the string by "-"
      distanceUnit = `(${splitLabel[1]})`
      transportationApproach = splitLabel[0]
      quantityLabel = `${t('emission.form.numberOf')} ${transportationApproach}`
    }
  } else {
    quantityLabel = t('emission.form.quantity')
  }

  if (redirectEmission) {
    return <Navigate to={ROUTE_PATHS.scope3category6} />
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {t("menu.scope3Category6Title")}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">

            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t('general.btnUpdate')}
                </h4>
              </div>

              {isLoading ? (
                <div className="card-body">
                  <LoadingCard />
                </div>
              ) : (
                <>
                  {isNotFound ? (
                    <div className="card-body">
                      <NotFound />
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="companyId"
                              className="form-label"
                            >
                              {t('emission.form.label1')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="companyId"
                              name="companyId"
                              onChange={onSelect}
                              value={selectedCompanyId}
                              className="form-select mb-3"
                            >
                              {selectedCompanyId === 0 && (
                                <option
                                  key={0}
                                  value={0}
                                >
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {companyList.map(item => {
                                return (
                                  <option
                                    key={item.company_id}
                                    value={item.company_id}
                                  >
                                    {item.company_name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        {selectedCompanyId > 0 && (
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="locationId"
                                className="form-label"
                              >
                                {t('emission.table.label2')} <span className="text-danger">*</span>
                              </label>
                              <select
                                id="locationId"
                                name="locationId"
                                onChange={onSelect}
                                value={selectedLocationId}
                                className="form-select mb-3"
                              >
                                {selectedLocationId === 0 && (
                                  <option
                                    key={0}
                                    value={0}
                                  >
                                    {t('emission.form.select')}
                                  </option>
                                )}
                                {locationList.map(item => {
                                  return (
                                    <option
                                      key={item.office_id}
                                      value={item.office_id}
                                    >
                                      {item.office_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="categoryId"
                              className="form-label"
                            >
                              {t('emission.form.label2')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="categoryId"
                              name="categoryId"
                              onChange={onSelect}
                              value={selectedCategoryId}
                              className="form-select mb-1"
                            >
                              {selectedCategoryId === 0 && (
                                <option
                                  key={0}
                                  value={0}
                                >
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {categoryList.map(item => {
                                return (
                                  <option
                                    key={item.category_id}
                                    value={item.category_id}
                                  >
                                    {
                                      i18n.language === 'id'
                                        ? item.category_name_id
                                        : item.category_name
                                    }
                                  </option>
                                )
                              })}
                            </select>
                            <p>
                              &nbsp;{t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub">EPA 2023</a>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="period" className="form-label">
                              {t('emission.form.period')}
                            </label>
                            <div>
                              <DatePicker
                                id="period"
                                name="period"
                                selected={selectedStartDate}
                                dateFormat="MMMM yyyy"
                                className="form-select mb-3"
                                showMonthYearPicker
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="departureCity" className="form-label">
                              {t('emission.form.departureCity')} <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="departureCity"
                              name="departureCity"
                              onChange={onInputChange}
                              value={departureCity}
                              className="form-control"
                              placeholder={t('emission.form.enter')}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="departureCountryId" className="form-label">
                              {t('emission.form.departureCountry')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="departureCountryId"
                              name="departureCountryId"
                              onChange={onSelect}
                              value={departureCountryId}
                              className="form-select mb-0"
                            >
                              {!departureCountryId && (
                                <option value={0} key={0}>
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {
                                countryList.length > 0 &&
                                countryList.map(item => {
                                  return (
                                    <option
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="destinationCity" className="form-label">
                              {t('emission.form.destinationCity')} <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="destinationCity"
                              name="destinationCity"
                              onChange={onInputChange}
                              value={destinationCity}
                              className="form-control"
                              placeholder={t('emission.form.enter')}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="destinationCountryId" className="form-label">
                              {t('emission.form.destinationCountry')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="destinationCountryId"
                              name="destinationCountryId"
                              onChange={onSelect}
                              value={destinationCountryId}
                              className="form-select mb-0"
                            >
                              {!destinationCountryId && (
                                <option value={0} key={0}>
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {
                                countryList.length > 0 &&
                                countryList.map(item => {
                                  return (
                                    <option
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="activity" className="form-label">
                              {t('emission.form.activity')} <span className="text-danger">*</span>
                            </label>
                            <input
                              id="activity"
                              name="activity"
                              onChange={onInputChange}
                              value={activity}
                              className="form-control"
                              placeholder={t('emission.form.activity')}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="transportationModeName" className="form-label">
                              {t('emission.form.transportationMode')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="transportationModeName"
                              name="transportationModeName"
                              onChange={onSelect}
                              value={selectedTransportationModeName}
                              className="form-select mb-0"
                            >
                              {(
                                selectedCategoryId === 0 ||
                                !selectedTransportationModeName
                              ) && (
                                  <option value={0} key={0}>
                                    {t('emission.form.select')}
                                  </option>
                                )}
                              {
                                transportationModeNameList.length > 0 &&
                                transportationModeNameList.map(item => {
                                  return (
                                    <option
                                      key={item}
                                      value={item}
                                    >
                                      {item}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="transportationModeUnitId"
                              className="form-label"
                            >
                              {t('emission.form.unit')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="transportationModeUnitId"
                              name="transportationModeUnitId"
                              onChange={onSelect}
                              value={selectedTransportationModeUnitId}
                              className="form-select mb-0"
                            >
                              {(
                                selectedTransportationModeUnitId === 0 ||
                                !selectedTransportationModeName
                              ) && (
                                  <option value={0} key={0}>
                                    {t('emission.form.select')}
                                  </option>
                                )}
                              {
                                selectedTransportationModeName &&
                                transportationModeUnitList.length > 0 &&
                                transportationModeUnitList.map(item => {
                                  return (
                                    <option
                                      key={item.unit_id}
                                      value={item.unit_id}
                                    >
                                      {item.unit_label}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="quantity"
                              className="form-label"
                            >
                              {quantityLabel} <span className="text-danger">*</span>
                            </label>
                            <NumericFormat
                              id="quantity"
                              name="quantity"
                              className="form-control"
                              value={quantity}
                              onValueChange={onInputNumericFromatChange}
                              decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                              thousandSeparator={i18n.language === 'id' ? '.' : ','}
                              placeholder={t('emission.form.enter')}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="distance"
                              className="form-label"
                            >
                              {t('emission.form.distance')} {distanceUnit} <span className="text-danger">*</span>
                            </label>
                            <NumericFormat
                              id="distance"
                              name="distance"
                              className="form-control"
                              value={distance}
                              onValueChange={onInputNumericFromatChange}
                              decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                              thousandSeparator={i18n.language === 'id' ? '.' : ','}
                              placeholder={t('emission.form.enter')}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="startDate" className="form-label">
                              {t('emission.form.startDate')} <span className="text-danger">*</span>
                            </label>
                            <div>
                              <DatePicker
                                id="startDate"
                                name="startDate"
                                selected={selectedStartDate}
                                onChange={(date) => setSelectedStartDate(date)}
                                dateFormat="dd MMMM yyyy"
                                className="form-select"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="endDate" className="form-label">
                              {t('emission.form.endDate')} <span className="text-danger">*</span>
                            </label>
                            <div>
                              <DatePicker
                                id="endDate"
                                name="endDate"
                                selected={selectedEndDate}
                                onChange={(date) => setSelectedEndDate(date)}
                                dateFormat="dd MMMM yyyy"
                                className="form-select"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label htmlFor="numberOfHotelRooms" className="form-label">
                              {t('emission.form.numberOfHotelRooms')}
                            </label>
                            <NumericFormat
                              id="numberOfHotelRooms"
                              name="numberOfHotelRooms"
                              className="form-control"
                              value={numberOfHotelRooms}
                              onValueChange={onInputNumericFromatChange}
                              decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                              thousandSeparator={i18n.language === 'id' ? '.' : ','}
                              placeholder={t('emission.form.enter')}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="formFile" className="form-label">
                            {t('emission.form.label15')}
                          </label>
                          <p className="text-muted">
                            {t('emission.form.label16')}
                          </p>
                          <div className="col-md-6">
                            <Attachment id={attachmentId}>
                              {({ attachment, isLoading }) => {
                                return <AttachmentChildren
                                  setSelectedFile={setSelectedFile}
                                  onAttachFile={onAttachFile}
                                  selectedFile={selectedFile}
                                  onChangeFile={onChangeFile}
                                  attachmentData={attachment}
                                  isLoading={isLoading}
                                />
                              }}
                            </Attachment>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="text-end">
                          <button
                            onClick={onSaveRecord}
                            type="submit"
                            className="btn btn-primary"
                          >
                            {t('general.btnSave')}
                          </button>
                        </div>
                      </div>
                    </div >
                  )}
                </>
              )}

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
