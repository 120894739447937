import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import BoxItem from '../Cards/BoxItem'

import iconTotalEmission from '../../../assets/icon-total-emission.svg'
import iconHighestEmission from '../../../assets/icon-higest-emission.svg'
import iconHighestContributor from '../../../assets/icon-higest-contributor.svg'
import iconCarbonIntensity from '../../../assets/icon-carbon-intensity.svg'

import { twoDecimalFormat } from '../../../library/helper'


function UpperCards({
  companyOptions,
  selectedYear,
}) {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    const getData = async () => {
      const response = await axios({
        withCredentials: true,
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url: `${process.env.REACT_APP_BASE_SERVER}/record-summaries-lookup`,
        signal: controller.signal,
        params: {
          year: selectedYear,
          gwp: new URLSearchParams(window.location.search).get('gwp'),
        },
      })
      if (response.data.ok) {
        const {
          totalEmission,
          highestEmissionCategory,
          companyCount,
          highestContributorToTotalEmission,
          carbonIntensity,
          revenueUnit,
        } = response.data.data
        setData({
          totalEmission: totalEmission?.total,
          highestEmissionCategoryEn: highestEmissionCategory?.categoryName,
          highestEmissionCategoryId: highestEmissionCategory?.categoryNameId,
          companyCount: companyCount?.companyCount,
          highestContributorToTotalEmission: highestContributorToTotalEmission?.companyName,
          carbonIntensity,
          revenueUnit: revenueUnit?.unitLabel,
        })
      }
    }
    if (selectedYear) {
      getData()
    }

    return () => controller.abort()
  }, [selectedYear, companyOptions])

  return (
    <div className='gap-3 d-flex flex-wrap w-100'>
      <BoxItem
        borderColor={'#438375'}
        label={t('dashboard.card.title1')}
        value={
          <Fragment>
            <div
              className='align-items-center d-flex justify-content-center p-2'
              style={{
                backgroundColor: typeof data.totalEmission === 'number' ? '#438375' : '#7777',
                borderRadius: 8,
                height: 40,
                width: 40,
              }}
            >
              <img
                src={iconTotalEmission}
                alt="iconTotalEmission"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </div>
            {
              typeof data.totalEmission === 'number'
                ? <p
                  className='fw-semibold mb-0'
                  style={{ fontSize: 12 }}
                >
                  {twoDecimalFormat(data.totalEmission, i18n.language)}
                  <span className='fw-normal'> tCO<sub>2</sub>e</span>
                </p>
                : <p
                  className='fw-semibold mb-0 text-muted'
                  style={{ fontSize: 12 }}
                >-</p>
            }
          </Fragment>
        }
      />
      <BoxItem
        borderColor={'#E27F7F'}
        label={t('dashboard.card.title2')}
        value={
          <Fragment>
            <div
              className='align-items-center d-flex justify-content-center p-2'
              style={{
                backgroundColor: data.highestEmissionCategoryEn ? '#E27F7F' : '#7777',
                borderRadius: 8,
                height: 40,
                width: 40,
              }}
            >
              <img
                src={iconHighestEmission}
                alt="iconTotalEmission"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </div>
            {
              data.highestEmissionCategoryEn
                ? <p
                  className='fw-semibold mb-0'
                  style={{ fontSize: 12 }}
                >
                  {
                    i18n.language === 'id'
                      ? data.highestEmissionCategoryId || data.highestEmissionCategoryEn
                      : data.highestEmissionCategoryEn
                  }
                </p>
                : <p
                  className='fw-semibold mb-0 text-muted'
                  style={{ fontSize: 12 }}
                >-</p>
            }
          </Fragment>
        }
      />
      {
        data.companyCount > 1 && (
          <BoxItem
            borderColor={'#FFDE59'}
            label={t('dashboard.card.title3')}
            value={
              <Fragment>
                <div
                  className='align-items-center d-flex justify-content-center p-2'
                  style={{
                    backgroundColor: data.highestContributorToTotalEmission ? '#FFDE59' : '#7777',
                    borderRadius: 8,
                    height: 40,
                    width: 40,
                  }}
                >
                  <img
                    src={iconHighestContributor}
                    alt="iconTotalEmission"
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </div>
                {
                  data.highestContributorToTotalEmission
                    ? <p
                      className='fw-semibold mb-0'
                      style={{ fontSize: 12 }}
                    >
                      {data.highestContributorToTotalEmission}
                    </p>
                    : <p
                      className='fw-semibold mb-0 text-muted'
                      style={{ fontSize: 12 }}
                    >-</p>
                }

              </Fragment>
            }
          />
        )
      }
      <BoxItem
        borderColor={'#013333'}
        label={t('dashboard.card.title4')}
        value={
          <Fragment>
            <div
              className='align-items-center d-flex justify-content-center p-2'
              style={{
                backgroundColor: typeof data.carbonIntensity === 'number' ? '#013333' : '#7777',
                borderRadius: 8,
                height: 40,
                width: 40,
              }}
            >
              <img
                src={iconCarbonIntensity}
                alt="iconTotalEmission"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </div>
            {
              typeof data.carbonIntensity === 'number'
                ? <p
                  className='fw-semibold mb-0'
                  style={{ fontSize: 12 }}
                >
                  {
                    data.carbonIntensity >= 0
                      ? twoDecimalFormat(data.carbonIntensity, i18n.language)
                      : null
                  }
                  <span className='fw-normal'>
                    {' '}tCO<sub>2</sub>e/{data.revenueUnit}
                  </span>
                </p>
                : <p
                  className='fw-semibold mb-0 text-muted'
                  style={{ fontSize: 12 }}
                >-</p>
            }
          </Fragment>
        }
      />
    </div>
  )
}

export default UpperCards