import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import Swal from 'sweetalert2';

import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

import { withRouter } from '../components/withRouter'

import "react-datepicker/dist/react-datepicker.css";

import InputNumeric from '../components/InputNumeric'

export class CompanyUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectCompany: false,
            inputCompany: "",
            selectedBoundary: 0,
            inputBuilding: "",
            inputAddress: "",

            selectedCompany: 0,
            selectedCompanyGroup: 0,
            selectedOffice: 0,
            selectedDate: new Date(Date.now()),
            selectedScope: 0,
            selectedCategory: 0,
            selectedItem: 0,
            amountItem: 0,
            amountRevenue: "",

            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: "",
                    unit: 0,
                    multiplier: 1,
                }
            ],

            listCategory: [],
            listFactor: [],
            itemUnit: '',

            amountEmission: 0,
            countRecords: 1,

            revenueUnit: "",
            selectedID: "",
        }
    }

    componentDidMount = async () => {
        showPreloader();

        let listCompany = [];
        let listCompanyGroup = [];
        let listCategory = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];

        console.log("revenue create");
        const data = {}

        let tempRevenueUnit = "";

        const optionsSetting = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
        };
        const responseSetting = await axios(optionsSetting);
        if (responseSetting.data.success) {
            console.log(responseSetting.data.data)
            console.log(responseSetting.data.data[0].client_revenue_unit)
            tempRevenueUnit = responseSetting.data.data[0].client_revenue_unit;
        }

        const optionsGetCompany = {
            withCredentials: true,
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/client/boundary`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }
        console.log(listCompany)

        const optionsGetCompanyGroup = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/division`
        };
        const responseGetCompanyGroup = await axios(optionsGetCompanyGroup);
        if (responseGetCompanyGroup.data.success) {
            listCompanyGroup = responseGetCompanyGroup.data.data
        }
        console.log(listCompanyGroup)

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }
        console.log(listCategory)

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }
        console.log(listEmissionFactor)

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }
        console.log(listEmissionFactorUnit)

        const { id } = this.props.params

        const dataID = {
            'id': id,
        };
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataID),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company/read`
        };
        const response = await axios(options);
        if (response.data.success) {
            console.log(response.data.data)
            this.setState({
                inputCompany: response.data.data[0].company_name,
                selectedID: id,
                selectedCompanyGroup: response.data.data[0].company_group,
                selectedBoundary: response.data.data[0].company_shareholding,
            })
        } else {

        }

        this.setState({
            listCompany: listCompany,
            listCompanyGroup: listCompanyGroup,
            listCategory: listCategory,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
            selectedOffice: 0,
            revenueUnit: tempRevenueUnit,
        })

        hidePreloader();
    }

    onSelectCompany = async (e) => {
        let companyValue = e.target.value;
        console.log(companyValue)
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                selectedOffice: 0,
            })
        }
    }

    onSelectBoundary = async (e) => {
        let boundaryValue = e.target.value;
        console.log(boundaryValue)
        this.setState({
            selectedBoundary: boundaryValue,
        })
        console.log(this.state.selectedBoundary)
    }

    onSelectGroup = async (e) => {
        let groupValue = e.target.value;
        console.log(groupValue)
        this.setState({
            selectedCompanyGroup: groupValue,
        })
        console.log(this.state.selectedCompanyGroup)
    }

    onSelectCategory = async (e) => {
        /*let categoryValue = e;
        console.log(categoryValue)
        this.setState({
            selectedCategory: categoryValue,
        })*/
        let categoryValue = e.target.value;
        this.setState({
            selectedCategory: categoryValue,
        })
    }

    onSelectOffice = async (e) => {
        let officeValue = e.target.value;
        this.setState({
            selectedOffice: officeValue,
        })
    }

    onSelectDate = async (e) => {
        console.log(e);
        this.setState({
            selectedDate: e,
        })
    }

    onInsertRow = async () => {
        let data = this.state.listEmissionRecord;
        data.push({
            assets: "",
            factor: 0,
            amount: "",
            unit: 0,
            multiplier: 1,
        })
        this.setState({
            countRecords: this.state.countRecords + 1,
        })
    }

    onDeleteRow = async (index) => {
        let data = this.state.listEmissionRecord;
        data.splice(index, 1)
        this.setState({
            countRecords: this.state.countRecords - 1,
        })
    }

    onInputAsset = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].assets = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onSelectEmission = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].factor = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onSelectUnit = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].unit = e.target.value;
        let listEmissionFactorUnit = this.state.listEmissionFactorUnit;
        let filteredUnit = listEmissionFactorUnit.filter(
            // eslint-disable-next-line
            item => item.unit_id == e.target.value
        )
        data[index].multiplier = filteredUnit[0].unit_multiplier;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onInputAmount = async (e) => {
        let amount = e.target.value;
        this.setState({
            amountRevenue: amount
        })

        console.log(this.state.amountRevenue)
    }

    onInputCompany = async (e) => {
        let companyName = e.target.value;
        this.setState({
            inputCompany: companyName
        })

        console.log(this.state.inputCompany)
    }

    onInputBuilding = async (e) => {
        let buildingValue = e.target.value;
        this.setState({
            inputBuilding: buildingValue,
        })
    }

    onInputShareholding = (values, sourceInfo) => {
        if (sourceInfo?.event?.target?.name && values) {
            this.setState({
                selectedBoundary: values.value,
            })
        }
    }

    onInputAddress = async (e) => {
        let addressValue = e.target.value;
        this.setState({
            inputAddress: addressValue,
        })
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company/update`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onSaveRecord = async (e) => {
        let invalidData = true;

        let companyName = this.state.inputCompany;
        let companyGroup = this.state.selectedCompanyGroup;
        let companyBoundary = this.state.selectedBoundary;
        //let building = this.state.inputBuilding;
        //let address = this.state.inputAddress;

        //console.log(companyName)
        //console.log(companyGroup)
        //console.log(companyBoundary)
        //console.log(building)
        //console.log(address)

        // eslint-disable-next-line
        if (companyName != "" && companyGroup != 0 && companyBoundary != 0) {

            const data = {
                'name': this.state.inputCompany,
                'id': this.state.selectedID,
                'group': this.state.selectedCompanyGroup,
                'boundary': this.state.selectedBoundary,
                'building': this.state.inputBuilding,
                'address': this.state.inputAddress,
            };

            const result = this.onSaveRecordCall(data);
            if (result) {
                invalidData = false;
            }

        } else {
            invalidData = true;
        }

        /*let listCategory = this.state.listCategory;
        let filteredCategory = listCategory.filter(
            // eslint-disable-next-line
            item => item.category_id == this.state.selectedCategory
        )
        console.log(filteredCategory)
        this.state.listEmissionRecord.forEach(element => {
            let listEmissionFactor = this.state.listEmissionFactor;
            let filteredEmissionFactor = listEmissionFactor.filter(
                // eslint-disable-next-line
                item => item.emission_factor_id == element.factor && item.fc_category == this.state.selectedCategory
            )
            console.log(filteredEmissionFactor)

            const data = {
                'token': jwtDecode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)).token,
                'scope': filteredCategory[0].category_scope,
                'category': this.state.selectedCategory,
                'factor': element.factor,
                'unit': element.unit,
                'location': this.state.selectedOffice,
                'assets': element.assets,
                'date': this.state.selectedDate,
                'amount': element.amount,
                'total': element.amount * element.multiplier * filteredEmissionFactor[0].emission_factor,
            };
            console.log(data)
            const result = this.onSaveRecordCall(data);
            if(result){
                invalidData = false;
            }
        });*/
        if (invalidData) {
            Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        } else {
            Swal.fire({
                title: '',
                html: '<p>Successfully record company</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        redirectCompany: true
                    })
                }
            })
        }
    }

    render() {
        const { redirectCompany } = this.state;
        const { listCompanyGroup, selectedCompanyGroup } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor } = this.state;

        if (redirectCompany) {
            return <Navigate to={'/company'} />
        }

        let optionCompany = [];
        optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
        // eslint-disable-next-line
        if (listCompany != undefined) {
            if (listCompany.length > 0) {
                listCompany.map(async (item, index) => {
                    optionCompany.push(<option key={index + 1} value={item.boundary_id}>{item.boundary_name}</option>)
                })
            }
        }

        let optionCompanyGroup = [];
        optionCompanyGroup.push(selectedCompanyGroup > 0 ? null : <option key={0} value={0}>Select Business Division</option>)
        // eslint-disable-next-line
        if (listCompanyGroup != undefined) {
            if (listCompanyGroup.length > 0) {
                listCompanyGroup.map(async (item, index) => {
                    optionCompanyGroup.push(<option key={index + 1} value={item.group_id}>{item.group_name}</option>)
                })
            }
        }

        let optionOffice = [];
        optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>Select Location</option>)
        // eslint-disable-next-line
        if (listOffice != undefined) {
            if (listOffice.length > 0) {
                listOffice.map(async (item, index) => {
                    optionOffice.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
                })
            }
        }

        let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>Select Source</option>)
        // eslint-disable-next-line
        if (listCategory != undefined) {
            if (listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
                    // eslint-disable-next-line
                    /*if(item.category_id == selectedCategory){
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }else{
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }*/
                })
            }
        }

        let optionEmissionFactor = [];
        // eslint-disable-next-line
        if (selectedCategory != 0) {
            // eslint-disable-next-line
            if (listEmissionFactor != undefined) {
                if (listEmissionFactor.length > 0) {
                    listEmissionFactor.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.fc_category == selectedCategory) {
                            optionEmissionFactor.push(
                                <option key={index + 1} value={item.emission_factor_id}>{item.emission_name}</option>
                            )
                        }
                    })
                }
            }
        }

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t('menu.label622')}</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {this.props.t('general.btnUpdate')} Company
                                    </h4>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="company"
                                                    className="form-label"
                                                >
                                                    {this.props.t('menu.label622')}
                                                </label>
                                                <input
                                                    id="company"
                                                    name="company"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.inputCompany}
                                                    onChange={(e) => this.onInputCompany(e)}
                                                    placeholder={this.props.t('emission.form.enter')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="company"
                                                    className="form-label"
                                                >
                                                    {this.props.t('menu.label621')}
                                                </label>
                                                <select
                                                    onChange={e => this.onSelectGroup(e)}
                                                    value={this.state.selectedCompanyGroup}
                                                    id="company"
                                                    name="company"
                                                    className="form-select mb-3"
                                                >
                                                    {optionCompanyGroup}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="shareholding"
                                                    className="form-label"
                                                >
                                                    {this.props.t('company.label1')}
                                                </label>
                                                <div className="input-group">
                                                    <InputNumeric
                                                        id="shareholding"
                                                        name="shareholding"
                                                        isAllowed={(values) => {
                                                            if (!values.value) return true
                                                            const { floatValue } = values
                                                            return floatValue < 101
                                                        }}
                                                        value={this.state.selectedBoundary}
                                                        onValueChange={this.onInputShareholding}
                                                        placeholder={this.props.t('emission.form.enter')}
                                                    />
                                                    <span
                                                        className="input-group-text"
                                                        id="basic-addon2"
                                                    >
                                                        %
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="text-end">
                                                <button
                                                    onClick={this.onSaveRecord}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    {this.props.t('general.btnSave')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            /*<>
                <header className="bg-white shadow">
                    <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">Emission</h1>
                    </div>
                </header>
                <main>
                    <div className="mx-auto w-full py-6 px-4 sm:px-6 lg:px-8">
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">{this.props.t('general.btnCreate')} Emission Records</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Emission record etc</p>
                            </div>

                            <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">



                                    <div className="col-span-6 md:col-span-2">
                                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                                            Month
                                        </label>
                                        <DatePicker
                                            id="date"
                                            selected={selectedDate}
                                            onChange={(date) => this.onSelectDate(date)}
                                            dateFormat="MMMM yyyy"
                                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            showMonthYearPicker
                                        />
                                    </div>

                                    <div className="col-span-6 md:col-span-2">
                                        <div className="col-span-6 md:col-span-6">
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Category</p>
                                        </div>
                                        <div className="grid grid-cols-6 gap-2 py-2">
                                            {optionCategory}
                                        </div>
                                    </div>

                                    <div className="col-span-6 md:col-span-4">
                                        <div className="col-span-6 md:col-span-6">
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Emission records</p>
                                        </div>

                                        <div className="col-span-6 md:col-span-6">
                                            {emissionRows}
                                        </div>

                                        <div onClick={() => this.onInsertRow()} className="col-span-6 md:col-span-6 py-2">
                                            <p className="mt-1 text-sm text-center text-gray-500">Add new record</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                <button
                                    onClick={this.onSaveRecord}
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </>*/
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CompanyUpdate)))
