import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import xlsx from 'json-as-xlsx'
import { useSelector } from 'react-redux'
import ApexCharts from 'apexcharts'

import {
  ROUTE_PATHS,
  SCENARIO_LOCAL_STORAGE_KEY,
  ANALYTICS_LABEL,
  AREA_CHART_COLORS,
  EMPTY_CHART_COLORS,
  twoDecimalFormat,
} from '../library/helper'
import ReactApexChart from 'react-apexcharts'

import Download from './Download'
import MultilineChartLegend from './ApexCharts/MultilineChartLegend'

const LABEL = ANALYTICS_LABEL

const properties = {
  emissionVsTarget: {
    chartId: 'emissionVsTarget',
    apiURL: 'record-vs-target-summaries-lookup',
    titleTranslation: 'dashboard.card.title.emissionVsTarget',
    downloadedChartName: 'emission-vs-target',
  },
  carbonIntensityVsTarget: {
    chartId: 'carbonIntensityVsTarget',
    apiURL: 'carbon-intensity-vs-target-summaries-lookup',
    titleTranslation: 'analytic.carbonIntensity.vsTarget',
    downloadedChartName: 'carbon-intensity-vs-target',
  },
  SBTiScope12: {
    chartId: 'SBTiScope12',
    apiURL: 'analytics-sbti-scope12-lookup',
    titleTranslation: 'analytics.sbti.scope12',
    downloadedChartName: 'sbti-scope12',
  },
  SBTiScope3: {
    chartId: 'SBTiScope3',
    apiURL: 'analytics-sbti-scope3-lookup',
    titleTranslation: 'analytics.sbti.scope3',
    downloadedChartName: 'sbti-scope3',
  },
}

const emptySeries = [
  {
    "name": "actual emission - reduction",
    "data": [
      117.26,
      135.09,
      138.87,
      144.05,
      165.8,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
  },
  {
    "name": "actual emission",
    "data": [
      117.26,
      190.2,
      217.43,
      224.05,
      285.83,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
  },
  {
    "name": "emission forecast",
    "data": [
      null,
      null,
      null,
      null,
      285.83,
      362.39,
      459.46,
      582.53,
      738.56,
      936.38,
      1187.19,
      1505.18
    ],
  },
  {
    "name": "Net Zero Indonesia",
    "data": [
      null,
      null,
      null,
      null,
      285.83,
      352.61,
      434.6,
      535.28,
      658.72,
      809.88,
      994.63,
      1220.4
    ],
  },
  {
    "name": "NDC - Unconditional",
    "data": [
      null,
      null,
      null,
      null,
      285.83,
      345.87,
      417.6,
      502.9,
      603.99,
      723.07,
      862.73,
      1025.18
    ],
  },
  {
    "name": "NDC - Conditional",
    "data": [
      null,
      null,
      null,
      null,
      285.83,
      340.03,
      402.76,
      474.7,
      556.21,
      647.41,
      747.57,
      854.94
    ],
  },
  {
    "name": "NDC & NZE",
    "data": [
      null,
      null,
      null,
      null,
      285.83,
      345.87,
      417.6,
      502.9,
      603.99,
      723.07,
      862.73,
      1025.18
    ],
  },
  {
    "name": "Custom Scenario",
    "data": [
      null,
      null,
      null,
      null,
      285.83,
      287.64,
      289.45,
      291.26,
      332.91,
      374.55,
      337.79,
      301.04
    ],
  }
]

const emptyXaxisCategories = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]


const NoData = ({
  onDashboard,
}) => {
  return (
    <div className='d-flex flex-column gap-2 pb-3 px-3'
    >
      <div style={{
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '0.5rem',
        borderRadius: 14,
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        alignSelf: 'center',
        top: onDashboard ? '20%' : '50%',

      }}>
        No Data
      </div>
    </div>
  )
}


function MultilineChartCard(props) {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const cardBodyRef = useRef()
  const account = useSelector((state) => state.account.value)

  const [data, setData] = useState([])
  const [lines, setLines] = useState()
  const [revenueUnit, setRevenueUnit] = useState('')
  const [showGrowthRateForm, setShowGrowthRateForm] = useState(false)
  const [growthRate, setGrowthRate] = useState()
  const [averageGrowthRate, setAverageGrowthRate] = useState(null)
  const [prevSbtiSetting, setPrevSbtiSetting] = useState({})
  const [newSbtiSetting, setNewSbtiSetting] = useState({})

  const [filteredData, setFilteredData] = useState([])
  const [activeKeys, setActiveKeys] = useState([])

  const [hiddenSeries, setHiddenSeries] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const options = {
    chart: {
      id: 'chart',
      height: 280,
      toolbar: {
        show: false,
      },
      type: 'area',
    },
    colors: AREA_CHART_COLORS,
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
    },
    stroke: {},
    xaxis: {
      type: 'year',
      categories: data
        .map(item => item?.[LABEL.timescale])
        .filter((value, index, array) => array.indexOf(value) === index).sort()
    },
    yaxis: {
      title: {
        text: 'tCO₂e'
      },
      tickAmount: 4,
      min: 0,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val ? twoDecimalFormat(val, i18n.language) + " tCO₂e" : null
        },
      },
    },
    markers: {},
  }

  const emptyOptions = {
    ...options,
    colors: EMPTY_CHART_COLORS,
    xaxis: {
      type: 'year',
      categories: props.onDashboard ? emptyXaxisCategories.slice(0, 3) : emptyXaxisCategories,
      labels: {
        show: false,
      },
    },
    tooltip: {
      ...options.tooltip,
      enabled: false,
    },
    chart: {
      ...options.chart,
      zoom: {
        enable: false,
      }
    }
  }

  const arrayLength = data.map(item => Object.keys(item)?.length)
  const indexMax = arrayLength
    .findIndex(item => {
      return item === arrayLength
        .reduce((prev, data) => prev > data ? prev : data, 0)
    })

  const series = data?.length > 0
    ? Object.keys(data?.[indexMax])
      .filter(item => item !== LABEL.timescale)
      .map(item => ({
        name: item,
        data: data
          .map(itemData => itemData?.[item]
            ? parseFloat(itemData?.[item]?.toFixed(2))
            : null),
      }))
    : []

  // Set circle markers
  if (series.length > 0) {
    const descretes = []
    for (let i = 0; i < series.length; i++) {
      for (let j = 0; j < series[i].data.length; j++) {
        const seriesGroup = lines.find(line => line.label === series[i].name)
        if (seriesGroup) {
          if (seriesGroup.group === 1) {
            descretes.push({
              seriesIndex: i,
              dataPointIndex: j,
              fillColor: AREA_CHART_COLORS[i],
              strokeColor: '#fff',
              size: 4,
              shape: "circle"
            })
          } else if (
            seriesGroup.group === 3 &&
            Array.isArray(seriesGroup.targetYears) &&
            data?.length > 0
          ) {
            for (let k = 0; k < seriesGroup.targetYears.length; k++) {
              const targetYear = seriesGroup.targetYears[k]
              const dataPointIndexGroup3 = data.findIndex(d => d.timescale === targetYear)
              if (dataPointIndexGroup3 !== -1 && j === dataPointIndexGroup3) {
                descretes.push({
                  seriesIndex: i,
                  dataPointIndex: j,
                  fillColor: AREA_CHART_COLORS[i],
                  strokeColor: '#fff',
                  size: 4,
                  shape: "circle"
                })
              }
            }
          }
        }
      }
    }
    options.markers.discrete = descretes
    options.stroke.curve = series.map((s, index) => {
      if (index === 0 || index === 1) {
        return 'smooth'
      } else {
        return 'straight'
      }
    })
  } else {
    emptyOptions.stroke.curve = emptySeries.map((s, index) => {
      if (index === 0 || index === 1) {
        return 'smooth'
      } else {
        return 'straight'
      }
    })
  }

  useEffect(() => {
    const controller = new AbortController()

    const getData = async () => {
      try {
        setIsLoading(true)
        const params = {}
        if (
          location.pathname === ROUTE_PATHS.scenario ||
          props.onDashboard
        ) {
          params.startYear = props.startYear
          params.endYear = props.endYear
          params.growthRate = growthRate
          params.checkedScenarios = {
            isFound: false,
            checkedScenarioIds: []
          }
          if (props.onDashboard) {
            params.onDashboard = true
          } else {
            const checkedScenarioIds = JSON.parse(localStorage.getItem(SCENARIO_LOCAL_STORAGE_KEY))
            params.checkedScenarios = {
              isFound: !!checkedScenarioIds,
              checkedScenarioIds: checkedScenarioIds
            }
          }
        } else if (location.pathname === ROUTE_PATHS.analyticsSBTi) {
          params.growthRate = newSbtiSetting.growthRate
        } else if (location.pathname === ROUTE_PATHS.analyticsCarbonIntensity) {
          params.startYear = props.startYear
          params.endYear = props.endYear
        }

        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params,
          url: `${process.env.REACT_APP_BASE_SERVER}/${properties[props.chartId].apiURL}`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setData(response.data.data.dataChart)
          setLines(response.data.data.lineChart)
          setRevenueUnit(response.data.data.revenueUnit)
          setShowGrowthRateForm(response.data.data.showGrowthRateForm)
          setAverageGrowthRate(response.data.data.averageGrowthRate)

          setPrevSbtiSetting({
            nearTermLar: response.data.data.nearTermLar,
            longTermGoal: response.data.data.longTermGoal,
            nearTermTargetYear: response.data.data.nearTermTargetYear,
            longTermTargetYear: response.data.data.longTermTargetYear,
            growthRate: response.data.data.averageGrowthRate, // in %
          })

          setActiveKeys(prevKeys => {
            if (prevKeys.some(key => key.label === LABEL.emissionPathway)) {
              return prevKeys
                .filter(initKey => {
                  return (
                    initKey.label !== LABEL.nearTermSBTs
                    && initKey.label !== LABEL.longTermSBTs
                  )
                })
            } else if (prevKeys.some(key => key.label === LABEL.nearTermSBTs)) {
              return prevKeys
                .filter(initKey => {
                  return (
                    initKey.label !== LABEL.emissionPathway
                    && initKey.label !== LABEL.longTermSBTs
                  )
                })
            } else if (prevKeys.some(key => key.label === LABEL.longTermSBTs)) {
              return prevKeys
                .filter(initKey => {
                  return (
                    initKey.label !== LABEL.emissionPathway
                    && initKey.label !== LABEL.nearTermSBTs
                  )
                })
            } else if (prevKeys.length > 0) {
              return prevKeys
            } else {
              return response.data.data.lineChart
                .filter(initKey => {
                  if (location.pathname === ROUTE_PATHS.analyticsSBTi) {
                    return (
                      initKey.label !== LABEL.nearTermSBTs
                      && initKey.label !== LABEL.longTermSBTs
                    )
                  } else {
                    return true
                  }
                })
            }
          })
        }
      } catch (error) {
        if (error.code && error.code !== 'ERR_CANCELED') {
          Swal.fire({
            icon: 'error',
            title: '',
            html: error.response.data.message,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    if (
      props.startYear &&
      props.endYear &&
      props.endYear > props.startYear
    ) {
      getData()
    }

    return () => controller.abort()
  }, [props.startYear, props.endYear, props.chartId, props.onDashboard, growthRate, newSbtiSetting])

  const onDownload = () => {
    props.onDownloadImage(props.chartId, properties[props.chartId].downloadedChartName)
  }

  const onDownloadExcel = () => {
    const columns = [
      { label: "YEAR", value: "timescale" },
    ]
    if (activeKeys.some(key => key.label === LABEL.actualEmission)) {
      columns.push({ label: LABEL.actualEmission.toLocaleUpperCase(), value: LABEL.actualEmission })
    }
    if (activeKeys.some(key => key.label === LABEL.projection)) {
      columns.push({ label: LABEL.projection.toLocaleUpperCase(), value: LABEL.projection })
    }
    if (activeKeys.some(key => key.label === LABEL.emissionPathway)) {
      columns.push({ label: LABEL.emissionPathway.toLocaleUpperCase(), value: LABEL.emissionPathway })
    }
    if (activeKeys.some(key => key.label === LABEL.nearTermSBTs)) {
      columns.push({ label: LABEL.nearTermSBTs.toLocaleUpperCase(), value: LABEL.nearTermSBTs })
    }
    if (activeKeys.some(key => key.label === LABEL.longTermSBTs)) {
      columns.push({ label: LABEL.longTermSBTs.toLocaleUpperCase(), value: LABEL.longTermSBTs })
    }
    columns.push({ label: 'GAP', value: 'gap' })

    const content = filteredData.map(row => {
      const newObject = {}
      columns.forEach(col => {
        newObject[col.value] = row[col.value]
      })
      if (row[columns[2].value] && row[columns[3].value]) {
        newObject.gap = Number(row[columns[2].value]) - Number(row[columns[3].value])
      }
      return newObject
    })

    const data = [
      {
        sheet: "Data",
        columns,
        content,
      },
    ]

    const settings = {
      fileName: `${props.chartId}_${account.client_name}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
    }

    xlsx(data, settings)
  }

  const FilterSeries = (seriesName = "") => {
    if (series.every(item => item?.name === undefined)) return;

    ApexCharts.getChartByID(options?.chart?.id).toggleSeries(seriesName);

    let currentData = [...hiddenSeries];
    const checkIndex = currentData.findIndex(item => item === seriesName);

    if (checkIndex < 0) currentData.push(seriesName);
    else currentData.splice(checkIndex, 1);

    setHiddenSeries(currentData);
  }

  if (isLoading) {
    return (
      <div
        className="card"
        style={{
          height: props.onDashboard ? '300px' : '75vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className="d-flex flex-wrap gap-3 mb-2">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className='bg-white d-flex flex-column flex-grow-1 flex-shrink-1 gap-1 h-100 justify-content-center'
        style={{
          borderRadius: '8px',
          width: '100%',
        }}
        id={props.chartId}
      >
        <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
          <p
            className={`fw-semibold m-0 ${series.length > 0 ? '' : 'text-muted'}`}
            style={{ fontSize: 12 }}
          >
            {t(properties[props.chartId].titleTranslation)}
          </p>
          {
            location.pathname === ROUTE_PATHS.analyticsSBTi ? (
              <div className="flex-shrink-0">
                <ul className="list-inline text-end mb-0">
                  <li className="list-inline-item m-0">
                    <div className="dropdown">
                      <button
                        className="btn btn-ghost-secondary btn-icon btn-sm"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="ri-more-2-fill "></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end">
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={onDownload}
                        >
                          <i className="ri-file-line align-bottom text-muted me-2"></i>
                          {t('sbti.saveAsPng')}
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={onDownloadExcel}
                        >
                          <i className="ri-file-excel-line align-bottom text-muted me-2"></i>
                          {t('sbti.saveAsExcel')}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <Download
                onClick={onDownload}
                isDisabled={series.length === 0}
              />
            )
          }
        </div>

        <div className='px-3 position-relative'>
          <ReactApexChart
            options={series.length > 0 ? options : emptyOptions}
            series={series.length > 0 ? series : props.onDashboard ? emptySeries.map(s => {
              return {
                ...s,
                data: s.data.slice(0, 3)
              }
            }) : emptySeries}
            type='area'
            height={props.onDashboard ? 200 : 465}
          />
          {series.length === 0 && <NoData onDashboard={props.onDashboard} />}
        </div>
        {
          series?.length > 0 && (
            <MultilineChartLegend
              series={series}
              filterSeries={FilterSeries}
              hiddenSeries={hiddenSeries}
              onDashboard={props.onDashboard}
              lines={lines}
              averageGrowthRate={averageGrowthRate}
              setGrowthRate={setGrowthRate}
            />
          )
        }
      </div>
    )
  }
}

export default MultilineChartCard
