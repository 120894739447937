import React, { Component, Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"
import { tourEmissionFromDashboardStart } from '../features/tour/tourSlice'
import axios from "axios";
import qs from 'qs';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import "react-tooltip/dist/react-tooltip.css";
import { NumericFormat } from 'react-number-format'
import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'

import "react-datepicker/dist/react-datepicker.css";

const ALL_STEPS = ({
    company,
    location,
    source,
    monthAndYear,
    assetName,
    type,
    unit,
    amount,
    attachment,
    save,
}) => {
    return [
        {
            content: company.second,
            spotlightClicks: true,
            disableBeacon: true,
            target: '#tour__company',
            title: company.first,
        },
        {
            spotlightClicks: true,
            target: '#tour__location',
            title: location,
        },
        {
            spotlightClicks: true,
            target: '#tour__month_and_year',
            title: monthAndYear,
        },
        {
            content: source.second,
            spotlightClicks: true,
            target: '#tour__emission_source',
            title: source.first,
        },
        {
            spotlightClicks: true,
            target: '#tour__asset_name',
            title: assetName,

        },
        {
            spotlightClicks: true,
            target: '#tour__type',
            title: type,
        },
        {
            spotlightClicks: true,
            target: '#tour__unit',
            title: unit,
        },
        {
            spotlightClicks: true,
            target: '#tour__amount',
            title: amount,
        },
        {
            spotlightClicks: true,
            target: '#tour__attachment',
            title: attachment,
        },
        {
            target: '#tour__save',
            title: save,
        },
    ]
}

export class EmissionCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectEmission: false,
            selectedCompany: 0,
            selectedOffice: 0,
            selectedDate: new Date(Date.now()),
            selectedScope: 0,
            selectedCategory: 0,
            selectedItem: 0,
            amountItem: 0,

            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: 0,
                    amount_2: 0,
                    amount_3: 0,
                    amount_4: 0,
                    amount_5: 0,
                    amount_6: 0,
                    amount_7: 0,
                    amount_8: 0,
                    unit: 0,
                    multiplier: 1,
                    reduction: 0,
                    factorCompare: 0,
                }
            ],

            listCategory: [],
            listFactor: [],
            itemUnit: '',

            amountEmission: 0,
            countRecords: 1,

            selectedCategoryTooltip: "",
            selectedFile: null,

            tourSteps: [],
            tourRun: false,
            tourStepIndex: 0,
            limitDate: "01-01-2021",
        }
    }

    tourInitialSteps = () => {
        return ALL_STEPS({
            company: {
                first: this.props.t('emission.tour.company1'),
                second: this.props.t('emission.tour.company2'),
            },
            location: this.props.t('emission.tour.location'),
            source: {
                first: this.props.t('emission.tour.source1'),
                second: this.props.t('emission.tour.source2'),
            },
            monthAndYear: this.props.t('emission.tour.month'),
            assetName: this.props.t('emission.tour.asset'),
            type: this.props.t('emission.tour.fuel'),
            unit: this.props.t('emission.tour.unit.fuel'),
            amount: this.props.t('emission.tour.amount'),
            attachment: this.props.t('emission.tour.attachment.button'),
            save: this.props.t('emission.tour.save'),
        })
    }

    componentDidMount = async () => {
        showPreloader();

        this.setState({
            tourSteps: this.tourInitialSteps(),
            tourRun: this.props.tourFromDashboardRun,
        })

        let minYear = new Date().getFullYear() - 2;
        let minDate = "01-01-" + minYear;

        let listCompany = [];
        let listCategory = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];

        const data = {};

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }

        let selectedLanguage = this.props.i18n.language;
        let selectedCategoryTooltip = "";

        if (selectedLanguage === "en") {
            selectedCategoryTooltip = "Please select category";
        } else {
            selectedCategoryTooltip = "Pilih kategori emisi";
        }

        this.setState({
            listCompany: listCompany,
            listCategory: listCategory,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
            selectedOffice: 0,
            selectedCategoryTooltip: selectedCategoryTooltip,
            limitDate: minDate,
        })

        hidePreloader();
    }

    onSelectCompany = async (e) => {
        let companyValue = e.target.value;
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                selectedOffice: 0,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.t !== prevProps.t) {
            this.setState({
                tourSteps: this.tourInitialSteps()
            })
        }
        if (this.state.selectedCategory !== prevState.selectedCategory) {
            // Update Tour Steps
            if (!!this.state.selectedCategory) {
                if (
                    !!this.state.listEmissionFactor &&
                    this.state.listEmissionFactor.length > 0
                ) {
                    const efs = this.state.listEmissionFactor
                        .filter(item => Number(item.cf_category) === Number(this.state.selectedCategory))
                    let newSteps = this.tourInitialSteps()
                    const startIndex = 5
                    if (efs.length > 0) {
                        let typeAtStartIndex = newSteps[startIndex].title
                        let unitAfterStartIndex = newSteps[startIndex + 1].title
                        if (
                            Number(this.state.selectedCategory) === 22 ||
                            Number(this.state.selectedCategory) === 21 ||
                            Number(this.state.selectedCategory) === 20
                        ) {
                            typeAtStartIndex = this.props.t('emission.tour.gasType')
                            unitAfterStartIndex = this.props.t('emission.tour.unit.gasType')
                        } else if (Number(this.state.selectedCategory) === 18) {
                            typeAtStartIndex = this.props.t('emission.tour.fireExtinguisher')
                            unitAfterStartIndex = this.props.t('emission.tour.unit.fireExtinguisher')
                        } else if (Number(this.state.selectedCategory) === 7) {
                            typeAtStartIndex = this.props.t('emission.tour.grid')
                            unitAfterStartIndex = this.props.t('emission.tour.unit.grid')
                        } else if (Number(this.state.selectedCategory) === 4) {
                            typeAtStartIndex = this.props.t('emission.tour.treatmentType')
                            unitAfterStartIndex = this.props.t('emission.tour.unit.treatmentType')
                        } else if (Number(this.state.selectedCategory) === 3) {
                            typeAtStartIndex = this.props.t('emission.tour.refrigerant')
                            unitAfterStartIndex = this.props.t('emission.tour.unit.refrigerant')
                        }
                        this.setState({
                            tourSteps: newSteps.toSpliced(startIndex, 2, {
                                spotlightClicks: true,
                                target: "#tour__type",
                                title: typeAtStartIndex,
                            }, {
                                spotlightClicks: true,
                                target: "#tour__unit",
                                title: unitAfterStartIndex,
                            })
                        })
                    } else {
                        this.setState({
                            tourSteps: newSteps.toSpliced(startIndex, 2)
                        })
                    }
                }
            }
        }
    }

    onSelectCategory = async (e) => {
        /*let categoryValue = e;
        console.log(categoryValue)
        this.setState({
            selectedCategory: categoryValue,
        })*/
        let categoryValue = e.target.value;

        let selectedLanguage = this.props.i18n.language;
        let selectedCategoryTooltip = "";

        this.state.listCategory.map(async (item, index) => {
            if (item.category_id == categoryValue) {
                if (selectedLanguage === "en") {
                    selectedCategoryTooltip = item.category_description;
                } else {
                    selectedCategoryTooltip = item.category_description_id;
                }
            }
        })

        this.setState({
            selectedCategory: categoryValue,
            selectedCategoryTooltip: selectedCategoryTooltip,
            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: "",
                    unit: 0,
                    multiplier: 1,
                }
            ],
            countRecords: 1,
        })
    }

    onSelectOffice = async (e) => {
        let officeValue = e.target.value;
        this.setState({
            selectedOffice: officeValue,
        })
    }

    onSelectDate = async (e) => {
        var datePick = new Date(new Date(e).getFullYear(), new Date(e).getMonth(), 7);
        this.setState({
            selectedDate: datePick,
        })
    }

    onInputAsset = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].assets = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

    }

    onSelectEmission = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].factor = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

    }

    onCheckReduction = async (e, index) => {
        let data = this.state.listEmissionRecord;
        if (data[index].reduction == 1) {
            data[index].reduction = 0;
        } else {
            data[index].reduction = 1;
        }
        this.setState({
            listEmissionRecord: data
        })

    }

    onSelectUnit = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].unit = e.target.value;
        let listEmissionFactorUnit = this.state.listEmissionFactorUnit;
        let filteredUnit = listEmissionFactorUnit.filter(
            // eslint-disable-next-line
            item => item.unit_id == e.target.value
        )
        data[index].multiplier = filteredUnit[0].unit_multiplier;
        this.setState({
            listEmissionRecord: data
        })

    }

    onInputNumericFormatChange = (values, sourceInfo, index) => {
        if (sourceInfo?.event?.target?.name && values) {
            let data = this.state.listEmissionRecord
            data[index].amount = values.value
            this.setState({
                listEmissionRecord: data,
            })
        }
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data' },
            data: data,
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/create`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onSaveRecord = async (e) => {
        let listCategory = this.state.listCategory;
        let filteredCategory = listCategory.filter(
            // eslint-disable-next-line
            item => item.category_id == this.state.selectedCategory
        )
        this.state.listEmissionRecord.forEach(async element => {
            let listEmissionFactor = this.state.listEmissionFactor;
            let filteredEmissionFactor = listEmissionFactor.filter(
                // eslint-disable-next-line
                item => item.emission_factor_id == element.factor && item.cf_category == this.state.selectedCategory
            )

            let data = new FormData();
            if (filteredEmissionFactor.length > 0) {
                data.append('scope', filteredCategory[0].category_scope)
                data.append('category', this.state.selectedCategory)
                data.append('factor', element.factor)
                data.append('unit', element.unit)
                data.append('company', this.state.selectedCompany)
                data.append('location', this.state.selectedOffice)
                data.append('assets', element.assets)
                data.append('date', this.state.selectedDate.toISOString())
                data.append('amount', element.amount)
                data.append('total', parseFloat(element.amount) * parseFloat(element.multiplier) * parseFloat(filteredEmissionFactor[0].emission_factor))
                data.append('attachment', this.state.selectedFile)
            } else {
                data.append('scope', filteredCategory[0].category_scope)
                data.append('category', this.state.selectedCategory)
                data.append('factor', element.factor)
                data.append('unit', element.unit)
                data.append('company', this.state.selectedCompany)
                data.append('location', this.state.selectedOffice)
                data.append('assets', element.assets)
                data.append('date', this.state.selectedDate.toISOString())
                data.append('amount', element.amount)
                data.append('total', parseFloat(element.amount) * parseFloat(element.multiplier))
                data.append('attachment', this.state.selectedFile)
            }
            console.log(data)
            const result = await this.onSaveRecordCall(data);
            if (!result) {
                Swal.fire({
                    title: '',
                    html: '<p>Please fill all information needs</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            } else {
                Swal.fire({
                    title: '',
                    html: '<p>Successfully record emissions</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    console.log('alert result', result)
                    if (result.isConfirmed) {
                        this.setState({
                            redirectEmission: true
                        })
                    }
                })
            }
        });
    }

    onAttachFile = async () => {
        document.getElementById('formFile').click();
    }

    onChangeFile = async (e) => {
        const files = e.target.files
        this.setState({ selectedFile: files?.[0] })
    }

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            this.setState({ tourRun: false, tourStepIndex: 0 })
            this.props.tourEmissionFromDashboardStart(false)
        } else if (type === EVENTS.TARGET_NOT_FOUND) {
            this.setState({
                tourStepIndex: index - 1,
            })
        } else if (type === EVENTS.STEP_AFTER) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
            this.setState({
                tourStepIndex: nextStepIndex,
            })
        }
    }

    render() {
        const { redirectEmission } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { selectedDate } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor, listEmissionFactorUnit, listEmissionRecord } = this.state;
        const { countRecords } = this.state;
        let { selectedCategoryTooltip } = this.state;
        let { selectedFile } = this.state;
        let selectedLanguage = this.props.i18n.language;
        const { account } = this.props;

        if (redirectEmission) {
            return <Navigate to={'/emission'} />
        }

        let optionCompany = [];
        optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>{this.props.t('emission.form.placeholder1')}</option>)
        // eslint-disable-next-line
        if (listCompany != undefined) {
            if (listCompany.length > 0) {
                listCompany.map(async (item, index) => {
                    optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
                })
            }
        }

        let optionOffice = [];
        optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>{this.props.t('emission.form.placeholder2')}</option>)
        // eslint-disable-next-line
        if (listOffice != undefined) {
            if (listOffice.length > 0) {
                listOffice.map(async (item, index) => {
                    optionOffice.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
                })
            }
        }

        let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>{this.props.t('emission.form.placeholder3')}</option>)
        // eslint-disable-next-line
        if (listCategory != undefined) {
            if (listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    if (selectedLanguage === "en") {
                        optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
                        //selectedCategoryTooltip = item.category_description;
                    } else {
                        optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name_id}</option>)
                        //selectedCategoryTooltip = item.category_description_id;
                    }

                    // eslint-disable-next-line
                    /*if(item.category_id == selectedCategory){
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }else{
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }*/
                })
            }
        }

        let optionEmissionFactor = [];
        // eslint-disable-next-line
        if (selectedCategory != 0) {
            // eslint-disable-next-line
            if (listEmissionFactor != undefined) {
                if (listEmissionFactor.length > 0) {
                    listEmissionFactor.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.cf_category == selectedCategory) {
                            optionEmissionFactor.push(
                                <option key={index + 1} value={item.emission_factor_id}>{item.emission_name}</option>
                            )
                        }
                    })
                }
            }
        }

        let emissionRows = [];
        for (let i = 0; i < countRecords; i++) {
            let optionEmissionFactorUnit = [];
            // eslint-disable-next-line
            if (listEmissionRecord[i].factor != 0) {
                // eslint-disable-next-line
                if (listEmissionFactorUnit != undefined) {
                    if (listEmissionFactorUnit.length > 0) {
                        listEmissionFactorUnit.map(async (item, index) => {
                            // eslint-disable-next-line
                            if (item.unit_factor == listEmissionRecord[i].factor) {
                                optionEmissionFactorUnit.push(
                                    <option key={index + 1} value={item.unit_id}>{item.unit_label}</option>
                                )
                            }
                        })
                    }
                }
            }

            if (optionEmissionFactor.length > 0) {
                emissionRows.push(
                    <Fragment key={i}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3" id="tour__asset_name">
                                    <label htmlFor="assets" className="form-label">
                                        {this.props.t('emission.form.label7')}
                                    </label>
                                    <input
                                        onChange={e => this.onInputAsset(e, i)}
                                        value={listEmissionRecord[i].assets}
                                        className="form-control"
                                        placeholder={this.props.t('emission.form.enter')}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3" id="tour__type">
                                    <label htmlFor="factor" className="form-label">
                                        {/* eslint-disable-next-line */}
                                        {
                                            selectedCategory == 22 ? this.props.t('emission.form.label21') :
                                                selectedCategory == 21 ? this.props.t('emission.form.label21') :
                                                    selectedCategory == 20 ? this.props.t('emission.form.label21') :
                                                        selectedCategory == 18 ? this.props.t('emission.form.label22') :
                                                            selectedCategory == 7 ? this.props.t('emission.form.label8') :
                                                                selectedCategory == 4 ? this.props.t('emission.form.label19') :
                                                                    selectedCategory == 3 ? this.props.t('emission.form.label9') : this.props.t('emission.form.label10')}
                                    </label>
                                    <select onChange={e => this.onSelectEmission(e, i)} value={listEmissionRecord[i].factor} className="form-select mb-0">
                                        {/* eslint-disable-next-line */}
                                        {listEmissionRecord[i].factor > 0 ? "" : <option value="0">{this.props.t('emission.form.select')} {
                                            selectedCategory == 22 ? this.props.t('emission.form.label21') :
                                                selectedCategory == 21 ? this.props.t('emission.form.label21') :
                                                    selectedCategory == 20 ? this.props.t('emission.form.label21') :
                                                        selectedCategory == 18 ? this.props.t('emission.form.label22') :
                                                            selectedCategory == 7 ? this.props.t('emission.form.label8') :
                                                                selectedCategory == 4 ? this.props.t('emission.form.label19') :
                                                                    selectedCategory == 3 ? this.props.t('emission.form.label9') : this.props.t('emission.form.label10')}</option>}
                                        {optionEmissionFactor}
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3" id="tour__unit">
                                    <label htmlFor="unit" className="form-label">
                                        {this.props.t('emission.form.label11')}
                                    </label>
                                    <select onChange={e => this.onSelectUnit(e, i)} value={listEmissionRecord[i].unit} className="form-select mb-0">
                                        {listEmissionRecord[i].unit > 0 ? "" : <option value="0">{this.props.t('emission.form.placeholder4')}</option>}
                                        {optionEmissionFactorUnit}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3" id="tour__amount">
                                    <label htmlFor="amount" className="form-label">
                                        {/* eslint-disable-next-line */}
                                        {selectedCategory == 4 ? this.props.t('emission.form.label20') : selectedCategory == 5 ? this.props.t('emission.form.label12') : this.props.t('emission.form.label13')}
                                    </label>
                                    <NumericFormat
                                        name='amount'
                                        className="form-control"
                                        value={listEmissionRecord[i].amount}
                                        onValueChange={(values, sourceInfo) => this.onInputNumericFormatChange(values, sourceInfo, i)}
                                        decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                        thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                        placeholder={this.props.t('emission.form.enter')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            } else {
                emissionRows.push(
                    <Fragment key={i}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3" id="tour__asset_name">
                                    <label htmlFor="assets" className="form-label">
                                        {this.props.t('emission.form.label7')}
                                    </label>
                                    <input
                                        onChange={e => this.onInputAsset(e, i)}
                                        value={listEmissionRecord[i].assets}
                                        className="form-control"
                                        placeholder={this.props.t('emission.form.enter')}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3" id="tour__amount">
                                    <label htmlFor="amount" className="form-label">
                                        {/* eslint-disable-next-line */}
                                        {selectedCategory == 4 ? this.props.t('emission.form.label20') : selectedCategory == 5 ? this.props.t('emission.form.label12') : this.props.t('emission.form.label13')}
                                    </label>
                                    <NumericFormat
                                        name='amount'
                                        className="form-control"
                                        value={listEmissionRecord[i].amount}
                                        onValueChange={(values, sourceInfo) => this.onInputNumericFormatChange(values, sourceInfo, i)}
                                        decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                        thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                        placeholder={this.props.t('emission.form.enter')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="d-flex gap-3 mb-sm-0 align-items-baseline">
                                    <h4>
                                        {this.props.t("menu.label21")} - Scope 1 & 2
                                    </h4>
                                    <button
                                        onClick={() => this.setState({ tourRun: true })}
                                        className="btn btn-outline-info btn-sm"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Start Guideline"
                                    >
                                        <i className="ri-question-fill"></i>
                                    </button>
                                </div>
                                <Joyride
                                    callback={this.handleJoyrideCallback}
                                    continuous
                                    run={this.state.tourRun}
                                    scrollToFirstStep
                                    showProgress
                                    showSkipButton
                                    stepIndex={this.state.tourStepIndex}
                                    steps={this.state.tourSteps}
                                    styles={{
                                        options: {
                                            primaryColor: '#000',
                                            width: 500,
                                            zIndex: 10000,
                                        }
                                    }}
                                    locale={{
                                        back: this.props.t('emission.tour.back'),
                                        close: this.props.t('emission.tour.close'),
                                        last: this.props.t('emission.tour.last'),
                                        next: this.props.t('emission.tour.next'),
                                        open: this.props.t('emission.tour.open'),
                                        skip: this.props.t('emission.tour.skip'),
                                    }}
                                />

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{this.props.t('general.btnCreate')}</h4>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3" id="tour__company">
                                                <label htmlFor="company" className="form-label">{this.props.t('emission.form.label1')}</label>
                                                <select
                                                    onChange={e => this.onSelectCompany(e)}
                                                    value={selectedCompany}
                                                    id="company"
                                                    name="company"
                                                    className="form-select mb-3"
                                                >
                                                    {optionCompany}
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.selectedCompany > 0 ? <div className="col-md-6">
                                            <div className="mb-3" id="tour__location">
                                                <label htmlFor="office" className="form-label">{this.props.t('emission.table.label2')}</label>
                                                <select onChange={e => this.onSelectOffice(e)} value={selectedOffice} id="company" name="company" className="form-select mb-3">
                                                    {optionOffice}
                                                </select>
                                            </div>
                                        </div> : ""}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3" id="tour__emission_source">
                                                <label htmlFor="category" className="form-label">{this.props.t('emission.form.label2')}</label>
                                                <div className="input-group">
                                                    <select
                                                        onChange={e => this.onSelectCategory(e)}
                                                        value={selectedCategory}
                                                        id="category"
                                                        name="category"
                                                        className="form-select"
                                                    >
                                                        {optionCategory}
                                                    </select>
                                                    <span className="input-group-text" id="basic-addon2" title={selectedCategoryTooltip}>
                                                        <i className="ri-information-line"></i>
                                                    </span>
                                                </div>
                                                {/* eslint-disable-next-line */}
                                                {selectedCategory == 1 ?
                                                    <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://ditjenppi.menlhk.go.id/reddplus/images/adminppi/dokumen/igrk/Buku-EFDB.pdf">EFDB National KLHK 2017</a></p>
                                                    // eslint-disable-next-line
                                                    : selectedCategory == 6 ?
                                                        <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.ipcc-nggip.iges.or.jp/EFDB/find_ef.php?ipcc_code=2.B&ipcc_level=1">IPCC EFDB Guidelines 2006</a></p>
                                                        : selectedCategory == 7 ?
                                                            <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://gatrik.esdm.go.id/assets/uploads/download_index/files/96d7c-nilai-fe-grk-sistem-ketenagalistrikan-tahun-2019.pdf">GRK National ESDM 2019</a></p>
                                                            : selectedCategory != 0 ?
                                                                <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub">EPA 2023</a></p>
                                                                : ""}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3" id="tour__month_and_year">
                                                <label htmlFor="date" className="form-label">{this.props.t('emission.form.label4')}</label>
                                                <div>
                                                    <DatePicker
                                                        id="date"
                                                        selected={selectedDate}
                                                        onChange={(date) => this.onSelectDate(date)}
                                                        minDate={new Date(this.state.limitDate)}
                                                        dateFormat="MMMM yyyy"
                                                        className="form-select mb-3"
                                                        showMonthYearPicker
                                                        popperClassName="trucount-popper-date-picker-class"
                                                        popperPlacement={this.state.tourRun ? 'top' : 'bottom'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.selectedCategory > 0 ? <>
                                        {emissionRows}
                                        {account['client_subscription'] > 1 ? <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="formFile" className="form-label">{this.props.t('emission.form.label15')}</label>
                                                    <p className="text-muted">{this.props.t('emission.form.label16')}</p>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'baseline',
                                                            gap: '0.5rem',
                                                        }}
                                                    >
                                                        <button
                                                            id="tour__attachment"
                                                            onClick={this.onAttachFile}
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light"
                                                        >
                                                            {this.props.t('emission.form.label17')}
                                                        </button>
                                                        <p className="text-muted">{selectedFile?.name}</p>
                                                    </div>
                                                    <input
                                                        onChange={this.onChangeFile}
                                                        style={{ display: "none" }}
                                                        className="form-control"
                                                        type="file"
                                                        id="formFile"
                                                        accept="image/*,.pdf,.doc"
                                                    />
                                                </div>
                                            </div>
                                        </div> : ""}
                                        <div className="col-lg-12">
                                            <div className="text-end">
                                                <button
                                                    id="tour__save"
                                                    onClick={this.onSaveRecord}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    {this.props.t('general.btnSave')}
                                                </button>
                                            </div>
                                        </div>
                                    </> : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
    tourFromDashboardRun: state.tour.emissionTourFromDashboardRun
});

const mapDispatchToProps = {
    increment,
    decrement,
    setLoggedIn,
    setAccount,
    setCompany,
    tourEmissionFromDashboardStart,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmissionCreate))
