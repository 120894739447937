import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'

import SingleRowWrapper from '../../components/Dashboard/Layouts/Row'
import MultilineChartCard from '../../components/MultilineChartCard'

const onDownloadImage = async (element, label) => {
  const canvas = await html2canvas(document.getElementById(element))
  const image = canvas.toDataURL('image/png', 1.0)

  const fakeLink = window.document.createElement('a')
  fakeLink.style = 'display:none'
  fakeLink.download = label

  fakeLink.href = image

  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)

  fakeLink.remove()
}

function SBTi() {
  const account = useSelector((state) => state.account.value)
  const { t } = useTranslation()

  return (
    <div className='page-content'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-12'>
            <div className='align-items-center d-flex justify-content-between mb-4'>
              <h4 className='mb-sm-0' style={{ color: '#09564B' }}>
                {t('menu.label41')}
              </h4>
            </div>
          </div>
        </div>

        {
          account['client_subscription'] > 1 &&
          account['client_sbti'] === 1 && (
            <>
              <SingleRowWrapper>
                <MultilineChartCard
                  chartId='SBTiScope12'
                  onDownloadImage={onDownloadImage}
                  // Dummy year just to run the MultilineChartCard useEffect
                  startYear={new Date().getFullYear()}
                  endYear={new Date().getFullYear() + 1}
                />
              </SingleRowWrapper>

              <SingleRowWrapper>
                <MultilineChartCard
                  chartId='SBTiScope3'
                  onDownloadImage={onDownloadImage}
                  // Dummy year just to run the MultilineChartCard useEffect
                  startYear={new Date().getFullYear()}
                  endYear={new Date().getFullYear() + 1}
                />
              </SingleRowWrapper>
            </>
          )}

      </div>
    </div >
  )
}

export default SBTi
