import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import DataTable from 'react-data-table-component';

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import Swal from 'sweetalert2'

import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

export class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumn: [],
            tableData: [],
            tableFilter: "",
            tableResetPagination: false,

            numberUsers: 0,
        }
    }

    componentDidMount = async () => {
        showPreloader();

        //console.log("company");
        const data = {}

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/user`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            let numberUsers = responseGetCompany.data.data.length;
            const columns = [
                {
                    name: this.props.t('removal.table.label1'),
                    selector: row => row.user_name,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: this.props.t('user.label1'),
                    selector: row => row.user_email,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: this.props.t('emission.table.label6'),
                    selector: row => <div className="d-flex flex-wrap gap-2">
                        <Link to={'/user/update/' + row.user_uuid} className="btn btn-info">{this.props.t('general.btnUpdate')}</Link>
                        <button onClick={() => this.onDelete(row.user_uuid)} className="btn btn-warning">{this.props.t('general.btnDelete')}</button>
                    </div>,
                    sortable: true,
                    filterable: true,
                    width: "210px",
                },
            ];
            const record = responseGetCompany.data.data;
            this.setState({
                tableColumn: columns,
                tableData: record,
                tableFiltered: record,
                numberUsers: numberUsers,
            })
        }

        hidePreloader();
    }

    onFilter = async (e) => {
        // eslint-disable-next-line
        if (e.target.value != "") {
            let filterValue = e.target.value;
            let baseData = this.state.tableData;
            //console.log(baseData);
            let filteredData = baseData.filter(
                item =>
                    item.user_name.toLowerCase().includes(this.state.tableFilter.toLowerCase()) ||
                    item.user_email.toLowerCase().includes(this.state.tableFilter.toLowerCase())
            )
            this.setState({
                tableFilter: filterValue,
                tableFiltered: filteredData,
            })
        } else {
            this.setState({
                tableFilter: "",
                tableFiltered: this.state.tableData,
            })
        }
        //console.log(this.state.tableFilter)
        //console.log(this.state.tableFiltered)
    }

    onClear = async () => {
        // eslint-disable-next-line
        if (this.state.tableFilter != "") {
            this.setState({
                tableFilter: "",
            })
        }
    }

    onDeleteCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/user/delete`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onDelete = async (id) => {
        let invalidData = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    'id': id,
                };
                const result = this.onDeleteCall(data);
                if (result) {
                    invalidData = false;
                }

                if (invalidData) {
                    Swal.fire({
                        title: '',
                        html: '<p>Cannot delete data, please try again later</p>',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Deleted',
                        text: "Your data has been deleted.",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            }
        })
    }

    render() {
        const { tableColumn, tableFilter, tableFiltered, numberUsers } = this.state;
        const paginationComponentOptions = {
            rowsPerPageText: this.props.t('table.paginationResult'),
            rangeSeparatorText: this.props.t('table.paginationResultPage'),
        };

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t('menu.label624')}</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">{this.props.t('general.read')}</h5>
                                    <br /><br />
                                    {numberUsers < 3 ? <Link to={'/user/create'} className="btn btn-success">{this.props.t('general.btnCreate')}</Link> : ""}
                                    <div className="col-lg-4" style={{ float: "right" }}>
                                        <div className="input-group">
                                            <span className="input-group-text" id="search"><i className="ri-search-line"></i></span>
                                            <input value={tableFilter} onChange={e => this.onFilter(e)} type="text" className="form-control" placeholder={this.props.t('table.search')} aria-describedby="search" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={tableColumn}
                                        data={tableFiltered}
                                        noDataComponent={this.props.t('table.empty')}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(User));