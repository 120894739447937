import Card from './Cards/Card'
import CardHeader from './Cards/CardHeader'
import CardTitle from './Cards/CardTitle'

function SelectCompany({
  onChange,
  option,
  title,
  value,
}) {
  return (
    <Card>
      <CardHeader hideBorderBottom={true}>
        <CardTitle>
          {title}
        </CardTitle>
        <select
          style={{
            width: 'auto',
          }}
          onChange={onChange}
          value={value}
          id='mode'
          name='mode'
          className='form-select'
        >
          {option}
        </select>
      </CardHeader>
    </Card>
  )
}

export default SelectCompany
