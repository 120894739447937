import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

import { Chart } from "react-google-charts";

import { connect } from "react-redux"
import { increment, decrement } from "../../features/counter/counterSlice"
import { setLoggedIn } from "../../features/account/loginSlice"
import { setAccount } from "../../features/account/accountSlice"
import { setCompany } from "../../features/account/companySlice"

import axios from "axios";
import qs from 'qs';

import html2canvas from "html2canvas";

import { hidePreloader, showPreloader } from '../../library/helper';

import { withTranslation } from 'react-i18next';
export class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSummaryMonthlyEmission: 0,

      selectedMode: 0,
      selectedCompany: 1,
      totalSummaryYearlyEmission: [],


      totalSummaryYearlyEmissionDummy: [
        ["Company", "Total Emission", "Total Reduction"],
        ["Jan", 156, -33],
        ["Feb", 110, -43],
        ["Mar", 123, -10],
        ["Apr", 156, -33],
        ["May", 110, -43],
        ["Jun", 123, -10],
        ["Jul", 156, -33],
        ["Aug", 110, -43],
        ["Sep", 123, -10],
        ["Oct", 156, -33],
        ["Nov", 110, -43],
        ["Dec", 123, -10],
      ],

      totalScopeMonthlyEmission: [],
      totalCategoryMonthlyEmission: [],
      revenueUnit: "",
      selectedYear: new Date().getFullYear() - 1,
      currentYear: new Date().getFullYear() - 1,
      listYear: [],

      selectedScenario: 1,

      scenarioAnalysisData: [],

      divisionYearlyEmission: [],
      selectedScenario: 1,
    }
  }

  componentDidMount = async () => {
    showPreloader();

    if (this.props.account['client_onboard'] === 0) {
      this.setState({
        redirectOnboard: true,
      })
    }
    let selectedLanguage = this.props.i18n.language;

    let listCompany = [];
    let listCategory = [];
    let listYear = [];
    let storeScopeMonthlyEmission = [];
    let storeCategoryMonthlyEmission = [];

    let storeSummaryMonthlyEmission = [];
    let labelCompanySummary = [];
    let dataSummary1 = [];
    let dataSummary2 = [];
    let dataSummary3 = [];
    let dataSummary4 = [];
    let dataSummary5 = [];
    let dataSummary6 = [];
    let dataSummary7 = [];
    let dataSummary8 = [];
    let dataSummary9 = [];
    let dataSummary10 = [];
    let dataSummary11 = [];
    let dataSummary12 = [];
    labelCompanySummary.push('Company');
    dataSummary1.push('Jan');
    dataSummary2.push('Feb');
    dataSummary3.push('Mar');
    dataSummary4.push('Apr');
    dataSummary5.push('May');
    dataSummary6.push('Jun');
    dataSummary7.push('Jul');
    dataSummary8.push('Aug');
    dataSummary9.push('Sep');
    dataSummary10.push('Oct');
    dataSummary11.push('Nov');
    dataSummary12.push('Dec');

    let selectedCompany = [];

    let scenarioAnalysisBase = [];
    let scenarioAnalysisData = [];

    const data = {
      'year': this.state.selectedYear
    };

    const optionsGetScenarioAnalysisBase = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/scenario_analysis/summary_year`
    };
    const responseGetScenarioAnalysisBase = await axios(optionsGetScenarioAnalysisBase);
    if (responseGetScenarioAnalysisBase.data.success) {
      scenarioAnalysisBase = responseGetScenarioAnalysisBase.data.data
      scenarioAnalysisBase.forEach(element => {
        console.log(element)
        scenarioAnalysisData.push(
          {
            name: element.record_year,
            emission: element.record_total.toFixed(0),
            target: 500,
          }
        )
      });
    }

    scenarioAnalysisData.push(
      {
        name: 2023,
        target: 170,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2024,
        target: 160,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2025,
        target: 150,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2026,
        target: 140,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2027,
        target: 130,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2028,
        target: 120,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2029,
        target: 110,
      }
    )
    scenarioAnalysisData.push(
      {
        name: 2030,
        target: 100,
      }
    )

    const optionsGetYearRecorded = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/year_recorded`
    };
    const responseGetYearRecorded = await axios(optionsGetYearRecorded);
    if (responseGetYearRecorded.data.success) {
      listYear = responseGetYearRecorded.data.data
    }

    const optionsGetEmissionCategory = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_all`
    };
    const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
    if (responseGetEmissionCategory.data.success) {
      listCategory = responseGetEmissionCategory.data.data
    }

    let labelCategorySummary = [];
    labelCategorySummary.push('Category');
    listCategory.forEach(element => {
      if (selectedLanguage === "en") {
        labelCategorySummary.push(element.category_name);
      } else {
        labelCategorySummary.push(element.category_name_id);
      }
      //labelCategorySummary.push(element.category_name);
      console.log(element.category_id)
    })

    this.getCompany()
      .then((result) => {
        if (result !== null) {
          listCompany = result;
        }
      }).then(() => {

        listCompany.map(async element => {
          selectedCompany.push(element.company_id)
          this.getSummaryMonthly(this.state.selectedYear, element.company_id)
            .then((responseGetSummaryMonthly) => {
              if (responseGetSummaryMonthly !== null) {
                labelCompanySummary.push(element.company_name);

                let dataRows = responseGetSummaryMonthly;
                dataRows.forEach((element, index) => {
                  // eslint-disable-next-line
                  if (index == 0) {
                    dataSummary1.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 1) {
                    dataSummary2.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 2) {
                    dataSummary3.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 3) {
                    dataSummary4.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 4) {
                    dataSummary5.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 5) {
                    dataSummary6.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 6) {
                    dataSummary7.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 7) {
                    dataSummary8.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 8) {
                    dataSummary9.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 9) {
                    dataSummary10.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 10) {
                    dataSummary11.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 11) {
                    dataSummary12.push(element.total);
                  }
                });
              }
            }).then(() => {

            })

          this.getScopeMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetScopeMonthly) => {
              if (responseGetScopeMonthly !== null) {
                let labelScopeSummary = [];

                labelScopeSummary.push('Emission');
                labelScopeSummary.push('Scope 1');
                labelScopeSummary.push('Scope 2');
                // eslint-disable-next-line
                if (this.props.account['client_scope'] == 1) {
                  labelScopeSummary.push('Scope 3');
                }

                let resultScopeSummary = [];
                resultScopeSummary.push(labelScopeSummary);
                let dataRows = responseGetScopeMonthly;
                dataRows.forEach(element => {
                  let dataScopeSummary = [];
                  dataScopeSummary.push(element.month_name)
                  dataScopeSummary.push(element.total_1)
                  dataScopeSummary.push(element.total_2)
                  // eslint-disable-next-line
                  if (this.props.account['client_scope'] == 1) {
                    dataScopeSummary.push(element.total_3)
                  }
                  resultScopeSummary.push(dataScopeSummary)
                });
                storeScopeMonthlyEmission.push(resultScopeSummary);
              }
            }).then(() => {
              storeSummaryMonthlyEmission[0] = labelCompanySummary;
              storeSummaryMonthlyEmission[1] = dataSummary1;
              storeSummaryMonthlyEmission[2] = dataSummary2;
              storeSummaryMonthlyEmission[3] = dataSummary3;
              storeSummaryMonthlyEmission[4] = dataSummary4;
              storeSummaryMonthlyEmission[5] = dataSummary5;
              storeSummaryMonthlyEmission[6] = dataSummary6;
              storeSummaryMonthlyEmission[7] = dataSummary7;
              storeSummaryMonthlyEmission[8] = dataSummary8;
              storeSummaryMonthlyEmission[9] = dataSummary9;
              storeSummaryMonthlyEmission[10] = dataSummary10;
              storeSummaryMonthlyEmission[11] = dataSummary11;
              storeSummaryMonthlyEmission[12] = dataSummary12;

              this.setState({
                listCompany: listCompany,
                totalScopeMonthlyEmission: storeScopeMonthlyEmission,
                totalSummaryYearlyEmission: storeSummaryMonthlyEmission,
              })
            })

          this.getCategoryMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetCategoryMonthly) => {
              if (responseGetCategoryMonthly !== null) {
                let resultCategorySummary = [];
                resultCategorySummary.push(labelCategorySummary);
                let dataRows = responseGetCategoryMonthly;
                dataRows.forEach(element => {
                  let dataCategorySummary = [];
                  dataCategorySummary.push(element.month_name)
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 1).length > 0) {
                    dataCategorySummary.push(element.total_1)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 2).length > 0) {
                    dataCategorySummary.push(element.total_2)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 3).length > 0) {
                    dataCategorySummary.push(element.total_3)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 4).length > 0) {
                    dataCategorySummary.push(element.total_4)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 5).length > 0) {
                    dataCategorySummary.push(element.total_5)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 6).length > 0) {
                    dataCategorySummary.push(element.total_6)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 7).length > 0) {
                    dataCategorySummary.push(element.total_7)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 8).length > 0) {
                    dataCategorySummary.push(element.total_8)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 9).length > 0) {
                    dataCategorySummary.push(element.total_9)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 10).length > 0) {
                    dataCategorySummary.push(element.total_10)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 11).length > 0) {
                    dataCategorySummary.push(element.total_11)
                  }
                  if (listCategory.filter(p => p.category_id == 12).length > 0) {
                    dataCategorySummary.push(element.total_12)
                  }
                                    if (listCategory.filter(p => p.category_id == 13).length > 0) {
                                        dataCategorySummary.push(element.total_13)
                                    }
                                    if (listCategory.filter(p => p.category_id == 14).length > 0) {
                                        dataCategorySummary.push(element.total_14)
                                    }
                                    if (listCategory.filter(p => p.category_id == 15).length > 0) {
                                        dataCategorySummary.push(element.total_15)
                                    }
                                    if (listCategory.filter(p => p.category_id == 16).length > 0) {
                                        dataCategorySummary.push(element.total_16)
                                    }
                                    if (listCategory.filter(p => p.category_id == 17).length > 0) {
                                        dataCategorySummary.push(element.total_17)
                                    }
                  resultCategorySummary.push(dataCategorySummary)
                });
                storeCategoryMonthlyEmission.push(resultCategorySummary);
              }
            }).then(() => {
              this.setState({
                totalCategoryMonthlyEmission: storeCategoryMonthlyEmission,
              })
            })
        })
      })

    const optionsTotalEmissionCurrentYear = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_current`
    };
    let calcTotalEmissionCurrentYear = [];
    let growth = 0;
    let growthValue = true;
    await axios(optionsTotalEmissionCurrentYear).then((responseTotalEmissionCurrentYear) => {
      if (responseTotalEmissionCurrentYear.data.success) {
        responseTotalEmissionCurrentYear.data.data.forEach(element => {
          calcTotalEmissionCurrentYear.push(element.total);
        });
        growth = ((parseFloat(calcTotalEmissionCurrentYear[0]) - parseFloat(calcTotalEmissionCurrentYear[1])) / parseFloat(calcTotalEmissionCurrentYear[1])) * 100;
        let growthNumber = Math.sign(growth)
        if (Math.sign(growthNumber) === 1) {
          growthValue = false;
        }
        if (isNaN(growth)) {
          growthValue = false;
        }
        this.setState({
          totalEmissionGrowth: growth,
          totalEmissionGrowthValue: growthValue,
        })
      }
    });

    const optionsTotalCategoryEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_category`
    };
    const responseTotalCategoryEmission = await axios(optionsTotalCategoryEmission);
    const categoryMonthlyEmission = [];
    let calcHighestCategory = 0;
    let highestCategory = "";
    categoryMonthlyEmission.push(["Category", "Emission (tCO<sub>2</sub>e)"]);
    if (responseTotalCategoryEmission.data.success) {
      responseTotalCategoryEmission.data.data.forEach(element => {
        if (element.total_emission > calcHighestCategory) {
          calcHighestCategory = element.total_emission;
          if (selectedLanguage === "en") {
            highestCategory = element.category_name;
          } else {
            highestCategory = element.category_name_id;
          }
        }
        if (selectedLanguage === "en") {
          categoryMonthlyEmission.push([element.category_name, element.total_emission])
        } else {
          categoryMonthlyEmission.push([element.category_name_id, element.total_emission])
        }
      });
    }

    const optionsDivisionYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_division`
    };
    const responseDivisionYearlyEmission = await axios(optionsDivisionYearlyEmission);
    const divisionYearlyEmission = [];
    //let calcHighestCategory = 0;
    //let highestCategory = "";
    divisionYearlyEmission.push(["Division", "Emission (tCO<sub>2</sub>e)"]);
    if (responseDivisionYearlyEmission.data.success) {
      responseDivisionYearlyEmission.data.data.forEach(element => {
        /*if(element.total_emission > calcHighestCategory){
            calcHighestCategory = element.total_emission;
            highestCategory = element.category_name;
        }*/
        divisionYearlyEmission.push([element.group_name, element.total_emission])
      });
    }

    const optionsCompanyYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/company_yearly_emission`
    };
    const responseCompanyYearlyEmission = await axios(optionsCompanyYearlyEmission);
    const companyYearlyEmission = [];
    let highestCompany = "";
    companyYearlyEmission.push(["Company", "Emission (tCO<sub>2</sub>e)"]);
    if (responseCompanyYearlyEmission.data.success) {
      responseCompanyYearlyEmission.data.data.forEach((element, index) => {
        // eslint-disable-next-line
        if (index == 0) {
          highestCompany = element.company_name;
        }
        companyYearlyEmission.push([element.company_name, element.total_emission])
      });
    }

    const optionsTotalEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission`
    };
    const responseTotalEmission = await axios(optionsTotalEmission);
    let totalEmission = [];
    if (responseTotalEmission.data.success) {
      totalEmission = responseTotalEmission.data.data;

      this.setState({
        totalEmission: totalEmission,
      })
    }

    const optionsTotalRevenue = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_revenue`
    };
    const responseTotalRevenue = await axios(optionsTotalRevenue);
    let totalRevenue = [];
    if (responseTotalRevenue.data.success) {
      totalRevenue = responseTotalRevenue.data.data;
      this.setState({
        totalRevenue: totalRevenue,
      })
    }

    /*let ctrLastyear = 0;
    let ctrPrevyear = 0;
    ctrLastyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);
    ctrPrevyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);*/

    const optionsSetting = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
    };
    const responseSetting = await axios(optionsSetting);
    let tempRevenueUnit = "";
    if (responseSetting.data.success) {
      // eslint-disable-next-line
      if (responseSetting.data.data[0].client_revenue_unit == 1) {
        tempRevenueUnit = "IDR mn";
      } else {
        tempRevenueUnit = "USD k";
      }
    }

    this.setState({
      categoryMonthlyEmission: categoryMonthlyEmission,
      companyYearlyEmission: companyYearlyEmission,
      totalEmissionCurrentYear: calcTotalEmissionCurrentYear,
      divisionYearlyEmission: divisionYearlyEmission,
      highestCategoryEmission: highestCategory,
      highestCompanyEmission: highestCompany,
      revenueUnit: tempRevenueUnit,
      listYear: listYear,
      selectedScenario: responseSetting.data.data[0].client_scenario,

      scenarioAnalysisData: scenarioAnalysisData,
    })


    hidePreloader();
  }

  onReload = async () => {
    showPreloader();
    let listCompany = [];

    let selectedLanguage = this.props.i18n.language;

    let listCategory = [];
    let storeScopeMonthlyEmission = [];
    let storeCategoryMonthlyEmission = [];

    let storeSummaryMonthlyEmission = [];
    let labelCompanySummary = [];
    let dataSummary1 = [];
    let dataSummary2 = [];
    let dataSummary3 = [];
    let dataSummary4 = [];
    let dataSummary5 = [];
    let dataSummary6 = [];
    let dataSummary7 = [];
    let dataSummary8 = [];
    let dataSummary9 = [];
    let dataSummary10 = [];
    let dataSummary11 = [];
    let dataSummary12 = [];
    labelCompanySummary.push('Company');
    dataSummary1.push('Jan');
    dataSummary2.push('Feb');
    dataSummary3.push('Mar');
    dataSummary4.push('Apr');
    dataSummary5.push('May');
    dataSummary6.push('Jun');
    dataSummary7.push('Jul');
    dataSummary8.push('Aug');
    dataSummary9.push('Sep');
    dataSummary10.push('Oct');
    dataSummary11.push('Nov');
    dataSummary12.push('Dec');

    let selectedCompany = [];

    const data = {
      'year': this.state.selectedYear
    };

    const optionsGetEmissionCategory = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_all`
    };
    const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
    if (responseGetEmissionCategory.data.success) {
      listCategory = responseGetEmissionCategory.data.data
    }

    let labelCategorySummary = [];
    labelCategorySummary.push('Category');
    listCategory.forEach(element => {
      if (selectedLanguage === "en") {
        labelCategorySummary.push(element.category_name);
      } else {
        labelCategorySummary.push(element.category_name_id);
      }
    })

    this.getCompany()
      .then((result) => {
        if (result !== null) {
          listCompany = result;
        }
      }).then(() => {

        listCompany.map(async element => {
          selectedCompany.push(element.company_id)
          this.getSummaryMonthly(this.state.selectedYear, element.company_id)
            .then((responseGetSummaryMonthly) => {
              if (responseGetSummaryMonthly !== null) {
                labelCompanySummary.push(element.company_name);

                let dataRows = responseGetSummaryMonthly;
                dataRows.forEach((element, index) => {
                  // eslint-disable-next-line
                  if (index == 0) {
                    dataSummary1.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 1) {
                    dataSummary2.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 2) {
                    dataSummary3.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 3) {
                    dataSummary4.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 4) {
                    dataSummary5.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 5) {
                    dataSummary6.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 6) {
                    dataSummary7.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 7) {
                    dataSummary8.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 8) {
                    dataSummary9.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 9) {
                    dataSummary10.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 10) {
                    dataSummary11.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 11) {
                    dataSummary12.push(element.total);
                  }
                });
              }
            }).then(() => {

            })

          this.getScopeMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetScopeMonthly) => {
              if (responseGetScopeMonthly !== null) {
                let labelScopeSummary = [];

                labelScopeSummary.push('Emission');
                labelScopeSummary.push('Scope 1');
                labelScopeSummary.push('Scope 2');
                // eslint-disable-next-line
                if (this.props.account['client_scope'] == 1) {
                  labelScopeSummary.push('Scope 3');
                }

                let resultScopeSummary = [];
                resultScopeSummary.push(labelScopeSummary);
                let dataRows = responseGetScopeMonthly;
                dataRows.forEach(element => {
                  let dataScopeSummary = [];
                  dataScopeSummary.push(element.month_name)
                  dataScopeSummary.push(element.total_1)
                  dataScopeSummary.push(element.total_2)
                  // eslint-disable-next-line
                  if (this.props.account['client_scope'] == 1) {
                    dataScopeSummary.push(element.total_3)
                  }
                  resultScopeSummary.push(dataScopeSummary)
                });
                storeScopeMonthlyEmission.push(resultScopeSummary);
              }
            }).then(() => {
              //console.log(storeScopeMonthlyEmission)

              storeSummaryMonthlyEmission[0] = labelCompanySummary;
              storeSummaryMonthlyEmission[1] = dataSummary1;
              storeSummaryMonthlyEmission[2] = dataSummary2;
              storeSummaryMonthlyEmission[3] = dataSummary3;
              storeSummaryMonthlyEmission[4] = dataSummary4;
              storeSummaryMonthlyEmission[5] = dataSummary5;
              storeSummaryMonthlyEmission[6] = dataSummary6;
              storeSummaryMonthlyEmission[7] = dataSummary7;
              storeSummaryMonthlyEmission[8] = dataSummary8;
              storeSummaryMonthlyEmission[9] = dataSummary9;
              storeSummaryMonthlyEmission[10] = dataSummary10;
              storeSummaryMonthlyEmission[11] = dataSummary11;
              storeSummaryMonthlyEmission[12] = dataSummary12;

              this.setState({
                listCompany: listCompany,
                totalScopeMonthlyEmission: storeScopeMonthlyEmission,
                totalSummaryYearlyEmission: storeSummaryMonthlyEmission,
              })
            })

          this.getCategoryMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetCategoryMonthly) => {
              if (responseGetCategoryMonthly !== null) {
                let resultCategorySummary = [];
                resultCategorySummary.push(labelCategorySummary);
                let dataRows = responseGetCategoryMonthly;
                dataRows.forEach(element => {
                  let dataCategorySummary = [];
                  dataCategorySummary.push(element.month_name)
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 1).length > 0) {
                    dataCategorySummary.push(element.total_1)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 2).length > 0) {
                    dataCategorySummary.push(element.total_2)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 3).length > 0) {
                    dataCategorySummary.push(element.total_3)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 4).length > 0) {
                    dataCategorySummary.push(element.total_4)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 5).length > 0) {
                    dataCategorySummary.push(element.total_5)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 6).length > 0) {
                    dataCategorySummary.push(element.total_6)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 7).length > 0) {
                    dataCategorySummary.push(element.total_7)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 8).length > 0) {
                    dataCategorySummary.push(element.total_8)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 9).length > 0) {
                    dataCategorySummary.push(element.total_9)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 10).length > 0) {
                    dataCategorySummary.push(element.total_10)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 11).length > 0) {
                    dataCategorySummary.push(element.total_11)
                  }
                  if (listCategory.filter(p => p.category_id == 12).length > 0) {
                    dataCategorySummary.push(element.total_12)
                  }
                  resultCategorySummary.push(dataCategorySummary)
                });
                storeCategoryMonthlyEmission.push(resultCategorySummary);
              }
            }).then(() => {
              this.setState({
                totalCategoryMonthlyEmission: storeCategoryMonthlyEmission,
              })
            })
        })
      })

    const optionsTotalEmissionCurrentYear = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_current`
    };
    let calcTotalEmissionCurrentYear = [];
    let growth = 0;
    let growthValue = true;
    await axios(optionsTotalEmissionCurrentYear).then((responseTotalEmissionCurrentYear) => {
      if (responseTotalEmissionCurrentYear.data.success) {
        responseTotalEmissionCurrentYear.data.data.forEach(element => {
          calcTotalEmissionCurrentYear.push(element.total);
        });
        growth = ((parseFloat(calcTotalEmissionCurrentYear[0]) - parseFloat(calcTotalEmissionCurrentYear[1])) / parseFloat(calcTotalEmissionCurrentYear[1])) * 100;
        let growthNumber = Math.sign(growth)
        if (Math.sign(growthNumber) === 1) {
          growthValue = false;
        }
        if (isNaN(growth)) {
          growthValue = false;
        }
        this.setState({
          totalEmissionGrowth: growth,
          totalEmissionGrowthValue: growthValue,
        })
      }
    });

    const optionsTotalCategoryEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_category`
    };
    const responseTotalCategoryEmission = await axios(optionsTotalCategoryEmission);
    const categoryMonthlyEmission = [];
    let calcHighestCategory = 0;
    let highestCategory = "";
    categoryMonthlyEmission.push(["Category", "Emission (tCO<sub>2</sub>e)"]);
    if (responseTotalCategoryEmission.data.success) {
      responseTotalCategoryEmission.data.data.forEach(element => {
        if (element.total_emission > calcHighestCategory) {
          calcHighestCategory = element.total_emission;
          if (selectedLanguage === "en") {
            highestCategory = element.category_name;
          } else {
            highestCategory = element.category_name_id;
          }
        }
        if (selectedLanguage === "en") {
          categoryMonthlyEmission.push([element.category_name, element.total_emission])
        } else {
          categoryMonthlyEmission.push([element.category_name_id, element.total_emission])
        }
      });
    }

    const optionsDivisionYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_division`
    };
    const responseDivisionYearlyEmission = await axios(optionsDivisionYearlyEmission);
    const divisionYearlyEmission = [];
    //let calcHighestCategory = 0;
    //let highestCategory = "";
    divisionYearlyEmission.push(["Division", "Emission (tCO<sub>2</sub>e)"]);
    if (responseDivisionYearlyEmission.data.success) {
      responseDivisionYearlyEmission.data.data.forEach(element => {
        /*if(element.total_emission > calcHighestCategory){
            calcHighestCategory = element.total_emission;
            highestCategory = element.category_name;
        }*/
        divisionYearlyEmission.push([element.group_name, element.total_emission])
      });
    }

    const optionsCompanyYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/company_yearly_emission`
    };
    const responseCompanyYearlyEmission = await axios(optionsCompanyYearlyEmission);
    const companyYearlyEmission = [];
    let highestCompany = "";
    companyYearlyEmission.push(["Company", "Emission (tCO<sub>2</sub>e)"]);
    if (responseCompanyYearlyEmission.data.success) {
      responseCompanyYearlyEmission.data.data.forEach((element, index) => {
        // eslint-disable-next-line
        if (index == 0) {
          highestCompany = element.company_name;
        }
        companyYearlyEmission.push([element.company_name, element.total_emission])
      });
    }

    const optionsTotalEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission`
    };
    const responseTotalEmission = await axios(optionsTotalEmission);
    let totalEmission = [];
    if (responseTotalEmission.data.success) {
      totalEmission = responseTotalEmission.data.data;
      this.setState({
        totalEmission: totalEmission,
      })
    }

    const optionsTotalRevenue = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_revenue`
    };
    const responseTotalRevenue = await axios(optionsTotalRevenue);
    let totalRevenue = [];
    if (responseTotalRevenue.data.success) {
      totalRevenue = responseTotalRevenue.data.data;
      this.setState({
        totalRevenue: totalRevenue,
      })
    }

    /*let ctrLastyear = 0;
    let ctrPrevyear = 0;
    ctrLastyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);
    ctrPrevyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);*/

    const optionsSetting = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
    };
    const responseSetting = await axios(optionsSetting);
    let tempRevenueUnit = "";
    if (responseSetting.data.success) {
      // eslint-disable-next-line
      if (responseSetting.data.data[0].client_revenue_unit == 1) {
        tempRevenueUnit = "IDR mn";
      } else {
        tempRevenueUnit = "USD k";
      }
    }

    this.setState({
      categoryMonthlyEmission: categoryMonthlyEmission,
      companyYearlyEmission: companyYearlyEmission,
      totalEmissionCurrentYear: calcTotalEmissionCurrentYear,
      divisionYearlyEmission: divisionYearlyEmission,
      highestCategoryEmission: highestCategory,
      highestCompanyEmission: highestCompany,
      revenueUnit: tempRevenueUnit,
    })
    hidePreloader();
  }

  getCompany = async () => {
    const data = {};
    const optionsGetCompany = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
    };
    const responseGetCompany = await axios(optionsGetCompany);
    if (responseGetCompany.data.success) {
      return responseGetCompany.data.data;
    } else {
      return null;
    }
  }

  getSummaryMonthly = async (year, company) => {
    const data = {
      'year': year,
      'company': company,
    };
    const optionsGetSummaryMonthly = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/summary_yearly_emission`
    };
    const responseGetSummaryYearly = await axios(optionsGetSummaryMonthly);
    if (responseGetSummaryYearly.data.success) {
      return responseGetSummaryYearly.data.data;
    } else {
      return null;
    }
  }

  getScopeMonthly = async (company, year) => {
    const dataCollect = {
      'company': company,
      'year': year,
    };
    const optionsScopeMonthlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(dataCollect),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/scope_monthly_emission`
    };
    const responseScopeMonthlyEmission = await axios(optionsScopeMonthlyEmission);
    if (responseScopeMonthlyEmission.data.success) {
      return responseScopeMonthlyEmission.data.data;
    } else {
      return null;
    }
  }

  getCategoryMonthly = async (company, year) => {
    console.log(this.state.selectedYear, 'this.state.selectedYear onrelod', year)

    const dataCollect = {
      'company': company,
      'year': year,
    };
    const optionsCategoryMonthlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(dataCollect),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/category_monthly_emission`
    };
    const responseCategoryMonthlyEmission = await axios(optionsCategoryMonthlyEmission);
    if (responseCategoryMonthlyEmission.data.success) {
      return responseCategoryMonthlyEmission.data.data;
    } else {
      return null;
    }
  }

  onSelectMode = async (e) => {
    let modeSelected = e.target.value;
    await this.reloadSummaryYearly(this.state.listCompany, this.state.companySelected, modeSelected).then((result) => {
      console.log(result)
      this.setState({
        selectedMode: modeSelected,
        totalSummaryYearlyEmission: result,
      })
    })
  }

  onSelectYear = async (e) => {
    let yearSelected = e.target.value;

    this.setState({
      selectedYear: yearSelected,
    }, () => {
      this.onReload();
    })
  }

  onSelectScenario = async (e) => {
    let scenarioSelected = e.target.value;

    this.setState({
      selectedScenario: scenarioSelected,
    })
    this.onReload()
  }

  onSelectCompany = async (e) => {
    let companySelected = e.target.value;
    this.setState({
      selectedCompany: companySelected,
    })
    /*await this.reloadSummaryYearly(this.state.listCompany, companySelected, this.state.selectedMode).then((result) => {
        console.log(result)
        this.state.selectedCompany = companySelected;
        this.state.totalSummaryYearlyEmission = result;
        this.setState({
            selectedCompany: companySelected,
            totalSummaryYearlyEmission: result,
        })
    })*/
  }

  onDownloadYearlyCategory = async () => {
    this.onDownloadImage("yearlyCategory", "yearly-emission-by-category")
  }

  onDownloadYearlyCompany = async () => {
    this.onDownloadImage("yearlyCompany", "yearly-emission-by-company")
  }

  onDownloadYearlyDivision = async (index) => {
    this.onDownloadImage("yearlyDivision", "yearly-emission-by-division")
  }

  onDownloadMonthlyCompany = async () => {
    this.onDownloadImage("monthlyCompany", "monthly-emission-by-company")
  }

  onDownloadMonthlyScope = async (index) => {
    this.onDownloadImage("monthlyScope" + index, "monthly-emission-by-scope-for-" + this.state.listCompany[index].company_name.toLowerCase())
  }

  onDownloadImage = async (element, label) => {
    const canvas = await html2canvas(document.getElementById(element));
    const image = canvas.toDataURL("image/png", 1.0);

    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = label;

    fakeLink.href = image;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  }

  render() {
    const { t } = this.props;
    const { redirectOnboard } = this.state;

    if (redirectOnboard) {
      return <Navigate to={'/onboard'} />
    }

    let optionCompany = [];
    let labelChartCompany = [];
    optionCompany.push(this.state.selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
    // eslint-disable-next-line
    if (this.state.listCompany != undefined) {
      if (this.state.listCompany.length > 0) {
        this.state.listCompany.map(async (item, index) => {
          labelChartCompany[index] = item.company_name;
          optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
        })
      }
    }

    const presetColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const pieOptions = {
      pieHole: 0.2,
      height: 350,
      is3D: false,
      legend: { position: 'top', maxLines: 5 },
      colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA']
    };

    const columnOptions = {
      legend: { position: 'top', maxLines: 5 },
      isStacked: true,
      vAxis: {
        format: '#,### tCO\u2082e'
      },
      colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA']
    };

    const columnAltOptions = {
      legend: { position: 'top', maxLines: 5 },
      isStacked: true,
      vAxis: {
        format: '#,### tCO\u2082e'
      },
      colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA'] // 16 items
    };

    let optionScope = [];
    this.state.totalScopeMonthlyEmission.map(async (item, index) => {
      optionScope.push(
        // eslint-disable-next-line
        this.state.selectedCompany == (index + 1) ?
          <div key={index} className="col-12">
            <div className="card card-animate">
              <div className="card-header align-items-center d-flex justify-content-between">
                <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('dashboard.card.title10')}</strong></h4>
                <button type="button" onClick={() => this.onDownloadMonthlyScope(index)} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>
              </div>

              <div key={index} className="card-body" id={"monthlyScope" + index}>
                <Chart chartType="ColumnChart" width="100%" height="250px" data={item} options={columnOptions} />
              </div>
            </div>
          </div> : <div key={index}></div>
      )
    })

    let optionCategory = [];
    this.state.totalCategoryMonthlyEmission.map((item, index) => {
      optionCategory.push(
        this.state.selectedCompany == (index + 1) ?
          <div key={index} className="col-12">
            <div className="card card-animate">
              <div className="card-header align-items-center d-flex justify-content-between">
                <h4 className="card-title mb-0 flex-grow-1">
                  <strong>{this.props.t('dashboard.card.title11')}</strong>
                </h4>
                <button
                  type="button"
                  onClick={() => this.onDownloadMonthlyCategory(index)}
                  className="btn btn-info"
                >
                  {this.props.t('dashboard.btnDownload')}
                </button>
              </div>

              <div
                key={index}
                className="card-body"
                id={"monthlyCategory" + index}
              >
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="250px"
                  data={item}
                  options={columnAltOptions}
                />
              </div>
            </div>
          </div> : <div key={index}></div>
      )
      return null
    })

    let subTotalEmissionCurrentYear = ""
    if (this.state.totalEmissionGrowthValue) {
      subTotalEmissionCurrentYear = <p className="mb-0 text-muted"><span className="badge bg-light text-success mb-0"><i className="ri-arrow-down-line align-middle"></i> {parseFloat(isNaN(this.state.totalEmissionGrowth) ? 0 : Math.abs(this.state.totalEmissionGrowth)).toFixed(2)} % </span> vs. previous year</p>;
    } else {
      subTotalEmissionCurrentYear = <p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0"><i className="ri-arrow-up-line align-middle"></i> {parseFloat(isNaN(this.state.totalEmissionGrowth) ? 0 : Math.abs(this.state.totalEmissionGrowth)).toFixed(2)} % </span> vs. previous year</p>
    }

    let ctrLastyear = 0;
    let ctrPrevyear = 0;
    // eslint-disable-next-line
    if (this.state.totalRevenue != undefined) {
      if (this.state.totalRevenue.length > 0) {
        this.state.totalRevenue.map(async (item, index) => {
          // eslint-disable-next-line
          if (item.revenue_year == (this.state.selectedYear)) {
            ctrLastyear = parseFloat(this.state.totalEmission[index].emission_total) / parseFloat(this.state.totalRevenue[index].revenue_total);
          }
          // eslint-disable-next-line
          if (item.revenue_year == (this.state.selectedYear - 1)) {
            ctrPrevyear = parseFloat(this.state.totalEmission[index].emission_total) / parseFloat(this.state.totalRevenue[index].revenue_total);
          }
        })
      }
    }

    let ctrGrowth = parseFloat((ctrLastyear - ctrPrevyear) / ctrPrevyear * 100);

    let growthCTR = "";
    if (ctrGrowth > 0) {
      growthCTR = <p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0"><i className="ri-arrow-up-line align-middle"></i> {parseFloat(!isFinite(ctrGrowth) ? 0 : Math.abs(ctrGrowth)).toFixed(2)} % </span> vs. previous year</p>;
    } else {
      growthCTR = <p className="mb-0 text-muted"><span className="badge bg-light text-success mb-0"><i className="ri-arrow-down-line align-middle"></i> {parseFloat(!isFinite(ctrGrowth) ? 0 : Math.abs(ctrGrowth)).toFixed(2)} % </span> vs. previous year</p>
    }

    let optionYear = [];
    this.state.listYear.map(async (item, index) => {
      optionYear.push(
        <option key={index} value={item.year}>{item.year}</option>
      )
    })

    return (
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">{this.props.t('menu.label44')}</h4>
                <select
                  style={{ width: "auto" }}
                  className="form-select"
                  id="companyStandard"
                  value={this.state.selectedYear}
                  onChange={e => this.onSelectYear(e)}
                >
                  {optionYear}
                </select>
                <select
                  style={{ width: "auto" }}
                  className="form-select"
                  id="companyStandard"
                  value={this.state.selectedYear}
                  onChange={e => this.onSelectYear(e)}
                >
                  {optionYear}
                </select>

                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <a className="text-reset dropdown-btn" href="#setting">
                                            <span className="text-muted"><i className="ri-settings-4-line align-middle me-1 fs-15"></i>Settings</span>
                                        </a>
                                    </ol>
                                </div>*/}

              </div>
            </div>
          </div>

          {/*<div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1"><strong>Corporate Climate Action</strong></h4>
                                </div>

                                <div className="card-body">
                                    <div className="table-responsive table-card">
                                        <table className="table table-borderless table-hover table-nowrap align-middle">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Calculate Baseline Emission</strong></td>
                                                    <td>2022 Baseline</td>
                                                    <td><Link to={'/setting'} type="button" className="btn btn-outline-success">Edit</Link></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Set Goal</strong></td>
                                                    <td>Net Zero</td>
                                                    <td><Link to={'/setting'} type="button" className="btn btn-outline-success">Edit</Link></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Formulate Decarbonization Roadmap</strong></td>
                                                    <td>10 Activities</td>
                                                    <td><Link to={'/activity'} type="button" className="btn btn-outline-success">View</Link></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Asses Climate Risks & Opportunities</strong></td>
                                                    <td>-</td>
                                                    <td><button type="submit" className="btn btn-success">{this.props.t('general.btnSave')}</button></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Disclose with acknowledge framework</strong></td>
                                                    <td>-</td>
                                                    <td><button type="submit" className="btn btn-success">{this.props.t('general.btnSave')}</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-success mb-0">2023 Target</p>
                                            <h4 style={{minHeight:"48px"}} className="mt-4 ff-secondary fw-semibold">10% reduction compared to baseline</h4>
                                            <p className="mb-0 text-muted">To meet the short term target</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-success mb-0">Short Term Target</p>
                                            <h4 style={{minHeight:"48px"}} className="mt-4 ff-secondary fw-semibold">30% reduction by 2030</h4>
                                            <p className="mb-0 text-muted">Compared to 2021 baseline year</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-success mb-0">Long Term Target</p>
                                            <h4 style={{minHeight:"48px"}} className="mt-4 ff-secondary fw-semibold">Net Zero by 2050 for scope 1 & scope 2</h4>
                                            <p className="mb-0 text-muted">Not yet submitted to SBTi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}

          <div className="row">
            <div className="col-md-3">
              <div className="card card-animate">
                <div className="card-body" style={{ minHeight: "170px" }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">{this.props.t('dashboard.card.title1')}<br />&nbsp;</p>
                      <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{isNaN(parseInt(this.state.totalEmissionCurrentYear)) ? 0 : parseFloat(this.state.totalEmissionCurrentYear).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> <span style={{ fontSize: "var(--vz-body-font-size)" }} className="text-muted">tCO<sub>2</sub>e</span></h3>
                      {/* eslint-disable-next-line */}
                      {this.state.selectedYear != this.state.currentYear ? <></>/*subTotalEmissionCurrentYear*/ : <></>}
                      {/*<p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0"><i className="ri-arrow-up-line align-middle"></i> 0 % </span> vs. previous year</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-animate">
                <div className="card-body" style={{ minHeight: "170px" }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">{this.props.t('dashboard.card.title2')}</p>
                      <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{this.state.highestCategoryEmission}</span></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-animate">
                <div className="card-body" style={{ minHeight: "170px" }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">{this.props.t('dashboard.card.title3')}</p>
                      <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{this.state.highestCompanyEmission}</span></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-animate">
                <div className="card-body" style={{ minHeight: "170px" }}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="fw-medium text-muted mb-0">{this.props.t('dashboard.card.title4')}<br />&nbsp;</p>
                      <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{ctrLastyear.toFixed(2)}</span> <span style={{ fontSize: "var(--vz-body-font-size)" }} className="text-muted">tCO<sub>2</sub>e/{this.state.revenueUnit}</span></h3>
                      {/* eslint-disable-next-line */}
                      {this.state.selectedYear != this.state.currentYear ? <></>/*growthCTR*/ : <></>}
                      {/*<p className="mb-0 text-muted"><span className="badge bg-light text-danger mb-0"><i className="ri-arrow-up-line align-middle"></i> 0 % </span> vs. previous year</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">

            {/*<div className="col-lg-6 col-sm-12">
                            <div className="card card-animate">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h4 className="card-title mb-0 flex-grow-1"><strong>Yearly Emission by<br/>Category</strong></h4>
                                </div>

                                <div className="card-body" id="yearlyCategory">
                                    <ul className="nav nav-tabs nav-justified mb-3" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#base-justified-category" role="tab" aria-selected="false">
                                                Category
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#base-justified-company" role="tab" aria-selected="false">
                                                Company
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#base-justified-division" role="tab" aria-selected="false">
                                                Division
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content  text-muted">
                                        <div className="tab-pane active" id="base-justified-category" role="tabpanel">
                                            {/*<h4 className="card-title mb-0 flex-grow-1"><strong>Total Emissions : {priceFormat(this.state.totalCategoryMonthlyEmission.toFixed(2))} tCO<sub>2</sub>e</strong></h4>
                                            <p className='text-muted'>Scope 1 & Scope 2</p>*
                                            <Chart chartType="PieChart" data={this.state.categoryMonthlyEmission} options={pieOptions} />
                                            <button type="button" onClick={this.onDownloadYearlyCategory} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>

                                        </div>
                                        <div className="tab-pane" id="base-justified-company" role="tabpanel">
                                            {/*<h4 className="card-title mb-0 flex-grow-1"><strong>Total Emissions : {priceFormat(this.state.totalCategoryMonthlyEmission.toFixed(2))} tCO<sub>2</sub>e</strong></h4>
                                            <p className='text-muted'>Scope 1 & Scope 2</p>*
                                            <Chart chartType="PieChart" data={this.state.companyYearlyEmission} options={pieOptions} />

                                            <button type="button" onClick={this.onDownloadYearlyCompany} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>

                                        </div>
                                        <div className="tab-pane" id="base-justified-division" role="tabpanel">
                                            {/*<h4 className="card-title mb-0 flex-grow-1"><strong>Total Emissions : {priceFormat(this.state.totalCategoryMonthlyEmission.toFixed(2))} tCO<sub>2</sub>e</strong></h4>
                                            <p className='text-muted'>Scope 1 & Scope 2</p>*
                                            <Chart chartType="PieChart" data={this.state.divisionYearlyEmission} options={pieOptions} />
                                            {/*<PieChart width={800} height={400}>
                                                <Pie
                                                    data={this.state.divisionYearlyEmission}
                                                    cx={120}
                                                    cy={200}
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                >
                                                    {this.state.divisionYearlyEmission.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={presetColors[index % presetColors.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>

                                            <button type="button" onClick={this.onDownloadYearlyDivision} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="card card-animate">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h4 className="card-title mb-0 flex-grow-1"><strong>Implementation</strong></h4>
                                </div>

                                <div className="card-body" id="yearlyCompany" style={{ width: '100%', height: '400px', marginBottom: '100px' }}>
                                    <select className="form-select mb-5" id="companyStandard" value={this.state.selectedScenario} onChange={e => this.onSelectScenario(e)} >
                                        <option value="1">Baseline</option>
                                        <option value="2">Target</option>
                                        <option value="3">Business as Usual</option>
                                    </select>
                                    <ResponsiveContainer>
                                        <LineChart
                                            data={this.state.scenarioAnalysisData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                            >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line
                                                type="monotone"
                                                dataKey="emission"
                                                stroke="#8884d8"
                                                activeDot={{ r: 8 }}
                                            />
                                            <Line type="monotone" dataKey="target" stroke="#82ca9d" strokeDasharray="5 5" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>*/}

            <div className="col-lg-4 col-sm-12">
              <div className="card card-animate">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('dashboard.card.title5')}</strong></h4>
                  <button type="button" onClick={this.onDownloadYearlyCategory} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>
                </div>

                <div className="card-body" id="yearlyCategory">
                  {/*<h4 className="card-title mb-0 flex-grow-1"><strong>Total Emissions : {priceFormat(this.state.totalCategoryMonthlyEmission.toFixed(2))} tCO<sub>2</sub>e</strong></h4>
                                    <p className='text-muted'>Scope 1 & Scope 2</p>*/}
                  <Chart chartType="PieChart" data={this.state.categoryMonthlyEmission} options={pieOptions} />
                  {/*<PieChart width={400} height={400}>
                                        <Pie data={this.state.categoryMonthlyEmission} dataKey="value" cx="50%" cy="50%" innerRadius={60} fill="#8884d8" label />
                                    </PieChart>*/}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12">
              <div className="card card-animate">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('dashboard.card.title6')}</strong></h4>
                  <button type="button" onClick={this.onDownloadYearlyCompany} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>
                </div>

                <div className="card-body" id="yearlyCompany">
                  {/*<h4 className="card-title mb-0 flex-grow-1"><strong>Total Emissions : {priceFormat(this.state.totalCategoryMonthlyEmission.toFixed(2))} tCO<sub>2</sub>e</strong></h4>
                                    <p className='text-muted'>Scope 1 & Scope 2</p>*/}
                  <Chart chartType="PieChart" data={this.state.companyYearlyEmission} options={pieOptions} />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12">
              <div className="card card-animate">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('dashboard.card.title7')}</strong></h4>
                  <button type="button" onClick={this.onDownloadYearlyDivision} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>
                </div>

                <div className="card-body" id="yearlyDivision">
                  {/*<h4 className="card-title mb-0 flex-grow-1"><strong>Total Emissions : {priceFormat(this.state.totalCategoryMonthlyEmission.toFixed(2))} tCO<sub>2</sub>e</strong></h4>
                                    <p className='text-muted'>Scope 1 & Scope 2</p>*/}
                  <Chart chartType="PieChart" data={this.state.divisionYearlyEmission} options={pieOptions} />
                </div>
              </div>
            </div>

            {/*<div className="col-12">
                            <div className="card card-animate">
                                <div className="card-header align-items-center d-flex justify-content-between">
                                    <h4 className="card-title mb-0 flex-grow-1"><strong>Monthly Total Emission</strong></h4>
                                    <button type="button" onClick={this.onDownloadMonthlyCompany} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>
                                    {/*<div className="col-lg-8" style={{float:"right"}}>
                                        <select onChange={e => this.onSelectMode(e)} value={this.state.selectedMode} id="mode" name="mode" className="form-select mb-3">
                                            <option value={0}>All Company</option>
                                            <option value={1}>Selected Company</option>
                                        </select>
                                        {this.state.selectedMode > 0 ?
                                        <Select isMulti options={options} onChange={(choice) => this.onSelectCompany(choice)}/> : ""}
                                    </div>*
                                </div>

                                {/*<div className="card-body">
                                    <Chart chartType="AreaChart" width="100%" height="250px" data={this.state.totalSummaryYearlyEmission} options={columnOptions} />
                                </div>*
                                <div className="card-body" id="monthlyCompany">
                                    <Chart chartType="ColumnChart" width="100%" height="250px" data={this.state.totalSummaryYearlyEmissionDummy} options={columnOptions} />
                                </div>
                            </div>
                        </div>*/}

            <div className="col-12">
              <div className="card card-animate">
                <div className="card-header align-items-center d-flex justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('dashboard.card.title8')}</strong></h4>
                  <button type="button" onClick={this.onDownloadMonthlyCompany} className="btn btn-info">{this.props.t('dashboard.btnDownload')}</button>
                  {/*<div className="col-lg-8" style={{float:"right"}}>
                                        <select onChange={e => this.onSelectMode(e)} value={this.state.selectedMode} id="mode" name="mode" className="form-select mb-3">
                                            <option value={0}>All Company</option>
                                            <option value={1}>Selected Company</option>
                                        </select>
                                        {this.state.selectedMode > 0 ?
                                        <Select isMulti options={options} onChange={(choice) => this.onSelectCompany(choice)}/> : ""}
                                    </div>*/}
                </div>

                {/*<div className="card-body">
                                    <Chart chartType="AreaChart" width="100%" height="250px" data={this.state.totalSummaryYearlyEmission} options={columnOptions} />
                                </div>*/}

                <div className="card-body" id="monthlyCompany">
                  <Chart chartType="ColumnChart" width="100%" height="250px" data={this.state.totalSummaryYearlyEmission} options={columnOptions} />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="card-header align-items-center d-flex justify-content-between">
                  <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('dashboard.card.title9')}</strong></h4>
                  <select style={{ width: "auto" }} onChange={e => this.onSelectCompany(e)} value={this.state.selectedCompany} id="mode" name="mode" className="form-select mb-3">
                    {optionCompany}
                  </select>
                </div>
              </div>
            </div>

            {optionScope}

            {optionCategory}

          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  count: state.counter.value,
  loggedIn: state.loggedIn.value,
  account: state.account.value,
  company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Company));