import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

export default function InputNumeric({
    id,
    name,
    value,
    placeholder,
    onValueChange,
    disabled = false,
    ...restProps
}) {
    const { t, i18n } = useTranslation()

    return <NumericFormat
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        className="form-control"
        onValueChange={onValueChange}
        placeholder={placeholder || t('emission.form.enter')}
        decimalSeparator={i18n.language === 'id' ? ',' : '.'}
        thousandSeparator={i18n.language === 'id' ? '.' : ','}
        {...restProps}
    />
}
