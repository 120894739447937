import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table'
import {
    MRT_Localization_ID,
} from 'material-react-table/locales/id'
import {
    MRT_Localization_EN,
} from 'material-react-table/locales/en'
import { Chart } from "react-google-charts"

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios"
import qs from 'qs'
import Swal from 'sweetalert2'
import moment from 'moment'
import {
    withTranslation,
    useTranslation,
} from 'react-i18next'

import {
    hidePreloader,
    showPreloader,
    twoDecimalFormat,
    integerFormat,
    ROUTE_PATHS,
} from '../library/helper'

const useTableLookup = () => {
    return useQuery({
        queryKey: [
            'supplychain/get_all',
        ],
        retry: false,
        queryFn: ({ signal }) => axios({
            url: [
                process.env.REACT_APP_BASE_SERVER,
                '/supplychain/get_all',
            ].join(''),
            method: 'POST',
            withCredentials: true,
            signal,
        }).then(response => {
            return response?.data?.data ?? []
        }),
    })
}

const useTable = () => {
    const { t, i18n } = useTranslation()

    const {
        data = [],
        isLoading,
        isRefetching,
    } = useTableLookup()

    const onDeleteCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/delete`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    const onDelete = async (id) => {
        let invalidData = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = {
                    'id': id,
                };
                const result = await onDeleteCall(data)
                if (result) {
                    invalidData = false;
                }

                if (invalidData) {
                    Swal.fire({
                        title: '',
                        html: '<p>Cannot delete data, please try again later</p>',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Deleted',
                        text: "Your data has been deleted.",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            }
        })
    }

    return useMaterialReactTable({
        enableBottomToolbar: true,
        enableColumnOrdering: true,
        enableDensityToggle: false,
        enableExpandAll: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: true,
        enablePagination: true,
        enableTableFooter: false,
        enableTopToolbar: true,
        initialState: { density: 'compact' },
        localization: i18n.language === 'id' ? (
            MRT_Localization_ID
        ) : MRT_Localization_EN,
        muiTablePaperProps: {
            elevation: 0,
        },
        columns: [
            {
                header: t('emission.table.label1'),
                size: 100,
                accessorKey: 'record_date',
                accessorFn: (row) => {
                    return moment(row.record_date).format('YYYY')
                },
                sortingFn: (rowA, rowB) => {
                    const a = rowA.original.record_date
                    const b = rowB.original.record_date

                    if (a > b) {
                        return 1
                    } else if (b > a) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            {
                accessorKey: 'supplier_name',
                header: t('investment.label1'),
                size: 150,
                Cell: ({ cell }) => cell.getValue(),
            },
            {
                accessorKey: 'supplier_industry',
                header: t('investment.label2'),
                size: 150,
                Cell: ({ cell }) => cell.getValue(),
            },
            {
                accessorKey: 'record_amount',
                header: t('investment.label3'),
                size: 100,
                Cell: ({ cell }) => <div className="d-flex flex-column flex-wrap gap-2">
                    <span>
                        {integerFormat(cell.getValue(), i18n.language)}%
                    </span>
                </div>,
            },
            {
                accessorKey: 'record_intensity',
                header: t('investment.label4'),
                size: 100,
                Cell: ({ cell }) => <div className="d-flex flex-column flex-wrap gap-2">
                    <span>
                        {twoDecimalFormat(cell.getValue(), i18n.language)} tCO<sub>2</sub>e
                    </span>
                </div>,
            }, {
                accessorKey: 'record_total',
                header: t('investment.label5'),
                size: 100,
                Cell: ({ cell }) => <div className="d-flex flex-column flex-wrap gap-2">
                    <span>
                        {twoDecimalFormat(cell.getValue(), i18n.language)} tCO<sub>2</sub>e
                    </span>
                </div>,
            },
            {
                accessorKey: 'record_uuid',
                header: t('emission.table.label6'), // Action column
                size: 100,
                enableSorting: false,
                enableColumnFilter: false,
                enableGrouping: false,
                Cell: ({ cell }) => <div className="d-flex flex-wrap gap-2">
                    <Link
                        to={`/scope3/category15/update/${cell.getValue()}`}
                        className="btn btn-info btn-icon waves-effect waves-light"
                    >
                        <i className="ri-pencil-fill"></i>
                    </Link>
                    <button
                        onClick={() => onDelete(cell.getValue())}
                        type="button"
                        className="btn btn-warning btn-icon waves-effect waves-light"
                    >
                        <i className="ri-delete-bin-2-fill"></i>
                    </button>
                </div>,
            },
        ],
        data,
        state: {
            isLoading: isLoading || isRefetching,
        }
    })
}

const ListData = () => {
    const table = useTable()
    return <MaterialReactTable table={table} />
}


export class Supplychain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: new Date().getFullYear() - 1,
        }
    }

    componentDidMount = async () => {
        showPreloader();

        console.log("emission");
        let listYear = [];
        let totalScope3 = 0;
        let totalScope3Investment = 0;
        let highestContributor = "";

        let investmentsByCategory = [];
        let investmentsBySegment = [];

        let listCategory = [];
        let listSegment = [];

        const data = {
            'year': this.state.selectedYear
        };

        const optionsGetYearRecorded = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/year_recorded`
        };
        const responseGetYearRecorded = await axios(optionsGetYearRecorded);
        if (responseGetYearRecorded.data.success) {
            listYear = responseGetYearRecorded.data.data
        }

        if (this.state.selectedYear > 0) {
            const optionsGetTotalScope3 = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-total-lookup`
            };
            const responseGetTotalScope3 = await axios(optionsGetTotalScope3);
            if (responseGetTotalScope3.data.ok) {
                //console.log(responseGetTotalScope3.data.data)
                totalScope3 = responseGetTotalScope3.data.data[0].record_total;
            }

            const optionsGetTotalScope3Investment = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-total-investments-lookup`
            };
            const responseGetTotalScope3Investment = await axios(optionsGetTotalScope3Investment);
            if (responseGetTotalScope3Investment.data.ok) {
                //console.log("Total"+responseGetTotalScope3Investment.data.data)
                totalScope3Investment = responseGetTotalScope3.data.data[0].record_total;
            }

            const optionsGetScope3Category = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-investments-category-lookup`
            };
            const responseGetScope3Category = await axios(optionsGetScope3Category);
            if (responseGetScope3Category.data.ok) {
                //console.log("test"+responseGetScope3Category.data.data.industry_name)
                highestContributor = responseGetScope3Category.data.data[0]?.industry_name;
                //investmentsByCategory = responseGetScope3Category.data.data;
                let listCategoryHeader = [];
                listCategoryHeader.push("Category");
                listCategoryHeader.push("Total");
                listCategory.push(listCategoryHeader)
                responseGetScope3Category.data.data.forEach(element => {
                    let listCategoryItem = [];
                    listCategoryItem.push(element?.industry_name);
                    listCategoryItem.push(element.record_total);
                    listCategory.push(listCategoryItem);
                })
                //console.log(listCategory)
            }

            const optionsGetScope3Segment = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-investments-segment-lookup`
            };
            const responseGetScope3Segment = await axios(optionsGetScope3Segment);
            if (responseGetScope3Segment.data.ok) {
                //console.log(responseGetScope3Segment.data.data)
                let listSegmentHeader = [];
                listSegmentHeader.push("Category");
                listSegmentHeader.push("Total");
                listSegment.push(listSegmentHeader)
                responseGetScope3Segment.data.data.forEach(element => {
                    let listSegmentItem = [];
                    if (element.client_class == 1) {
                        listSegmentItem.push("SME");
                    }
                    if (element.client_class == 2) {
                        listSegmentItem.push("Corporate");
                    }
                    listSegmentItem.push(element.record_total);
                    listSegment.push(listSegmentItem);
                })
            }
        }

        this.setState({
            listYear: listYear,
            totalScope3: totalScope3,
            totalScope3Investment: totalScope3Investment,
            highestContributor: highestContributor,
            investmentsByCategory: listCategory,
            investmentsBySegment: listSegment,
        })

        hidePreloader();
    }

    onReload = async (yearValue) => {
        showPreloader();

        console.log("emission");
        let listYear = [];
        let totalScope3 = 0;
        let totalScope3Investment = 0;
        let highestContributor = "";

        let investmentsByCategory = [];
        let investmentsBySegment = [];

        let listCategory = [];
        let listSegment = [];

        const data = {
            'year': yearValue
        };

        const optionsGetYearRecorded = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/year_recorded`
        };
        const responseGetYearRecorded = await axios(optionsGetYearRecorded);
        if (responseGetYearRecorded.data.success) {
            listYear = responseGetYearRecorded.data.data
        }

        if (yearValue > 0) {
            const optionsGetTotalScope3 = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-total-lookup`
            };
            const responseGetTotalScope3 = await axios(optionsGetTotalScope3);
            if (responseGetTotalScope3.data.ok) {
                //console.log(responseGetTotalScope3.data.data)
                totalScope3 = responseGetTotalScope3.data.data[0].record_total;
            }

            const optionsGetTotalScope3Investment = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-total-investments-lookup`
            };
            const responseGetTotalScope3Investment = await axios(optionsGetTotalScope3Investment);
            if (responseGetTotalScope3Investment.data.ok) {
                //console.log("Total"+responseGetTotalScope3Investment.data.data)
                totalScope3Investment = responseGetTotalScope3.data.data[0].record_total;
            }

            const optionsGetScope3Category = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-investments-category-lookup`
            };
            const responseGetScope3Category = await axios(optionsGetScope3Category);
            if (responseGetScope3Category.data.ok) {
                //console.log("test"+responseGetScope3Category.data.data)
                highestContributor = responseGetScope3Category.data.data[0]?.industry_name;
                //investmentsByCategory = responseGetScope3Category.data.data;
                let listCategoryHeader = [];
                listCategoryHeader.push("Category");
                listCategoryHeader.push("Total");
                listCategory.push(listCategoryHeader)
                responseGetScope3Category.data.data.forEach(element => {
                    let listCategoryItem = [];
                    listCategoryItem.push(element?.industry_name);
                    listCategoryItem.push(element.record_total);
                    listCategory.push(listCategoryItem);
                })
                //console.log(listCategory)
            }

            const optionsGetScope3Segment = {
                withCredentials: true,
                method: 'GET',
                params: data,
                url: `${process.env.REACT_APP_BASE_SERVER}/scope3-dashboard-investments-segment-lookup`
            };
            const responseGetScope3Segment = await axios(optionsGetScope3Segment);
            if (responseGetScope3Segment.data.ok) {
                //console.log(responseGetScope3Segment.data.data)
                let listSegmentHeader = [];
                listSegmentHeader.push("Category");
                listSegmentHeader.push("Total");
                listSegment.push(listSegmentHeader)
                responseGetScope3Segment.data.data.forEach(element => {
                    let listSegmentItem = [];
                    if (element.client_class == 1) {
                        listSegmentItem.push("SME");
                    }
                    if (element.client_class == 2) {
                        listSegmentItem.push("Corporate");
                    }
                    listSegmentItem.push(element.record_total);
                    listSegment.push(listSegmentItem);
                })
            }
        }

        this.setState({
            listYear: listYear,
            totalScope3: totalScope3,
            totalScope3Investment: totalScope3Investment,
            highestContributor: highestContributor,
            investmentsByCategory: listCategory,
            investmentsBySegment: listSegment,
        })

        hidePreloader();
    }


    onDeleteCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/delete`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onDelete = async (id) => {
        let invalidData = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    'id': id,
                };
                const result = this.onDeleteCall(data);
                if (result) {
                    invalidData = false;
                }

                if (invalidData) {
                    Swal.fire({
                        title: '',
                        html: '<p>Cannot delete data, please try again later</p>',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Deleted',
                        text: "Your data has been deleted.",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            }
        })
    }

    onSelectYear = async (e) => {
        let yearValue = e.target.value;
        this.setState({
            selectedYear: yearValue,
        })
        this.onReload(yearValue)
    }

    render() {

        const pieOptions = {
            pieHole: 0.2,
            height: 350,
            is3D: false,
            legend: { position: 'top', maxLines: 5 },
            colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA']
        };

        let optionYear = [];
        if (this.state.listYear != undefined) {
            if (this.state.listYear.length > 0) {
                this.state.listYear.map(async (item, index) => {
                    optionYear.push(
                        <option key={index} value={item.year}>{item.year}</option>
                    )
                })
            }
        }

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">
                                    {this.props.t('menu.scope3Category15Title')}
                                </h4>
                                <select
                                    style={{ width: "auto" }}
                                    className="form-select"
                                    id="companyStandard"
                                    value={this.state.selectedYear}
                                    onChange={e => this.onSelectYear(e)}
                                >
                                    {optionYear}
                                </select>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-xl-4">
                            <div className="card card-animate">
                                <div className="card-body" style={{ minHeight: "170px" }}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">{this.props.t('investment.dashboard.label1')}</p>
                                            <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{parseFloat(this.state.totalScope3).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> <span style={{ fontSize: "var(--vz-body-font-size)" }} className="text-muted">tCO<sub>2</sub>e</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="card card-animate">
                                <div className="card-body" style={{ minHeight: "170px" }}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">{this.props.t('investment.dashboard.label2')}</p>
                                            <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{parseFloat(this.state.totalScope3Investment).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> <span style={{ fontSize: "var(--vz-body-font-size)" }} className="text-muted">tCO<sub>2</sub>e</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4">
                            <div className="card card-animate">
                                <div className="card-body" style={{ minHeight: "170px" }}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fw-medium text-muted mb-0">{this.props.t('investment.dashboard.label3')}</p>
                                            <h3 className="mt-4 ff-secondary fw-semibold"><span className="counter-value">{this.state.highestContributor}</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="card card-animate">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('investment.dashboard.label4')}</strong></h4>
                                </div>

                                <div className="card-body" id="yearlyCategory">
                                    <Chart chartType="PieChart" data={this.state.investmentsByCategory} options={pieOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="card card-animate">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h4 className="card-title mb-0 flex-grow-1"><strong>{this.props.t('investment.dashboard.label5')}</strong></h4>
                                </div>

                                <div className="card-body" id="yearlyCategory">
                                    <Chart chartType="PieChart" data={this.state.investmentsBySegment} options={pieOptions} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">{this.props.t('general.read')}</h5>
                                    <br /><br />
                                    <div className="d-flex flex-wrap gap-2">
                                        <Link
                                            to={ROUTE_PATHS.scope3category15Create}
                                            className="btn btn-success"
                                        >
                                            {this.props.t('general.btnCreate')}
                                        </Link>
                                    </div>
                                </div >
                                <div className="card-body">
                                    <ListData />
                                </div>
                            </div >
                        </div >
                    </div >

                </div >
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Supplychain));