import {
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import ExcelJS from 'exceljs'

import logoGRIPng from '../assets/GRI.png'

import TableHead from '../components/TableHead'
import TableBody from '../components/TableBody'
import Download from '../components/Download'

const thMainTitleStyles = {
  fontSize: '12px',
  fontWeight: 'bold',
}

const subtitleStyle = {
  fontSize: '11px',
}

const thSubtitleTextStyle = {
  ...subtitleStyle,
  backgroundColor: '#438375',
}

const thSubtitleStyles = {
  ...subtitleStyle,
  backgroundColor: '#09564b',
}

const TABLE_ID = 'gri-table'
const GRI_LOGO_ID = 'gri-logo'

const toLocaleString = (number, locales) => {
  const float = number || 0
  return float.toLocaleString(locales, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

const DownloadTableHeader = () => {
  return (
    <tbody>
      <tr>
        <td
          colSpan={9}
        >
          &nbsp;
        </td>
      </tr>
    </tbody >
  )
}

const parseUnit = (string) => {
  if (string === 'tCO2e') {
    return <span>tCO<sub>2</sub>e</span>
  } else if (string === 'm3') {
    return <span>m<sup>3</sup></span>
  } else if (string === 'tonCO2e') {
    return <span>tonCO<sub>2</sub>e</span>
  } else {
    return string
  }
}

function ReportGRI() {
  const { t, i18n } = useTranslation()
  const account = useSelector((state) => state.account.value)

  const defaultEndYear = new Date(new Date().setFullYear(new Date().getFullYear() - 4))

  const [optionYear, setOptionYear] = useState(defaultEndYear)
  const [selectedMulti, setSelectedMulti] = useState([])
  const [ghgTotalHeaders, setGhgTotalHeaders] = useState([])
  const [ghgTotalRows, setGhgTotalRows] = useState([])
  const [recordedYears, setRecordedYear] = useState([])
  const [emissionIntensityHeaders, setEmissionIntensityHeaders] = useState([])
  const [emissionIntensityRows, setEmissionIntensityRows] = useState([])
  const [ghgDetailsHeaders, setGhgDetailsHeaders] = useState([])
  const [ghgDetailsScope1Rows, setGhgDetailsScope1Rows] = useState([])
  const [ghgDetailsScope2Rows, setGhgDetailsScope2Rows] = useState([])
  const [ghgDetailsScope3Rows, setGhgDetailsScope3Rows] = useState([])
  const [emissionFromEnergyScope1Rows, setEmissionFromEnergyScope1Rows] = useState([])
  const [emissionFromEnergyScope1RowsCountSpan, setEmissionFromEnergyScope1RowsCountSpan] = useState({})
  const [emissionFromEnergyScope2Rows, setEmissionFromEnergyScope2Rows] = useState([])
  const [emissionFromEnergyScope2RowsCountSpan, setEmissionFromEnergyScope2RowsCountSpan] = useState({})
  const [totalEmissionFromEnergyScope1Headers, setTotalEmissionFromEnergyScope1Headers] = useState([])
  const [reductionHeaders, setReductionHeaders] = useState([])
  const [reductionRows, setReductionRows] = useState([])
  const [reductionTotals, setReductionTotals] = useState({})
  const [downloadedTableId, setDownloadedTableId] = useState(0)
  const [gRILogoBase64, setGRILogoBase64] = useState(null)
  const [revenueUnit, setRevenueUnit] = useState('')

  const handleMulti = (data, x) => {
    setSelectedMulti(data)
    setOptionYear(prev => {
      return prev
        .map(p => {
          return {
            ...p,
            isDisabled: data.length >= 3 ? true : false,
          }
        })
    })
  }

  useEffect(() => {
    const downloadCanvas = async () => {
      if (downloadedTableId) {
        const canvas = await html2canvas(document.getElementById('reportBody'))
        const image = canvas.toDataURL("image/png", 1.0)

        var imgWidth = 210
        var pageHeight = 300
        var imgHeight = (canvas.height - 30) * imgWidth / canvas.width
        var heightLeft = imgHeight
        var doc = new jsPDF('p', 'mm')
        var position = 8

        doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position += heightLeft - imgHeight + 5 // top padding for other pages
          doc.addPage()
          doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }
        doc.save(`${selectedMulti?.[0]?.value} ${account.client_name} Consolidated Statement of Greenhouse Gas Emissions.pdf`)
        setDownloadedTableId(0)
      }
    }
    downloadCanvas()
  }, [downloadedTableId, selectedMulti, account.client_name])

  useEffect(() => {
    const controller = new AbortController()
    const getYearSummaries = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/record-year-summaries-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          if (Array.isArray(response.data.data)) {
            const lastYear = new Date().getFullYear() - 1
            setOptionYear(response.data.data
              .map(d => ({ value: d.year, label: d.year, isDisabled: true })))
            if (response.data.data.length > 0) {
              const findLastYear = response.data.data.find(d => d.year === lastYear)
              if (findLastYear) {
                setSelectedMulti(response.data.data
                  .filter(d => d.year <= lastYear)
                  .slice(0, 3)
                  .map(d => ({ value: d.year, label: d.year, isDisabled: true }))
                )
              } else {
                // Find the first 3 recorded years
                setSelectedMulti(response.data.data
                  .slice(0, 3)
                  .map(d => ({ value: d.year, label: d.year, isDisabled: true }))
                )
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    getYearSummaries()

    return () => controller.abort()
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    const getGRIReport = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/report-gri-lookup`,
          signal: controller.signal,
          params: {
            selectedYears: selectedMulti.map(selectedYear => selectedYear.value),
            gwp: new URLSearchParams(window.location.search).get('gwp'),
          },
        })
        if (response.data.ok && response.data.data.gHGTotalRows.length > 0) {
          const ghgTotalHeaderItems = [
            {
              key: 1,
              label: 'Scope',
              labelId: 'Cakupan',
            },
            {
              key: 2,
              label: 'Unit',
              labelId: 'Satuan',
            },
          ]
          ghgTotalHeaderItems.push(...response.data.data.recordedYears.map((d, index) => {
            return {
              key: index + 3,
              label: d,
              labelId: d,
            }
          }))
          setGhgTotalHeaders(ghgTotalHeaderItems)
          setGhgTotalRows(response.data.data.gHGTotalRows)
          setRecordedYear(response.data.data.recordedYears)
          const revenueUnitLabel = response.data.data.revenueUnit.unit_label
          setRevenueUnit(response.data.data.revenueUnit.unit_label)
          const emissionIntensityHeaderItems = [
            {
              key: 1,
              label: 'Year',
              labelId: 'Tahun',
            },
            {
              key: 2,
              label: <span>Total Emission Scope 1 + 2 <br />({parseUnit('tCO2e')})</span>,
              labelId: <span>Total Emisi Cakupan 1 + 2 <br />({parseUnit('tCO2e')})</span>,
            },
            {
              key: 3,
              label: <span>Total Revenue <br />({revenueUnitLabel})</span>,
              labelId: <span>Total Pendapatan <br />({revenueUnitLabel})</span>,
            },
            {
              key: 4,
              label: <span>Emission Intensity <br /> Scope 1 + 2  / Revenue <br />({parseUnit('tCO2e')} per {revenueUnitLabel}) <br />[GRI 305-4]</span>,
              labelId: <span>Intensitas Emisi <br />Cakupan 1 + 2 / Pendapatan <br />({parseUnit('tCO2e')} per {revenueUnitLabel}) <br />[GRI 305-4]</span>,
            },
            {
              key: 5,
              label: <span>Emission Intensity <br />Scope 1 + 2  / Total Energy <br />({parseUnit('tCO2e')} per GJ)</span>,
              labelId: <span>Intensitas Emisi<br />Scope 1 + 2  / Total Energi <br />({parseUnit('tCO2e')} per GJ)</span>,
            },
          ]
          setEmissionIntensityHeaders(emissionIntensityHeaderItems)
          setEmissionIntensityRows(response.data.data.emissionIntensityRows)
          const ghgDetailsHeaderItems = [
            {
              key: 1,
              label: 'Scope/Emission Source',
              labelId: 'Cakupan/Sumber Emisi',
            },
          ]
          ghgDetailsHeaderItems.push(...response.data.data.recordedYears.map((year, index) => {
            return {
              key: index + 2,
              label: <span>Total Emission {year} <br />({parseUnit('tCO2e')})</span>,
              labelId: <span>Total Emisi {year} <br />({parseUnit('tCO2e')})</span>,
              year: year,
            }
          }))
          setGhgDetailsHeaders(ghgDetailsHeaderItems)
          setGhgDetailsScope1Rows(response.data.data.emissionScope1Rows)
          setGhgDetailsScope2Rows(response.data.data.emissionScope2Rows)
          setGhgDetailsScope3Rows(response.data.data.emissionScope3Rows)
          const newEmissionFromEnergyScope1Rows = response.data.data.emissionFromEnergyScope1Rows.flatMap(emissionRow => {
            return emissionRow.sources.flatMap(source => {
              const unit = source.years[0].emission_unit ? source.years[0].emission_unit : ''
              return [
                {
                  cellLabel: <span>Activity Data ({parseUnit(unit)}) </span>,
                  cellLabelId: <span>Data Aktifitas ({parseUnit(unit)}) </span>,
                  objectKey: 'activity_data',
                  unit: unit,
                },
                {
                  cellLabel: <span>Total Emission ({parseUnit('tCO2e')})</span>,
                  cellLabelId: <span>Total Emisi ({parseUnit('tCO2e')})</span>,
                  objectKey: 'total',
                },
                {
                  cellLabel: <span>Total Energy (GJ)</span>,
                  cellLabelId: <span>Total Energi (GJ)</span>,
                  objectKey: 'total_energy',
                },
              ].flatMap(parameter => {
                return {
                  emission: emissionRow,
                  sourceData: source,
                  ...parameter,
                }
              })
            })
          })
          setEmissionFromEnergyScope1Rows(newEmissionFromEnergyScope1Rows)
          const countSpan = {
            category: {},
            categoryAndFactor: {}
          }
          const categoryIds = [...new Set(response.data.data.emissionFromEnergyScope1Rows
            .map(item => item.record_category)
          )]

          categoryIds.forEach((categoryId) => {
            const len = newEmissionFromEnergyScope1Rows.filter(row => row.emission.record_category === categoryId).length
            countSpan.category[categoryId] = {
              rowCount: len,
              startIndex: newEmissionFromEnergyScope1Rows.findIndex(row => row.emission.record_category === categoryId),
            }
            const findFactors = response.data.data.emissionFromEnergyScope1Rows
              .find(emission => emission.record_category === categoryId)
            if (findFactors) {
              const factorIds = findFactors.sources.map(source => source.emission_factor_id)
              factorIds.forEach(factorId => {
                countSpan.categoryAndFactor[`${categoryId}//${factorId}`] = {
                  rowCount: 3,
                  startIndex: newEmissionFromEnergyScope1Rows
                    .findIndex(row => row.emission.record_category === categoryId && row.sourceData.emission_factor_id === factorId),
                }
              })
            }
          })
          setEmissionFromEnergyScope1RowsCountSpan({ ...countSpan })

          // Scope 2
          const newEmissionFromEnergyScope2Rows = response.data.data.emissionFromEnergyScope2Rows.flatMap(emissionRow => {
            return emissionRow.sources.flatMap(source => {
              const unit = source.years[0].emission_unit ? `(${source.years[0].emission_unit})` : ''
              return [
                {
                  cellLabel: <span>Activity Data {unit} </span>,
                  cellLabelId: <span>Data Actifitas {unit} </span>,
                  objectKey: 'activity_data',
                  unit: unit,
                },
                {
                  cellLabel: <span>Total Emission ({parseUnit('tCO2e')})</span>,
                  cellLabelId: <span>Total Emisi ({parseUnit('tCO2e')})</span>,
                  objectKey: 'total',
                },
                {
                  cellLabel: <span>Total Energy (GJ)</span>,
                  cellLabelId: <span>Total Energi (GJ)</span>,
                  objectKey: 'total_energy',
                },
              ].flatMap(parameter => {
                return {
                  emission: emissionRow,
                  sourceData: source,
                  ...parameter,
                }
              })
            })
          })
          setEmissionFromEnergyScope2Rows(newEmissionFromEnergyScope2Rows)
          const countSpan2 = {
            category: {},
            categoryAndFactor: {}
          }
          const categoryIds2 = [...new Set(response.data.data.emissionFromEnergyScope2Rows
            .map(item => item.record_category)
          )]

          categoryIds2.forEach((categoryId) => {
            const len = newEmissionFromEnergyScope2Rows.filter(row => row.emission.record_category === categoryId).length
            countSpan2.category[categoryId] = {
              rowCount: len,
              startIndex: newEmissionFromEnergyScope2Rows.findIndex(row => row.emission.record_category === categoryId),
            }
            const findFactors2 = response.data.data.emissionFromEnergyScope2Rows
              .find(emission => emission.record_category === categoryId)
            if (findFactors2) {
              const factorIds2 = findFactors2.sources.map(source => source.emission_factor_id)
              factorIds2.forEach(factorId => {
                countSpan2.categoryAndFactor[`${categoryId}//${factorId}`] = {
                  rowCount: 3,
                  startIndex: newEmissionFromEnergyScope2Rows
                    .findIndex(row => row.emission.record_category === categoryId && row.sourceData.emission_factor_id === factorId),
                }
              })
            }
          })
          setEmissionFromEnergyScope2RowsCountSpan({ ...countSpan2 })
          const totalEmissionFromEnergyHeaderScope1Items = [
            {
              key: 1,
              label: 'GRI',
              labelId: 'GRI',
              colSpan: selectedMulti.length > 3 ? selectedMulti.length - 2 : 1,
            },
            {
              key: 2,
              label: 'Category',
              labelId: 'Kategori',
              colSpan: selectedMulti.length > 3 ? selectedMulti.length - 2 : 1,
            },
            {
              key: 3,
              label: 'Source',
              labelId: 'Sumber',
              colSpan: selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 3 : 1
            },
            {
              key: 4,
              label: 'Metric (Unit)',
              labelId: 'Metrik (Satuan)',
              colSpan: 2,
            },
          ]
          totalEmissionFromEnergyHeaderScope1Items.push(...response.data.data.recordedYears.map((year, index) => {
            return {
              key: index + 5,
              label: year,
              labelId: year,
            }
          }))
          setTotalEmissionFromEnergyScope1Headers(totalEmissionFromEnergyHeaderScope1Items)
          const reductionHeaderItems = [
            {
              key: 1,
              label: 'Category',
              labelId: 'Kategori',
              colSpan: 2,
            },
            {
              key: 2,
              label: 'Initiative',
              labelId: 'Inisiatif',
              colSpan: selectedMulti.length === 2 ? 3 : selectedMulti.length === 1 ? 4 : 2,
            },
            {
              key: 3,
              label: 'Unit',
              labelId: 'Satuan',
            },
          ]
          reductionHeaderItems.push(...response.data.data.recordedYears.map((d, index) => {
            return {
              key: index + 4,
              label: d,
              labelId: d,
            }
          }))
          setReductionHeaders(reductionHeaderItems)
          setReductionRows(response.data.data.reductions)
          setReductionTotals(response.data.data.reductionTotals)
        } else {
          setGhgTotalHeaders([])
          setGhgTotalRows([])
          setRecordedYear([])
          setGhgDetailsScope1Rows([])
          setGhgDetailsScope2Rows([])
          setGhgDetailsScope3Rows([])
          setEmissionFromEnergyScope1Rows([])
          setEmissionFromEnergyScope2Rows([])
          setTotalEmissionFromEnergyScope1Headers([])
          setEmissionFromEnergyScope1RowsCountSpan({})
          setReductionHeaders([])
          setReductionRows([])
          setReductionTotals({})
        }
      } catch (e) {
        console.log(e)
      }

    }
    if (selectedMulti.length > 0) {
      getGRIReport()
    }

    return () => controller.abort()
  }, [selectedMulti])

  useEffect(() => {
    const getBlob = async () => {
      const image = document.getElementById(GRI_LOGO_ID)
      if (image) {
        const res = await fetch(image.src)
        const resBlob = await res.blob()

        const reader = new FileReader()
        reader.onloadend = () => {
          setGRILogoBase64(reader.result)
        }
        reader.readAsDataURL(resBlob)
      }
    }
    getBlob()
  }, [])

  const onDownloadExcel = () => {
    try {
      if (selectedMulti.length > 0) {
        // Create WorkBook
        const wb = new ExcelJS.Workbook()

        // Excel Properties
        wb.creator = 'TruCount'
        wb.lastModifiedBy = 'Trucount System'
        wb.created = new Date()
        wb.modified = new Date()

        // Create Sheet
        const ws = wb.addWorksheet('GRI Report', {
          pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
        })
        ws.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N";

        // Set the column width using letters or numbers
        const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        ws.columns = numbers.map(no => ({ key: no, width: 10 }))
        ws.properties.defaultRowHeight = 12

        // Style attributes
        const font = { size: 10 }
        const centerAlignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
        const leftAlignment = {
          vertical: 'middle', horizontal: 'left'
        }
        const rightAlignment = {
          vertical: 'middle', horizontal: 'right'
        }
        const style = { style: 'thin' }
        const border = {
          top: style,
          left: style,
          bottom: style,
          right: style
        }
        const fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '145ba4' }, // ##145ba4
        }
        const fontColorWhite = { color: { argb: 'ffffff' } } // #ffffff

        const STYLE_TYPE = {
          noBorder: 'no_border',
          default: 'default',
          fillDarkerBlue: 'fill_darker_blue',
          fillBlue: 'fill_blue',
          rightAlignment: 'right_alignment',
          leftAlignment: 'left_alignment',
        }

        const applyCellStyles = (selectedCell, customAlignment) => {
          selectedCell.font = font
          selectedCell.alignment = customAlignment || centerAlignment
          selectedCell.border = border
        }

        const renderCell = ({
          startRow,
          startColumn,
          endRow,
          endColumn,
          value,
          styleType,
        }) => {
          const cell = ws.getRow(startRow).getCell(startColumn)
          cell.value = value
          if (
            Array.isArray(styleType) &&
            styleType.some(type => type === STYLE_TYPE.noBorder)
          ) {
            cell.font = font
            cell.alignment = leftAlignment
          } else {
            applyCellStyles(cell)
            // Overwrite the above styles
            if (
              Array.isArray(styleType) &&
              styleType.some(type => type === STYLE_TYPE.fillDarkerBlue)
            ) {
              cell.fill = fill
              cell.font = { ...font, ...fontColorWhite }
            }
            if (
              Array.isArray(styleType) &&
              styleType.some(type => type === STYLE_TYPE.fillBlue)
            ) {
              const fgColorBlue = { fgColor: { argb: '4b83bc' } } // #4b83bc
              cell.fill = { ...fill, ...fgColorBlue }
              cell.font = { ...font, ...fontColorWhite }
            }
            if (
              Array.isArray(styleType) &&
              styleType.some(type => type === STYLE_TYPE.rightAlignment)
            ) {
              cell.alignment = rightAlignment
            }
            if (
              Array.isArray(styleType) &&
              styleType.some(type => type === STYLE_TYPE.leftAlignment)
            ) {
              cell.alignment = leftAlignment
            }
          }
          ws.mergeCells(startRow, startColumn, endRow, endColumn)
        }

        renderCell({
          startRow: 2,
          startColumn: 1,
          endRow: 2,
          endColumn: 3,
          value: `            ${t('report.gri')}`, // Extra whitespaces to position the text not behind gri logo
          styleType: [STYLE_TYPE.noBorder],
        })

        // Part 1
        const partOne = [
          {
            title: t('report.reportingEntity'),
            value: account.client_name,
            startRow: 4,
          },
          {
            title: t('report.gri.reportingYear'),
            value: Math.max(...selectedMulti.map(({ value }) => value)),
            startRow: 5,
          },
        ]
        partOne.forEach(item => {
          renderCell({
            startRow: item.startRow,
            startColumn: 1,
            endRow: item.startRow,
            endColumn: 2,
            value: item.title,
          })
          renderCell({
            startRow: item.startRow,
            startColumn: 3,
            endRow: item.startRow,
            endColumn: 5,
            value: item.value,
          })
        })

        const buffer = selectedMulti.length === 2 || selectedMulti.length === 1 ? 2 : 0
        let endColumnWholeRow
        if (selectedMulti.length === 3) {
          endColumnWholeRow = 12
        } else if (selectedMulti.length === 2) {
          endColumnWholeRow = 12
        } else if (selectedMulti.length === 1) {
          endColumnWholeRow = 10
        }

        // Part 2
        renderCell({
          startRow: 7,
          startColumn: 1,
          endRow: 7,
          endColumn: endColumnWholeRow,
          value: t('report.gri.totalOfGhgEmissions'),
          styleType: [STYLE_TYPE.fillDarkerBlue],
        })

        ghgTotalHeaders.forEach(({ key, label, labelId }) => {
          let startColumn
          let endColumn
          if (key === 1) {
            startColumn = 1
            endColumn = startColumn + 4 + (buffer)
          } else if (key === 2) {
            startColumn = 6 + buffer
            endColumn = startColumn
          } else if (key >= 3) {
            startColumn = key + key + 1 + buffer
            endColumn = startColumn + 1
          }
          renderCell({
            startRow: 8,
            startColumn: startColumn,
            endRow: 8,
            endColumn: endColumn,
            value: i18n.language === 'id' ? labelId : label,
            styleType: [STYLE_TYPE.fillDarkerBlue],
          })

        })

        const getRecordedYearAttributes = (yearRecords, currentYear, currentIndex) => {
          const findRecord = yearRecords.find(data => data.year === currentYear)
          const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

          let value = '-'
          if (findRecord) {
            value = toLocaleString(findRecord.total, locales)
          }
          const startColumn = currentIndex + 3 + currentIndex + 3 + 1 + buffer
          const endColumn = startColumn + 1
          return {
            startColumn,
            endColumn,
            value,
          }
        }

        ghgTotalRows.forEach(({ label, labelId, years }, index) => {
          const row = 9 + index
          const scopeItemEndColum = 5 + buffer

          renderCell({
            startRow: row,
            startColumn: 1,
            endRow: row,
            endColumn: scopeItemEndColum,
            value: i18n.language === 'id' ? labelId : label,
          })

          renderCell({
            startRow: row,
            startColumn: scopeItemEndColum + 1,
            endRow: row,
            endColumn: scopeItemEndColum + 1,
            value: {
              richText: [
                {
                  font: {
                    size: 10,
                  },
                  text: 'tCO'
                },
                {
                  font: {
                    size: 10,
                    vertAlign: 'subscript',
                  },
                  text: '2',
                },
                {
                  font: {
                    size: 10,
                  },
                  text: 'e',
                },
              ],
            }
          })

          recordedYears.forEach((yearRecorded, index) => {
            const attributes = getRecordedYearAttributes(years, yearRecorded, index)

            renderCell({
              startRow: row,
              startColumn: attributes.startColumn,
              endRow: row,
              endColumn: attributes.endColumn,
              value: attributes.value,
              styleType: [STYLE_TYPE.rightAlignment],
            })
          })
        })

        // Part 3
        const startRowOfPartThree = ghgTotalRows.length + 10
        renderCell({
          startRow: startRowOfPartThree,
          startColumn: 1,
          endRow: startRowOfPartThree,
          endColumn: endColumnWholeRow,
          value: t('report.gri.detailsOfGhgEmissions'),
          styleType: [STYLE_TYPE.fillDarkerBlue]
        })

        ghgDetailsHeaders.forEach(({ key, label, labelId, year }) => {
          let startColumn
          let endColumn
          let ghgDetailsHeaderItemValue
          if (key === 1) {
            startColumn = 1
            endColumn = startColumn + 5 + (buffer)
          } else if (key >= 2) {
            startColumn = key + key + 3 + buffer
            endColumn = startColumn + 1
          }
          if (key === 1) {
            ghgDetailsHeaderItemValue = i18n.language === 'id' ? labelId : label
          } else {
            ghgDetailsHeaderItemValue = {
              richText: [
                {
                  font: {
                    ...font,
                    ...fontColorWhite,
                  },
                  text: i18n.language === 'id' ? 'Total Emisi ' : 'Total Emission ',
                },
                {
                  font: {
                    ...font,
                    ...fontColorWhite,
                  },
                  text: `${year} `,
                },
                {
                  font: {
                    ...font,
                    ...fontColorWhite,
                  },
                  text: '(tCO'
                },
                {
                  font: {
                    ...font,
                    vertAlign: 'subscript',
                    ...fontColorWhite,
                  },
                  text: '2',
                },
                {
                  font: {
                    ...font,
                    ...fontColorWhite,
                  },
                  text: 'e)',
                },
              ]
            }
          }
          renderCell({
            startRow: startRowOfPartThree + 1,
            startColumn: startColumn,
            endRow: startRowOfPartThree + 2,
            endColumn: endColumn,
            value: ghgDetailsHeaderItemValue,
            styleType: [STYLE_TYPE.fillDarkerBlue],
          })
        })

        const renderScopeSubHeader = (startRow, scopeCode) => {
          const value = i18n.language === 'id' ? `Cakupan ${scopeCode}` : `Scope ${scopeCode}`
          renderCell({
            startRow: startRow,
            startColumn: 1,
            endRow: startRow,
            endColumn: endColumnWholeRow,
            value: value,
            styleType: [STYLE_TYPE.fillBlue, STYLE_TYPE.leftAlignment],
          })
        }

        const renderDetailsByScope = (scopeRows, prevLastRow) => {
          scopeRows.forEach(({ label, labelId, years }, scopeRowIndex) => {
            const startColumn = 1
            const endColumn = startColumn + 5 + (buffer)

            const startRow = prevLastRow + 1 + scopeRowIndex
            renderCell({
              startRow: startRow,
              startColumn: startColumn,
              endRow: startRow,
              endColumn: endColumn,
              value: i18n.language === 'id' ? labelId : label,
            })

            recordedYears.forEach((yearRecorded, index) => {
              const {
                startColumn,
                endColumn,
                value,
              } = getRecordedYearAttributes(years, yearRecorded, index)
              renderCell({
                startRow: startRow,
                startColumn: startColumn,
                endRow: startRow,
                endColumn: endColumn,
                value: value,
                styleType: [STYLE_TYPE.rightAlignment],
              })
            })
          })
        }

        // Part 3 - Scope 1
        if (ghgDetailsScope1Rows.length > 0) {
          renderScopeSubHeader(startRowOfPartThree + 3, 1)
          renderDetailsByScope(ghgDetailsScope1Rows, startRowOfPartThree + 3)
        }

        const addedScope1Row = ghgDetailsScope1Rows.length > 0 ? 1 : 0
        const addedScope2Row = ghgDetailsScope2Rows.length > 0 ? 1 : 0
        const addedScope3Row = ghgDetailsScope3Rows.length > 0 ? 1 : 0

        // Part 3 - Scope 2
        if (ghgDetailsScope2Rows.length > 0) {
          renderScopeSubHeader(startRowOfPartThree + 3 + addedScope1Row + ghgDetailsScope1Rows.length, 2)
          renderDetailsByScope(ghgDetailsScope2Rows, startRowOfPartThree + 3 + addedScope1Row + ghgDetailsScope1Rows.length)
        }

        // Part 3 - Scope 3
        if (ghgDetailsScope3Rows.length > 0) {
          renderScopeSubHeader(startRowOfPartThree + 3 + addedScope1Row + ghgDetailsScope1Rows.length + addedScope2Row + ghgDetailsScope2Rows.length, 3)
          renderDetailsByScope(ghgDetailsScope3Rows, startRowOfPartThree + 3 + addedScope1Row + ghgDetailsScope1Rows.length + addedScope2Row + ghgDetailsScope2Rows.length)
        }

        // Part 4
        const startRowOfPartFour = startRowOfPartThree
          + 3
          + addedScope1Row
          + ghgDetailsScope1Rows.length
          + addedScope2Row
          + ghgDetailsScope2Rows.length
          + 1
          + (addedScope3Row)
          + ghgDetailsScope3Rows.length;

        if (emissionIntensityRows.length > 0) {
          const partFourCells = [
            {
              startRow: startRowOfPartFour,
              startColumn: 1,
              endRow: startRowOfPartFour + 2,
              endColumn: 1,
              value: i18n.language === 'id' ? 'Tahun' : 'Year',
              key: 'year',
            },
            {
              startRow: startRowOfPartFour,
              startColumn: 2,
              endRow: startRowOfPartFour + 2,
              endColumn: selectedMulti.length === 1 ? 4 : 6,
              value: {
                richText: [
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: i18n.language === 'id' ? 'Total Emisi Cakupan 1 + 2 ' : 'Total Emission Scope 1 + 2 ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: '(tCO'
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                      vertAlign: 'subscript',
                    },
                    text: '2',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: 'e)',
                  },
                ]
              },
              key: 'emissionScope1And2'
            },
            {
              startRow: startRowOfPartFour,
              startColumn: (selectedMulti.length === 1 ? 4 : 6) + 1,
              endRow: startRowOfPartFour + 2,
              endColumn: (selectedMulti.length === 1 ? 4 : 6) + 1 + 1,
              value: {
                richText: [
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: i18n.language === 'id' ? 'Total Pendapatan ' : 'Total Revenue ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: `(${revenueUnit})`,
                  },
                ]
              },
              key: 'revenueTotal'
            },
            {
              startRow: startRowOfPartFour,
              startColumn: (selectedMulti.length === 1 ? 4 : 6) + 1 + 1 + 1,
              endRow: startRowOfPartFour + 2,
              endColumn: (selectedMulti.length === 1 ? 4 : 6) + 1 + 1 + 1 + 1,
              value: {
                richText: [
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: i18n.language === 'id' ? 'Intensitas Emisi ' : 'Emission Intensity ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: i18n.language === 'id' ? 'Cakupan 1 + 2 / Pendapatan ' : 'Scope 1 + 2  / Revenue ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: '(tCO'
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                      vertAlign: 'subscript',
                    },
                    text: '2',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: 'e ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: `per ${revenueUnit}) [GRI 305-4]`,
                  },
                ]
              },
              key: 'emissionScope1And2PerRevenue'
            },
            {
              startRow: startRowOfPartFour,
              startColumn: (selectedMulti.length === 1 ? 4 : 6) + 1 + 1 + 1 + 1 + 1,
              endRow: startRowOfPartFour + 2,
              endColumn: (selectedMulti.length === 1 ? 4 : 6) + 1 + 1 + 1 + 1 + 1 + 1,
              value: {
                richText: [
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: i18n.language === 'id' ? 'Intensitas Emisi ' : 'Emission Intensity ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: i18n.language === 'id' ? 'Scope 1 + 2  / Total Energi ' : 'Scope 1 + 2  / Total Energy ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: '(tCO'
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                      vertAlign: 'subscript',
                    },
                    text: '2',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: 'e ',
                  },
                  {
                    font: {
                      ...font,
                      ...fontColorWhite,
                    },
                    text: 'per GJ)',
                  },
                ]
              },
              key: 'emissionScope1And2PerEnergy'
            },
          ]

          partFourCells.forEach((item) => {
            renderCell({
              startRow: item.startRow,
              startColumn: item.startColumn,
              endRow: item.endRow,
              endColumn: item.endColumn,
              value: item.value,
              styleType: [STYLE_TYPE.fillDarkerBlue]
            })
          })

          emissionIntensityRows.forEach((row, index) => {
            const startRow = startRowOfPartFour + 2 + (index + 1)
            const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
            const tds = [
              { key: 'year', value: row.year },
              { key: 'emissionScope1And2', value: toLocaleString(row.emissionScope1And2, locales) },
              { key: 'revenueTotal', value: toLocaleString(row.revenueTotal, locales) },
              { key: 'emissionScope1And2PerRevenue', value: toLocaleString(row.emissionScope1And2PerRevenue, locales) },
              { key: 'emissionScope1And2PerEnergy', value: toLocaleString(row.emissionScope1And2PerEnergy, locales) },
            ]
            tds.forEach(td => {
              const findHeader = partFourCells.find(header => header.key === td.key)
              if (findHeader) {
                renderCell({
                  startRow: startRow,
                  startColumn: findHeader.startColumn,
                  endRow: startRow,
                  endColumn: findHeader.endColumn,
                  value: td.value,
                  styleType: td.key !== 'year' ? [STYLE_TYPE.rightAlignment] : null
                })
              }
            })
          })
        }

        // Part 5
        const startRowOfPartFive = startRowOfPartFour
          + (emissionIntensityRows.length > 0 ? 2 : 0)
          + emissionIntensityRows.length
          + (emissionIntensityRows.length > 0 ? 2 : 0);

        renderCell({
          startRow: startRowOfPartFive,
          startColumn: 1,
          endRow: startRowOfPartFive,
          endColumn: endColumnWholeRow,
          value: t('report.gri.totalEmissionFromEnergy'),
          styleType: [STYLE_TYPE.fillDarkerBlue],
        })
        const partFiveCells = [
          {
            startRow: startRowOfPartFive + 1,
            startColumn: 1,
            endRow: startRowOfPartFive + 1,
            endColumn: 1,
            value: 'GRI',
            key: 'gri',
          },
          {
            startRow: startRowOfPartFive + 1,
            startColumn: 2,
            endRow: startRowOfPartFive + 1,
            endColumn: selectedMulti.length === 1 ? 3 : 2,
            value: i18n.language === 'id' ? 'Kategori' : 'Category',
            key: 'category',
          },
          {
            startRow: startRowOfPartFive + 1,
            startColumn: (selectedMulti.length === 1 ? 3 : 2) + 1,
            endRow: startRowOfPartFive + 1,
            endColumn: (selectedMulti.length === 1 ? 3 : 2) + 1 + (selectedMulti.length === 2 ? 2 : 1),
            value: i18n.language === 'id' ? 'Sumber' : 'Source',
            key: 'source',
          },
          {
            startRow: startRowOfPartFive + 1,
            startColumn: (selectedMulti.length === 1 ? 3 : 2) + 1 + (selectedMulti.length === 2 ? 2 : 1) + 1,
            endRow: startRowOfPartFive + 1,
            endColumn: (selectedMulti.length === 1 ? 3 : 2) + 1 + (selectedMulti.length === 2 ? 2 : 1) + 1 + (selectedMulti.length === 3 ? 1 : 2),
            value: i18n.language === 'id' ? 'Metrik (Satuan)' : 'Metric (Unit)',
            key: 'metric',
          },
        ]
        const metricEndColumn = partFiveCells[3].endColumn
        partFiveCells.push(...recordedYears.map((recordedYear, yearIndex) => {
          const startColumn = yearIndex + 1 + metricEndColumn + yearIndex
          const endColumn = startColumn + 1
          return {
            startRow: startRowOfPartFive + 1,
            startColumn: startColumn,
            endRow: startRowOfPartFive + 1,
            endColumn: endColumn,
            value: recordedYear,
            key: recordedYear,
          }
        }))
        partFiveCells.forEach(item => {
          renderCell({
            startRow: item.startRow,
            startColumn: item.startColumn,
            endRow: item.endRow,
            endColumn: item.endColumn,
            value: item.value,
            styleType: [STYLE_TYPE.fillDarkerBlue]
          })
        })

        const renderTotalEmissionFromEnergyByScope = (
          emissionFromEnergyRows,
          scopeCode,
          countSpan,
          previousCellStartRow,
        ) => {
          if (emissionFromEnergyRows.length > 0) {
            emissionFromEnergyRows.forEach((item, index) => {
              const startRow = previousCellStartRow + 1 + (index + 1)
              const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
              let richTextMetricValue
              if (item.objectKey === 'activity_data') {
                richTextMetricValue = i18n.language === 'id' ? `Data Activitas (${item.unit})` : `Activity Data (${item.unit})`
              } else if (item.objectKey === 'total') {
                richTextMetricValue = {
                  richText: [
                    {
                      font: font,
                      text: i18n.language === 'id' ? 'Total Emisi ' : 'Total Emission ',
                    },
                    {
                      font: font,
                      text: '(tCO'
                    },
                    {
                      font: {
                        ...font,
                        vertAlign: 'subscript',
                      },
                      text: '2',
                    },
                    {
                      font: {
                        ...font,
                      },
                      text: 'e)',
                    },
                  ]
                }
              } else if (item.objectKey === 'total_energy') {
                richTextMetricValue = i18n.language === 'id' ? 'Total Energi (GJ)' : 'Total Energy (GJ)'
              }
              const tds = [
                {
                  key: 'gri',
                  value: scopeCode === 1 ? '305-1' : scopeCode === 2 ? '305-2' : '-',
                  rowSpan: emissionFromEnergyRows.length,
                  hide: index !== 0,
                },
                {
                  key: 'category',
                  value: i18n.language === 'id' ? item.emission.labelId : item.emission.label,
                  rowSpan: countSpan.category[item.emission.record_category].rowCount,
                  hide: index !== countSpan.category[item.emission.record_category].startIndex,
                },
                {
                  key: 'source',
                  value: item.sourceData.emission_name ? item.sourceData.emission_name : 'N/A',
                  rowSpan: 3,
                  hide: index !== countSpan
                    .categoryAndFactor[`${item.emission.record_category}//${item.sourceData.emission_factor_id}`].startIndex,
                },
                {
                  key: 'metric',
                  value: richTextMetricValue,
                  rowSpan: 1, hide: false,
                },
              ]
              tds.push(...recordedYears.map(recordedYear => {
                const coreData = item.sourceData.years.find(d => d.year === recordedYear)
                return {
                  key: recordedYear,
                  value: coreData
                    ? toLocaleString(item.objectKey === 'total_energy'
                      ? coreData[item.objectKey] / 1000000000
                      : coreData[item.objectKey], locales)
                    : '-',
                  hide: false,
                  rowSpan: 1,
                  styleType: [STYLE_TYPE.rightAlignment]
                }
              }))
              tds.forEach(td => {
                if (!td.hide) {
                  const findHeader = partFiveCells.find(header => header.key === td.key)
                  if (findHeader) {
                    renderCell({
                      startRow: startRow,
                      startColumn: findHeader.startColumn,
                      endRow: startRow + td.rowSpan - 1,
                      endColumn: findHeader.endColumn,
                      value: td.value,
                      styleType: td.styleType ? td.styleType : null
                    })
                  }
                }
              })
            })
          }
        }

        renderTotalEmissionFromEnergyByScope(
          emissionFromEnergyScope1Rows,
          1,
          emissionFromEnergyScope1RowsCountSpan,
          startRowOfPartFive,
        )
        renderTotalEmissionFromEnergyByScope(
          emissionFromEnergyScope2Rows,
          2,
          emissionFromEnergyScope2RowsCountSpan,
          startRowOfPartFive + emissionFromEnergyScope1Rows.length,
        )

        if (reductionRows.length > 0) {
          // Part 6
          const startRowOfPartSix = startRowOfPartFive + 2 + emissionFromEnergyScope1Rows.length + emissionFromEnergyScope2Rows.length + 1
          renderCell({
            startRow: startRowOfPartSix,
            startColumn: 1,
            endRow: startRowOfPartSix,
            endColumn: endColumnWholeRow,
            value: t('report.gri.totalReduction'),
            styleType: [STYLE_TYPE.fillDarkerBlue],
          })

          const partSixCells = [
            {
              startRow: startRowOfPartSix + 1,
              startColumn: 1,
              endRow: startRowOfPartSix + 1,
              endColumn: 2,
              value: i18n.language === 'id' ? 'Kategori' : 'Category',
              key: 'category',
            },
            {
              startRow: startRowOfPartSix + 1,
              startColumn: 3,
              endRow: startRowOfPartSix + 1,
              endColumn: selectedMulti.length === 3 ? 5 : 7,
              value: i18n.language === 'id' ? 'Inisiatif' : 'Initiative',
              key: 'initiative',
            },
            {
              startRow: startRowOfPartSix + 1,
              startColumn: (selectedMulti.length === 3 ? 5 : 7) + 1,
              endRow: startRowOfPartSix + 1,
              endColumn: (selectedMulti.length === 3 ? 5 : 7) + 1,
              value: i18n.language === 'id' ? 'Satuan' : 'Unit',
              key: 'Unit',
            },
          ]
          const endColumnPartSixUnit = partSixCells[2].endColumn
          partSixCells.push(...recordedYears.map((recordedYear, yearIndex) => {
            const startColumn = yearIndex + 1 + endColumnPartSixUnit + yearIndex
            const endColumn = startColumn + 1
            return {
              startRow: startRowOfPartSix + 1,
              startColumn: startColumn,
              endRow: startRowOfPartSix + 1,
              endColumn: endColumn,
              value: recordedYear,
              key: recordedYear,
            }
          }))
          partSixCells.forEach(item => {
            renderCell({
              startRow: item.startRow,
              startColumn: item.startColumn,
              endRow: item.endRow,
              endColumn: item.endColumn,
              value: item.value,
              styleType: [STYLE_TYPE.fillDarkerBlue]
            })
          })
          reductionRows.forEach((item, index) => {
            const tds = [
              {
                key: 'category',
                value: item.category_name,
                rowSpan: reductionRows.filter(reduction => reduction.category_id === item.category_id).length,
                colSpan: 2,
                hide: index !== reductionRows.findIndex(reduction => reduction.category_id === item.category_id),
              },
              {
                key: 'initiative',
                value: i18n.language === 'id' ? item.initiative_name_id : item.initiative_name,
                rowSpan: 1,
                colSpan: selectedMulti.length === 2 ? 3 : selectedMulti.length === 1 ? 4 : 2,
              },
              {
                key: 'Unit',
                value: {
                  richText: [
                    {
                      font: font,
                      text: 'tCO'
                    },
                    {
                      font: {
                        ...font,
                        vertAlign: 'subscript',
                      },
                      text: '2',
                    },
                    {
                      font: font,
                      text: 'e',
                    },
                  ]
                },
                rowSpan: 1,
                colSpan: 1,
              },
            ]
            tds.push(...recordedYears.map((recordedYear, yearIndex) => {
              const findReduction = item.years.find(itemYear => itemYear.reduction_year === recordedYear)
              const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
              return ({
                column: yearIndex + 4,
                value: findReduction ? toLocaleString(findReduction.reduction_total, locales) : '-',
                rowSpan: 1,
                colSpan: 1,
                key: recordedYear,
                styleType: [STYLE_TYPE.rightAlignment]
              })
            }))
            tds.forEach(td => {
              if (!td.hide) {
                const findHeader = partSixCells.find(header => header.key === td.key)
                if (findHeader) {
                  const startRow = startRowOfPartSix + 1 + (index + 1)
                  renderCell({
                    startRow: startRow,
                    startColumn: findHeader.startColumn,
                    endRow: startRow + td.rowSpan - 1,
                    endColumn: findHeader.endColumn,
                    value: td.value,
                    styleType: td.styleType ? td.styleType : null
                  })
                }
              };
            })
          })

          const partSixTotalRow = [
            {
              startColumn: 1,
              endColumn: selectedMulti.length === 3 ? 5 : 7,
              value: 'Total'
            },
            {
              startColumn: (selectedMulti.length === 3 ? 5 : 7) + 1,
              endColumn: (selectedMulti.length === 3 ? 5 : 7) + 1,
              value: {
                richText: [
                  {
                    font: font,
                    text: 'tCO'
                  },
                  {
                    font: {
                      ...font,
                      vertAlign: 'subscript',
                    },
                    text: '2',
                  },
                  {
                    font: font,
                    text: 'e',
                  },
                ]
              }
            },
          ]
          const endColumnpartSixTotalRowUnit = partSixTotalRow[1].endColumn
          partSixTotalRow.push(...recordedYears.map((recordedYear, yearIndex) => {
            const startColumn = yearIndex + 1 + endColumnpartSixTotalRowUnit + yearIndex
            const endColumn = startColumn + 1
            const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
            const findTotal = reductionTotals.find(row => row.reduction_year === recordedYear)
            const value = findTotal ? toLocaleString(findTotal.reduction_total, locales) : '-'
            return {
              startColumn: startColumn,
              endColumn: endColumn,
              value: value,
              styleType: [STYLE_TYPE.rightAlignment]
            }
          }))
          partSixTotalRow.forEach(item => {
            renderCell({
              startRow: startRowOfPartSix + 2 + reductionRows.length,
              startColumn: item.startColumn,
              endRow: startRowOfPartSix + 2 + reductionRows.length,
              endColumn: item.endColumn,
              value: item.value,
              styleType: item.styleType ? item.styleType : null
            })
          })
        }


        // Add image
        const imageId2 = wb.addImage({
          base64: gRILogoBase64,
          extension: 'png',
        })
        ws.addImage(imageId2, {
          tl: { col: 0, row: 0.5 },
          ext: { width: 30, height: 30 },
          hyperlinks: {
            hyperlink: 'https://www.globalreporting.org/',
            tooltip: 'https://www.globalreporting.org/'
          }
        })

        // Create xlsx file
        const fileLabel = 'Consolidated Statement of Greenhouse Gas Emissions'
        const fileName = `${Math.max(...selectedMulti.map(({ value }) => value))} ${account.client_name} ${fileLabel}`
        wb.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
          const url = window.URL.createObjectURL(blob)
          const anchor = document.createElement("a")
          anchor.href = url
          anchor.download = `${fileName}.xlsx`
          anchor.click()
          window.URL.revokeObjectURL(url)
        })

      }
    } catch (error) {
      console.log(error, 'e')
    }

  }

  return (
    <div className="page-content" >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {t('report.label1')}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div
                className="card-body"
                id="reportBody"
              >
                <div className="row px-3">
                  <div className="align-items-start d-flex justify-content-between mb-3 px-0 gap-2 flex-wrap">
                    <img
                      id={GRI_LOGO_ID}
                      src={logoGRIPng}
                      alt="GRI"
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                    />
                    <Download
                      onClick={onDownloadExcel}
                      style={{
                        width: "31px",
                        height: "31px",
                      }}
                    />
                  </div>

                  <div className="align-items-start d-flex justify-content-between mb-3 px-0 gap-3 flex-wrap">
                    <div>
                      <h4
                        className='fw-bold m-0'
                        style={{ fontSize: '24px' }}
                      >
                        {account.client_name}
                      </h4>
                      <p className='m-0'>
                        {selectedMulti.length === 0 ? '-' : Math.max(...selectedMulti.map(({ value }) => value))}
                      </p>
                    </div>
                    <div
                      className="d-flex flex-wrap gap-2 align-items-baseline justify-content-end"
                    >
                      <Select
                        value={selectedMulti}
                        isMulti={true}
                        onChange={handleMulti}
                        options={optionYear}
                        maxMenuHeight={100}
                      />
                    </div>
                  </div>

                  {selectedMulti.length > 0 && (
                    <div className="col-lg-12 overflow-auto p-0">
                      <table className="table align-middle table-nowrap mb-0"
                        id={TABLE_ID}
                      >
                        <TableHead
                          colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 5 : 0)}
                          ignoreHTML2Canvas={downloadedTableId !== 1}
                        >
                          <tr>
                            <th
                              colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 5 : 1)}
                              style={thMainTitleStyles}
                            >
                              {t('report.gri.totalOfGhgEmissions')}
                            </th>
                          </tr>
                          <tr>
                            {ghgTotalHeaders.map(({ key, label, labelId }) => {
                              let colSpan = 1
                              if (key === 1 && selectedMulti.length === 2) {
                                colSpan = 5
                              } else if (key === 1 && selectedMulti.length === 1) {
                                colSpan = 6
                              } else if (key === 1) {
                                colSpan = 4
                              }
                              return (
                                <th
                                  key={key}
                                  colSpan={colSpan}
                                  style={thSubtitleTextStyle}
                                >
                                  {i18n.language === 'id' ? labelId : label}
                                </th>
                              )
                            })}
                          </tr>
                        </TableHead>
                        <TableBody
                          ignoreHTML2Canvas={downloadedTableId !== 1}
                        >
                          {ghgTotalRows.map((row) => {
                            return (
                              <tr key={row.label}>
                                <td
                                  style={{
                                    fontWeight: row.label === 'Total' ? 'bold' : 'inherit',
                                    textAlign: 'left',
                                  }}
                                  colSpan={selectedMulti.length === 2 ? 5 : selectedMulti.length === 1 ? 6 : 4}
                                >
                                  {i18n.language === 'id' ? row.labelId : row.label}
                                </td>
                                <td
                                  style={{
                                    fontWeight: row.label === 'Total' ? 'bold' : 'inherit',
                                    textAlign: 'left',
                                  }}
                                >
                                  tCO<sub>2</sub>e
                                </td>
                                {recordedYears.map((yearRecorded) => {
                                  const findRecord = row.years.find(data => data.year === yearRecorded)
                                  const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                  let value = '-'
                                  if (findRecord) {
                                    value = toLocaleString(findRecord.total, locales)
                                  }
                                  return (
                                    <td
                                      key={yearRecorded}
                                      style={{
                                        fontWeight: row.label === 'Total' ? 'bold' : 'inherit',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {value}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </TableBody>

                        <DownloadTableHeader
                          onDownload={() => setDownloadedTableId(2)}
                        />

                        <TableHead ignoreHTML2Canvas={downloadedTableId !== 2}>
                          <tr>
                            <th
                              colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 5 : 1)}
                              style={thSubtitleStyles}
                            >
                              {t('report.gri.detailsOfGhgEmissions')}
                            </th>
                          </tr>
                        </TableHead>
                        <TableBody ignoreHTML2Canvas={downloadedTableId !== 2}>
                          <tr>
                            {ghgDetailsHeaders.map(({ key, label, labelId }) => {
                              let colSpan = 1
                              if (key === 1 && selectedMulti.length === 2) {
                                colSpan = 6
                              } else if (key === 1 && selectedMulti.length === 1) {
                                colSpan = 7
                              } else if (key === 1) {
                                colSpan = 5
                              }
                              return (
                                <th
                                  key={key}
                                  colSpan={colSpan}
                                >
                                  {i18n.language === 'id' ? labelId : label}
                                </th>
                              )
                            })}
                          </tr>
                        </TableBody>
                        <TableBody ignoreHTML2Canvas={downloadedTableId !== 2}>
                          {ghgDetailsScope1Rows.length > 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 5 : 1)}
                                >
                                  {i18n.language === 'id' ? 'Cakupan 1' : 'Scope 1'}
                                </td>
                              </tr>
                              {ghgDetailsScope1Rows.map((row) => {
                                return (
                                  <tr key={row.label}>
                                    <td
                                      style={{
                                        fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                        textAlign: 'left',
                                      }}
                                      colSpan={selectedMulti.length === 2 ? 6 : selectedMulti.length === 1 ? 7 : 5}
                                    >
                                      {i18n.language === 'id' ? row.labelId : row.label}
                                    </td>
                                    {recordedYears.map((yearRecorded) => {
                                      const findRecord = row.years.find(data => data.year === yearRecorded)
                                      const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                      let value = toLocaleString('-', locales)
                                      if (findRecord) {
                                        value = toLocaleString(findRecord.total, locales)
                                      }
                                      return (
                                        <td
                                          key={yearRecorded}
                                          style={{
                                            fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {value}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )}
                          {ghgDetailsScope2Rows.length > 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 5 : 1)}
                                >
                                  {i18n.language === 'id' ? 'Cakupan 2' : 'Scope 2'}
                                </td>
                              </tr>
                              {ghgDetailsScope2Rows.map((row) => {
                                return (
                                  <tr key={row.label}>
                                    <td
                                      style={{
                                        fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                        textAlign: 'left',
                                      }}
                                      colSpan={selectedMulti.length === 2 ? 6 : selectedMulti.length === 1 ? 7 : 5}
                                    >
                                      {i18n.language === 'id' ? row.labelId : row.label}
                                    </td>
                                    {recordedYears.map((yearRecorded) => {
                                      const findRecord = row.years.find(data => data.year === yearRecorded)
                                      const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                      let value = toLocaleString('-', locales)
                                      if (findRecord) {
                                        value = toLocaleString(findRecord.total, locales)
                                      }
                                      return (
                                        <td
                                          key={yearRecorded}
                                          style={{
                                            fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {value}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )}
                          {ghgDetailsScope3Rows.length > 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 5 : 1)}
                                >
                                  {i18n.language === 'id' ? 'Cakupan 3' : 'Scope 3'}
                                </td>
                              </tr>
                              {ghgDetailsScope3Rows.map((row) => {
                                return (
                                  <tr key={row.label}>
                                    <td
                                      style={{
                                        fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                        textAlign: 'left',
                                      }}
                                      colSpan={selectedMulti.length === 2 ? 6 : selectedMulti.length === 1 ? 7 : 5}
                                    >
                                      {i18n.language === 'id' ? row.labelId : row.label}
                                    </td>
                                    {recordedYears.map((yearRecorded, index) => {
                                      const findRecord = row.years.find(data => data.year === yearRecorded)
                                      const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                      let value = toLocaleString('-', locales)
                                      if (findRecord) {
                                        value = toLocaleString(findRecord.total, locales)
                                      }
                                      return (
                                        <td
                                          key={yearRecorded}
                                          style={{
                                            fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                            textAlign: 'right',
                                          }}
                                        >
                                          {value}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )}
                        </TableBody>


                        {emissionIntensityRows.length > 0 && (
                          <>
                            <DownloadTableHeader
                              onDownload={() => setDownloadedTableId(3)}
                            />
                            <TableHead
                              ignoreHTML2Canvas={downloadedTableId !== 3}
                            >
                              <tr>
                                {emissionIntensityHeaders.map(({ key, label, labelId }) => {
                                  let colSpan = 1
                                  if (key === 1) {
                                    colSpan = selectedMulti.length > 3 ? selectedMulti.length - 1 : 2
                                  } if (key === 5 || key === 4) {
                                    colSpan = 2
                                  }
                                  return (
                                    <th
                                      key={key}
                                      colSpan={colSpan}
                                      style={thSubtitleStyles}
                                    >
                                      {i18n.language === 'id' ? labelId : label}
                                    </th>
                                  )
                                })}
                              </tr>
                            </TableHead>
                            <TableBody ignoreHTML2Canvas={downloadedTableId !== 3}>
                              {emissionIntensityRows.map((row) => {
                                const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
                                return (
                                  <tr key={row.year}>
                                    <td
                                      colSpan={selectedMulti.length > 3 ? selectedMulti.length - 1 : 2}
                                      style={{
                                        textAlign: 'left',
                                      }}
                                    >
                                      {row.year}
                                    </td>
                                    {[
                                      { key: 1, value: toLocaleString(row.emissionScope1And2, locales) },
                                      { key: 2, value: toLocaleString(row.revenueTotal, locales) },
                                      { key: 3, value: toLocaleString(row.emissionScope1And2PerRevenue, locales) },
                                      { key: 4, value: toLocaleString(row.emissionScope1And2PerEnergy, locales) },
                                    ].map(td => (
                                      <td
                                        key={td.key}
                                        colSpan={td.key === 4 || td.key === 3 ? 2 : 1}
                                        style={{
                                          textAlign: 'right',
                                        }}
                                      >
                                        {td.value}
                                      </td>

                                    ))}

                                  </tr>

                                )
                              })}
                            </TableBody>
                          </>
                        )}

                        {(
                          emissionFromEnergyScope1Rows.length > 0 ||
                          emissionFromEnergyScope2Rows.length > 0
                        ) && (
                            <>
                              <DownloadTableHeader
                                onDownload={() => setDownloadedTableId(4)}
                              />
                              <TableHead ignoreHTML2Canvas={downloadedTableId !== 4}>
                                <tr>
                                  <th
                                    colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 6 : 1)}
                                    style={thSubtitleStyles}
                                  >
                                    {t('report.gri.totalEmissionFromEnergy')}
                                  </th>
                                </tr>
                              </TableHead>
                            </>
                          )}

                        <TableBody
                          ignoreHTML2Canvas={downloadedTableId !== 4}
                        >
                          <tr>
                            {
                              (
                                emissionFromEnergyScope1Rows.length > 0 ||
                                emissionFromEnergyScope2Rows.length > 0
                              ) && totalEmissionFromEnergyScope1Headers.map(({ key, label, labelId, colSpan = 1 }) => {
                                return (
                                  <th
                                    key={key}
                                    colSpan={colSpan}
                                  >
                                    {i18n.language === 'id' ? labelId : label}
                                  </th>
                                )
                              })
                            }
                          </tr>

                          {emissionFromEnergyScope1Rows.length > 0 && emissionFromEnergyScope1Rows.map((item, index) => {
                            const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
                            const data = [
                              {
                                column: 1,
                                value: '305-1',
                                rowSpan: emissionFromEnergyScope1Rows.length,
                                textAlign: 'left',
                                colSpan: 1,
                                hide: index !== 0,
                              },
                              {
                                column: 2,
                                value: i18n.language === 'id' ? item.emission.labelId : item.emission.label,
                                rowSpan: emissionFromEnergyScope1RowsCountSpan.category[item.emission.record_category].rowCount,
                                textAlign: 'left',
                                colSpan: 1,
                                hide: index !== emissionFromEnergyScope1RowsCountSpan.category[item.emission.record_category].startIndex,
                              },
                              {
                                column: 3,
                                value: item.sourceData.emission_name ? item.sourceData.emission_name : 'N/A',
                                rowSpan: emissionFromEnergyScope1RowsCountSpan
                                  .categoryAndFactor[`${item.emission.record_category}//${item.sourceData.emission_factor_id}`].rowCount,
                                hide: index !== emissionFromEnergyScope1RowsCountSpan
                                  .categoryAndFactor[`${item.emission.record_category}//${item.sourceData.emission_factor_id}`].startIndex,
                                textAlign: 'left',
                                colSpan: selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 3 : 1
                              },
                              {
                                column: 4,
                                value: i18n.language === 'id' ? item.cellLabelId : item.cellLabel,
                                rowSpan: 1,
                                textAlign: 'left',
                                colSpan: 2,
                              },
                            ]
                            data.push(...recordedYears.map((year, index) => {
                              const coreData = item.sourceData.years.find(d => d.year === year)
                              return ({
                                column: index + 5,
                                value: coreData ? toLocaleString(item.objectKey === 'total_energy' ? coreData[item.objectKey] / 1000000000 : coreData[item.objectKey], locales) : '-',
                                rowSpan: 1,
                                textAlign: 'right',
                              })
                            }))
                            return (
                              <tr
                                key={index}
                              >
                                {data.map(d => {
                                  if (d.hide) {
                                    return null
                                  }
                                  return (
                                    <td
                                      key={d.column}
                                      colSpan={d.colSpan ? d.colSpan : 1}
                                      rowSpan={d.rowSpan ? d.rowSpan : 1}
                                      style={{
                                        textAlign: d.textAlign,
                                      }}
                                    >
                                      {d.value}
                                    </td>
                                  )
                                }
                                )}
                              </tr>
                            )
                          })}

                          {
                            emissionFromEnergyScope2Rows.length > 0 && emissionFromEnergyScope2Rows.map((item, index) => {
                              const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
                              const data = [
                                {
                                  column: 1,
                                  value: '305-2',
                                  rowSpan: emissionFromEnergyScope2Rows.length,
                                  textAlign: 'left',
                                  colSpan: 1,
                                  hide: index !== 0,
                                },
                                {
                                  column: 2,
                                  value: i18n.language === 'id' ? item.emission.labelId : item.emission.label,
                                  rowSpan: emissionFromEnergyScope2RowsCountSpan.category[item.emission.record_category]?.rowCount ?? 1,
                                  textAlign: 'left',
                                  colSpan: 1,
                                  hide: index !== emissionFromEnergyScope2RowsCountSpan.category[item.emission.record_category]?.startIndex ?? false,
                                },
                                {
                                  column: 3,
                                  value: item.sourceData.emission_name ? item.sourceData.emission_name : 'N/A',
                                  rowSpan: emissionFromEnergyScope2RowsCountSpan
                                    .categoryAndFactor[`${item.emission.record_category}//${item.sourceData.emission_factor_id}`]?.rowCount ?? 1,
                                  hide: index !== emissionFromEnergyScope2RowsCountSpan
                                    .categoryAndFactor[`${item.emission.record_category}//${item.sourceData.emission_factor_id}`]?.startIndex ?? false,
                                  textAlign: 'left',
                                  colSpan: selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 3 : 1
                                },
                                {
                                  column: 4,
                                  value: i18n.language === 'id' ? item.cellLabelId : item.cellLabel,
                                  rowSpan: 1,
                                  textAlign: 'left',
                                  colSpan: 2,
                                },
                              ]
                              data.push(...recordedYears.map((year, index) => {
                                const coreData = item.sourceData.years.find(d => d.year === year)
                                return ({
                                  column: index + 5,
                                  value: coreData ? toLocaleString(item.objectKey === 'total_energy' ? coreData[item.objectKey] / 1000000000 : coreData[item.objectKey], locales) : '-',
                                  rowSpan: 1,
                                  textAlign: 'right',
                                })
                              }))
                              return (
                                <tr
                                  key={index}
                                >
                                  {data.map(d => {
                                    if (d.hide) {
                                      return null
                                    }
                                    return (
                                      <td
                                        key={d.column}
                                        colSpan={d.colSpan ? d.colSpan : 1}
                                        rowSpan={d.rowSpan ? d.rowSpan : 1}
                                        style={{
                                          textAlign: d.textAlign,
                                        }}
                                      >
                                        {d.value}
                                      </td>)
                                  }
                                  )}
                                </tr>
                              )
                            })
                          }
                        </TableBody >

                        {
                          reductionRows.length > 0 && (
                            <DownloadTableHeader
                              onDownload={() => setDownloadedTableId(5)}
                            />
                          )
                        }

                        {
                          reductionRows.length > 0 && (
                            <TableHead ignoreHTML2Canvas={downloadedTableId !== 5}>
                              <tr>
                                <th
                                  colSpan={selectedMulti.length + 4 + (selectedMulti.length === 2 ? 2 : selectedMulti.length === 1 ? 6 : 1)}
                                  style={thSubtitleStyles}
                                >
                                  {t('report.gri.totalReduction')}
                                </th>
                              </tr>
                            </TableHead>
                          )
                        }

                        <TableBody ignoreHTML2Canvas={downloadedTableId !== 5}>
                          <tr>
                            {reductionRows.length > 0 && reductionHeaders.map(({ key, label, labelId, colSpan = 1 }) => {
                              return (
                                <th
                                  key={key}
                                  colSpan={colSpan}
                                >
                                  {i18n.language === 'id' ? labelId : label}
                                </th>
                              )
                            })}
                          </tr>

                          {reductionRows.length > 0 && reductionRows.map((item, index) => {
                            const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
                            const data = [
                              {
                                column: 1,
                                value: item.category_name,
                                rowSpan: reductionRows.filter(reduction => reduction.category_id === item.category_id).length,
                                textAlign: 'left',
                                colSpan: 2,
                                hide: index !== reductionRows.findIndex(reduction => reduction.category_id === item.category_id),
                              },
                              {
                                column: 2,
                                value: i18n.language === 'id' ? item.initiative_name_id : item.initiative_name,
                                rowSpan: 1,
                                textAlign: 'left',
                                colSpan: selectedMulti.length === 2 ? 3 : selectedMulti.length === 1 ? 4 : 2,
                              },
                              {
                                column: 3,
                                value: parseUnit('tCO2e'),
                                rowSpan: 1,
                                textAlign: 'left  ',
                                colSpan: 1,
                              },
                            ]
                            data.push(...recordedYears.map((year, index) => {
                              const findReduction = item.years.find(itemYear => itemYear.reduction_year === year)
                              return ({
                                column: index + 4,
                                value: findReduction ? toLocaleString(findReduction.reduction_total, locales) : '-',
                                rowSpan: 1,
                                textAlign: 'right',
                              })
                            }))
                            return (
                              <tr
                                key={index}
                              >
                                {data.map(d => {
                                  if (d.hide) {
                                    return null
                                  }
                                  return (
                                    <td
                                      key={`${index} - ${d.column}`}
                                      colSpan={d.colSpan ? d.colSpan : 1}
                                      rowSpan={d.rowSpan ? d.rowSpan : 1}
                                      style={{
                                        textAlign: d.textAlign,
                                      }}
                                    >
                                      {d.value}
                                    </td>
                                  )
                                }
                                )}
                              </tr>
                            )
                          })}

                          {reductionRows.length > 0 && (
                            <tr>
                              <th
                                colSpan={selectedMulti.length === 2 ? 5 : selectedMulti.length === 1 ? 6 : 4}
                              >
                                Total
                              </th>
                              <th style={{ textAlign: 'left' }}>
                                {parseUnit('tCO2e')}
                              </th>
                              {recordedYears.map(year => {
                                const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
                                const findTotal = reductionTotals.find(row => row.reduction_year === year)
                                const value = findTotal ? toLocaleString(findTotal.reduction_total, locales) : '-'
                                return (
                                  <th
                                    key={year}
                                    style={{ textAlign: 'right' }}
                                  >
                                    {value}
                                  </th>
                                )
                              })}
                            </tr>
                          )}
                        </TableBody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div >

        </div >
      </div >
    </div >
  )
}

export default ReportGRI
