import {
  Fragment,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from "axios"
import Swal from 'sweetalert2'
import DatePicker from "react-datepicker"
import SimpleBar from 'simplebar-react'
import { ImNewTab } from "react-icons/im"

import InputNumeric from '../../components/InputNumeric'

import { SCENARIO_LOCAL_STORAGE_KEY } from '../../library/helper'
import styles from './SettingScenario.module.css'

const TootipInputWrapper = ({
  showTooltip,
  tooltipTitle,
  children,
}) => {
  if (showTooltip) {
    const inputWrapperProps = {}
    inputWrapperProps['data-bs-toggle'] = 'tooltip'
    inputWrapperProps['data-bs-trigger'] = 'hover'
    inputWrapperProps['data-bs-placement'] = 'top'
    inputWrapperProps['title'] = tooltipTitle
    return (
      <div {...inputWrapperProps} style={{ width: '100%' }}>
        {children}
      </div>
    )
  } else {
    return children
  }
}

function SettingScenario() {
  const { t } = useTranslation()
  const [selectedBaseline, setSelectedBaseline] = useState(null)
  const [scenarios, setScenarios] = useState([])
  const [scenarioChecked, setScenarioChecked] = useState({})

  useEffect(() => {
    try {
      const getScenarios = async () => {
        const config = {
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/scenario-analysis-setting-summaries-lookup`,
        }
        const response = await axios(config)
        if (response.data.ok) {
          setSelectedBaseline(new Date(`${response.data.data.client_baseline}/01/01`))
          // Sort target_data here
          const sortedScenarios = response.data.data
            .scenarios.map(responseData => {
              return {
                ...responseData,
                data: [...responseData.data]
                  .sort(function (a, b) {
                    return a.target_year - b.target_year
                  }),
              }
            })
          setScenarios(sortedScenarios)
          const checkedScenarioIds = JSON.parse(localStorage.getItem(SCENARIO_LOCAL_STORAGE_KEY))
          const checked = {}
          if (checkedScenarioIds) {
            sortedScenarios.forEach(element => {
              checked[element.id] = checkedScenarioIds.some(id => Number(id) === element.id)
            })
            setScenarioChecked(checked)
          } else {
            sortedScenarios.forEach(element => {
              checked[element.id] = true
            })
            setScenarioChecked(checked)
          }
        }
      }
      getScenarios()
    } catch (error) {
      let message = 'Something went wrong'
      if (error.response?.data?.message) message = error.response.data.message
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }, [])


  const onUpdateScenario = async () => {
    try {
      if (!selectedBaseline) {
        throw new Error(t('setting.scenario.invalidBaselineYear'))
      }
      const data = {
        scenarios: scenarios.filter(scenario => !scenario.is_default),
      }
      if (selectedBaseline) {
        const newBaselineYear = new Date(selectedBaseline).getFullYear()
        data.baseline = newBaselineYear
      }

      const options = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data,
        url: `${process.env.REACT_APP_BASE_SERVER}/scenario-analysis-setting-submission`
      }

      const response = await axios(options)
      if (response.data.ok) {
        // Store checkbox info to local storage
        const checkedIdArray = Object.keys(scenarioChecked)
        const onlyCheckedIds = checkedIdArray.filter(id => !!scenarioChecked[id])
        localStorage.removeItem(SCENARIO_LOCAL_STORAGE_KEY)
        localStorage.setItem(SCENARIO_LOCAL_STORAGE_KEY, JSON.stringify(onlyCheckedIds))
        Swal.fire({
          title: '',
          html: '<p>Successfully update settings</p>',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      }
    } catch (error) {
      let message = t('setting.scenario.invalidInput')
      if (error.response?.data?.message) {
        message = error.response.data.message
      } else if (error?.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onInputScenario = (e, editedScenarioIndex) => {
    setScenarios(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedScenarioIndex]
      return newPrev.toSpliced(editedScenarioIndex, 1, {
        ...findEditedTargetByIndex,
        target_group: e.target.value,
      })
    })

  }

  const onInputNote = (e, editedScenarioIndex, editedTargetIndex) => {
    setScenarios(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedScenarioIndex]
      return newPrev.toSpliced(editedScenarioIndex, 1, {
        ...findEditedTargetByIndex,
        data: findEditedTargetByIndex.data.toSpliced(editedTargetIndex, 1, {
          ...findEditedTargetByIndex.data[editedTargetIndex],
          target_note: e.target.value,
        }),
      })
    })
  }

  const onInputPercentage = (values, sourceInfo, editedScenarioIndex, editedTargetIndex) => {
    if (sourceInfo?.event?.target?.name && values) {
      setScenarios(prev => {
        const newPrev = [...prev]
        const findEditedTargetByIndex = prev[editedScenarioIndex]
        return newPrev.toSpliced(editedScenarioIndex, 1, {
          ...findEditedTargetByIndex,
          data: findEditedTargetByIndex.data.toSpliced(editedTargetIndex, 1, {
            ...findEditedTargetByIndex.data[editedTargetIndex],
            target_value: values.value,
          }),
        })
      })
    }
  }

  const onSelectDate = (fullDate, editedScenarioIndex, editedTargetIndex) => {
    setScenarios(prev => {
      const newPrev = [...prev]
      const findEditedTargetByIndex = prev[editedScenarioIndex]
      return newPrev.toSpliced(editedScenarioIndex, 1, {
        ...findEditedTargetByIndex,
        data: findEditedTargetByIndex.data.toSpliced(editedTargetIndex, 1, {
          ...findEditedTargetByIndex.data[editedTargetIndex],
          target_year: new Date(fullDate).getFullYear(),
        }),
      })
    })
  }

  const onAddNewScenario = () => {
    setScenarios(prev => {
      const id = prev.length > 0 ? prev[prev.length - 1].id + 1 : 1
      return [
        ...prev,
        {
          id,
          target_group: '',
          data: [
            {
              target_id: 1,
              target_note: '',
              target_value: '',
              target_year: '',
            },
          ],
        },
      ]
    })
  }

  const onAddMoreRow = (prevRowScenarioIndex, prevRowTargetIndex) => {
    setScenarios(prev => {
      const findPrevRowTargetByIndex = prev[prevRowScenarioIndex]
      return prev.toSpliced(prevRowScenarioIndex, 1, {
        ...findPrevRowTargetByIndex,
        data: findPrevRowTargetByIndex.data.toSpliced(prevRowTargetIndex + 1, 0, {
          target_id: findPrevRowTargetByIndex.data.length + 1,
          target_note: '',
          target_value: '',
          target_year: '',
        }),
      })
    })
  }

  const onDeleteRow = (currentScenarioIndex, currentTargetIndex) => {
    setScenarios(prev => {
      const findRowTargetByIndex = prev[currentScenarioIndex]
      if (currentTargetIndex === 0 && findRowTargetByIndex.data.length === 1) {
        return prev.toSpliced(currentScenarioIndex, 1)
      } else {
        return prev.toSpliced(currentScenarioIndex, 1, {
          ...findRowTargetByIndex,
          data: findRowTargetByIndex.data.toSpliced(currentTargetIndex, 1),
        })
      }
    })
  }

  const onTickScenario = (scenarioId) => {
    setScenarioChecked(prev => {
      return {
        ...prev,
        [scenarioId]: !prev[scenarioId],
      }
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{t('menu.label6')}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{t('menu.label41')}</h5>
              </div>
              <div className="card-body">
                <div className="col-lg-12">
                  <div className={styles.baselineContainer}>
                    <div className={styles.baseline}>
                      <label htmlFor="baselineYear" className="form-label">
                        {t('setting.scenario.baselineYear')} <span className="text-danger">*</span>
                      </label>
                      <div style={{ display: 'flex' }}>
                        <DatePicker
                          id="baselineYear"
                          selected={selectedBaseline}
                          onChange={(date) => setSelectedBaseline(date)}
                          dateFormat="yyyy"
                          className="form-select mb-3"
                          showYearPicker
                          placeholderText="YYYY"
                        />
                      </div>
                    </div>
                    <div className={styles.addNewScenario}>
                      <button
                        onClick={onAddNewScenario}
                        type="button"
                        className="btn btn-soft-success mb-3"
                        style={{ width: '100%' }}
                      >
                        + {t('setting.scenario.addYourOwnScenario')}
                      </button>
                    </div>
                  </div>

                </div>

                <SimpleBar
                  id="scrollbar"
                  // Issue: https://github.com/Grsmto/simplebar/issues/476#issuecomment-642779891
                  classNames={{
                    scrollbar: 'simplebar-scrollbar-custom',
                  }}
                >
                  {scenarios.length > 0 && scenarios.map((scenario, scenarioIndex) => {
                    return (
                      <Fragment key={scenario.id}>
                        <div className={styles.gridContainer}>
                          <div className={styles.group}>
                            {scenarioIndex === 0 && (
                              <div className='d-flex bg-white gap-2'>
                                <div
                                  className="form-check"
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-placement='top'
                                  title={t('setting.scenario.tickInfo')}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ visibility: 'hidden' }}
                                  >
                                  </input>

                                </div>
                                <label
                                  htmlFor="scenario"
                                  className="form-label ml-2"
                                >
                                  {t('setting.scenario')} <span className="text-danger">*</span>
                                </label>
                              </div>
                            )}
                            <div className={styles.gridBody}>
                              <div className='d-flex gap-1'>
                                <div
                                  className="form-check"
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-placement='top'
                                  title={t('setting.scenario.tickInfo')}
                                  style={{ alignSelf: 'center' }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={!!scenarioChecked[scenario.id]}
                                    onChange={() => onTickScenario(scenario.id)}
                                    value={!!scenarioChecked[scenario.id]}
                                  >
                                  </input>
                                </div>
                                <TootipInputWrapper
                                  showTooltip={scenario.is_default}
                                  tooltipTitle={scenario.target_group}
                                >
                                  <input
                                    value={scenario.target_group}
                                    onChange={e => onInputScenario(e, scenarioIndex)}
                                    className="form-control"
                                    placeholder={t('setting.scenario.lable')}
                                    disabled={scenario.is_default}
                                    autoFocus={(scenarioIndex === scenarios.length - 1)}
                                  />
                                </TootipInputWrapper>

                              </div>
                            </div>
                          </div>
                          <div className={styles.note}>
                            {scenarioIndex === 0 && (
                              <div className='d-flex bg-white'>
                                <label
                                  htmlFor="remarks"
                                  className="form-label"
                                >
                                  {t('setting.scenario.remarks')}
                                </label>
                              </div>
                            )}
                            <div
                              className={styles.gridBody}
                              style={{
                                marginBottom: scenario.is_default ? '0.3rem' : '1rem',
                              }}
                            >
                              {
                                scenario.data
                                  .map((target, targetIndex) => {
                                    return (
                                      <TootipInputWrapper
                                        key={`${scenarioIndex}-${targetIndex}`}
                                        showTooltip={scenario.is_default}
                                        tooltipTitle={target.target_note}
                                      >
                                        <div className="input-group">
                                          <input
                                            value={target.target_note}
                                            onChange={e => onInputNote(e, scenarioIndex, targetIndex)}
                                            className="form-control"
                                            placeholder={t('setting.scenario.notesOrComments')}
                                            disabled={scenario.is_default}
                                          />
                                          {scenario.is_default && (
                                            <a
                                              href={target.reference_link}
                                              target="_blank"
                                              rel="noreferrer noopener"
                                              className={`input-group-text cursor-pointer link-primary ${target.reference_link ? '' : 'text-muted pe-none'}`}
                                            >
                                              <span style={{
                                                display: 'flex',
                                                gap: '0.25rem',
                                                alignItems: 'center',
                                              }}>
                                                <ImNewTab />
                                              </span>
                                            </a>
                                          )}
                                        </div>
                                      </TootipInputWrapper>
                                    )
                                  })
                              }
                            </div>
                          </div>
                          <div className={styles.year}>
                            {scenarioIndex === 0 && (
                              <div className='d-flex bg-white'>
                                <label
                                  htmlFor="year"
                                  className="form-label"
                                >
                                  {t('setting.scenario.year')} <span className="text-danger">*</span>
                                </label>
                              </div>
                            )}
                            <div className={styles.gridBody}>
                              {
                                scenario.data
                                  .map((target, targetIndex) => {
                                    const datePickerProps = {
                                      id: "date",
                                      onChange: (e) => onSelectDate(e, scenarioIndex, targetIndex),
                                      dateFormat: "yyyy",
                                      className: "form-select",
                                      showYearPicker: true,
                                      placeholderText: 'YYYY',
                                      popperPlacement: 'top',
                                      popperClassName: "trucount-popper-date-picker-class",
                                      // https://stackoverflow.com/a/75459277
                                      popperProps: {
                                        strategy: "fixed",
                                      },
                                      disabled: scenario.is_default,
                                    }
                                    if (target.target_year) {
                                      datePickerProps.selected = new Date(`${target.target_year}/01/01`)
                                    }
                                    const datePickerWrapperProps = {
                                      key: `${scenarioIndex}-${targetIndex}`,
                                    }
                                    if (scenario.is_default) {
                                      datePickerWrapperProps['data-bs-toggle'] = 'tooltip'
                                      datePickerWrapperProps['data-bs-trigger'] = 'hover'
                                      datePickerWrapperProps['data-bs-placement'] = 'top'
                                      datePickerWrapperProps['title'] = target.target_year
                                    }
                                    return (
                                      <div {...datePickerWrapperProps}>
                                        <DatePicker
                                          {...datePickerProps}
                                        />
                                      </div>
                                    )
                                  })}

                            </div>

                          </div>
                          <div className={styles.target}>
                            {scenarioIndex === 0 && (
                              <div className='d-flex bg-white'>

                                <label
                                  htmlFor="target"
                                  className="form-label"
                                >
                                  Target (%) <span className="text-danger">*</span>
                                </label>
                              </div>
                            )}
                            <div className={styles.gridBody}>
                              {
                                scenario.data
                                  .map((target, targetIndex) => {
                                    return (
                                      <TootipInputWrapper
                                        key={`${scenarioIndex}-${targetIndex}`}
                                        showTooltip={scenario.is_default}
                                        tooltipTitle={target.target_value}
                                      >
                                        <InputNumeric
                                          value={target.target_value}
                                          disabled={scenario.is_default}
                                          id={`target-${scenarioIndex}-${targetIndex}`}
                                          name={`target-${scenarioIndex}-${targetIndex}`}
                                          placeholder={t('setting.scenario.percentage')}
                                          onValueChange={(values, sourceInfo) => {
                                            onInputPercentage(values, sourceInfo, scenarioIndex, targetIndex)
                                          }}
                                        />
                                      </TootipInputWrapper>
                                    )
                                  })
                              }
                            </div>
                          </div>
                          <div className={styles.action}>
                            <div className='d-flex flex-column'>
                              {scenarioIndex === 0 && (
                                <div className='d-flex bg-white'>

                                  <label
                                    htmlFor="action"
                                    className="form-label"
                                  >
                                    {t('setting.scenario.action')}
                                  </label>
                                </div>
                              )}
                              <div className={styles.gridBody}>
                                {
                                  scenario.data
                                    .map((_target, targetIndex) => {
                                      return <div
                                        key={`${scenarioIndex}-${targetIndex}`}
                                        className='d-flex gap-2'
                                      >
                                        <button
                                          onClick={() => onDeleteRow(scenarioIndex, targetIndex)}
                                          type="button"
                                          className="btn btn-icon btn-soft-danger"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={t('setting.scenario.deleteRow')}
                                          disabled={scenario.is_default}
                                        >
                                          <i className="ri-delete-bin-5-line"></i>
                                        </button>

                                        <button
                                          onClick={() => onAddMoreRow(scenarioIndex, targetIndex)}
                                          type="button"
                                          className="btn btn-icon btn-soft-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={t('setting.scenario.addMoreRow')}
                                          disabled={scenario.is_default}
                                        >
                                          <i className=" ri-add-circle-line"></i>
                                        </button>
                                      </div>
                                    })
                                }

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='mb-3'></div>
                      </Fragment>
                    )
                  })}
                </SimpleBar>

                <div style={{ display: 'flex', gap: '0.188rem' }}>
                  <i className="ri-lightbulb-line" style={{ color: 'orange' }}></i>
                  <p className="text-muted">
                    <span className="mr-1">{t('setting.scenario.tickInfo')}</span>
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onUpdateScenario}
                    >
                      {t('general.btnUpdate')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </div >
  )
}

export default SettingScenario