import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import Swal from 'sweetalert2';

import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";

export class UserCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectCompany: false,
            inputName: "",
            inputEmail: "",
            inputPassword: "",
            inputConfirm: "",
        }
    }

    componentDidMount = async () => {
        showPreloader();

        hidePreloader();
    }

    onInputCompany = async (e) => {
        let companyName = e.target.value;
        this.setState({
            inputCompany: companyName
        })

        //console.log(this.state.inputCompany)
    }

    onInputName = async (e) => {
        let userName = e.target.value;
        this.setState({
            inputName: userName
        })
    }

    onInputEmail = async (e) => {
        let userEmail = e.target.value;
        this.setState({
            inputEmail: userEmail
        })
    }

    onInputPassword = async (e) => {
        let userPassword = e.target.value;
        this.setState({
            inputPassword: userPassword
        })
    }

    onInputConfirmPassword = async (e) => {
        let userConfirmPassword = e.target.value;
        this.setState({
            inputConfirm: userConfirmPassword
        })
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/user/create`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        }else{
            return false;
        }
    }

    onSaveRecord = async (e) => {
        let invalidData = true;
        let invalidPass = true;

        // eslint-disable-next-line
        if(this.state.inputName != "" && this.state.inputEmail != ""){
            // eslint-disable-next-line
            if(this.state.inputPassword == this.state.inputConfirm){
                const data = {
                    'name': this.state.inputName,
                    'role': 1,
                    'email': this.state.inputEmail,
                    'password': this.state.inputPassword,
                };

                const result = this.onSaveRecordCall(data);
                if(result){
                    invalidData = false;
                }
            }else{
                invalidData = true;
                invalidPass = true;
            }
        }else{
            invalidData = true;
        }

        if(invalidData){
            if(invalidPass){
                Swal.fire({
                    title: '',
                    html: '<p>Confirm Password not match</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }else{
                Swal.fire({
                    title: '',
                    html: '<p>Please fill all information needs</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }else{
            Swal.fire({
                title: '',
                html: '<p>Successfully record user</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        redirectCompany: true
                    })
                }
            })
        }
    }

    render() {
        const { redirectCompany } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor, listEmissionFactorUnit, listEmissionRecord } = this.state;
        const { countRecords } = this.state;


        if(redirectCompany){
            return <Navigate to={'/user'} />
        }

        let optionCompany = [];
        optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
        // eslint-disable-next-line
        if(listCompany != undefined) {
            if(listCompany.length > 0) {
                listCompany.map(async (item, index) => {
                    optionCompany.push(<option key={index+1} value={item.boundary_id}>{item.boundary_name}</option>)
                })
            }
        }

        let optionOffice = [];
        optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>Select Location</option>)
        // eslint-disable-next-line
        if(listOffice != undefined) {
            if(listOffice.length > 0) {
                listOffice.map(async (item, index) => {
                    optionOffice.push(<option key={index+1} value={item.office_id}>{item.office_name}</option>)
                })
            }
        }

        let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>Select Source</option>)
        // eslint-disable-next-line
        if(listCategory != undefined) {
            if(listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    optionCategory.push(<option key={index+1} value={item.category_id}>{item.category_name}</option>)
                    // eslint-disable-next-line
                    /*if(item.category_id == selectedCategory){
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }else{
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }*/
                })
            }
        }

        let optionEmissionFactor = [];
        // eslint-disable-next-line
        if(selectedCategory != 0){
            // eslint-disable-next-line
            if(listEmissionFactor != undefined) {
                if(listEmissionFactor.length > 0) {
                    listEmissionFactor.map(async (item, index) => {
                        // eslint-disable-next-line
                        if(item.fc_category == selectedCategory){
                            optionEmissionFactor.push(
                                <option key={index+1} value={item.emission_factor_id}>{item.emission_name}</option>
                            )
                        }
                    })
                }
            }
        }

        let emissionRows = [];
        for (let i = 0; i < countRecords; i++) {
            let optionEmissionFactorUnit = [];
            // eslint-disable-next-line
            if(listEmissionRecord[i].factor != 0){
                // eslint-disable-next-line
                if(listEmissionFactorUnit != undefined) {
                    if(listEmissionFactorUnit.length > 0) {
                        listEmissionFactorUnit.map(async (item, index) => {
                            // eslint-disable-next-line
                            if(item.unit_factor == listEmissionRecord[i].factor){
                                optionEmissionFactorUnit.push(
                                    <option key={index+1} value={item.unit_id}>{item.unit_label}</option>
                                )
                            }
                        })
                    }
                }
            }

            if(optionEmissionFactor.length > 0) {
                emissionRows.push(
                    <tr key={i}>
                        <td>
                            <input
                                onChange={e => this.onInputAsset(e, i)}
                                value={listEmissionRecord[i].assets}
                                className="form-control"
                                placeholder="assets"
                            ></input>
                        </td>
                        <td>
                            <select onChange={e => this.onSelectEmission(e, i)} value={listEmissionRecord[i].factor} className="form-select mb-0">
                                {/* eslint-disable-next-line */}
                                {listEmissionRecord[i].factor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "Grid Name" : "Fuel Type"}</option>}
                                {optionEmissionFactor}
                            </select>
                        </td>
                        <td>
                            <select onChange={e => this.onSelectUnit(e, i)} value={listEmissionRecord[i].unit} className="form-select mb-0">
                                {listEmissionRecord[i].unit > 0 ? "" : <option value="0">Select Unit</option>}
                                {optionEmissionFactorUnit}
                            </select>
                        </td>
                        <td>
                            <input
                                type="number"
                                onChange={e => this.onInputAmount(e, i)}
                                value={listEmissionRecord[i].amount}
                                className="form-control"
                                placeholder="amount"
                            ></input>
                        </td>
                        <td>
                            <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                        </td>
                    </tr>
                )
            }else{
                emissionRows.push(
                    <tr key={i}>
                        <td>
                            <input
                                onChange={e => this.onInputAsset(e, i)}
                                value={listEmissionRecord[i].assets}
                                className="form-control"
                                placeholder="assets"
                            ></input>
                        </td>
                        <td>
                            <input
                                type="number"
                                onChange={e => this.onInputAmount(e, i)}
                                value={listEmissionRecord[i].amount}
                                className="form-control"
                                placeholder="amount"
                            ></input>
                        </td>
                        <td>
                            <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                        </td>
                    </tr>
                )
            }
        }

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t('menu.label624')}</h4>
                            </div>
                        </div>
                    </div>

                    
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{this.props.t('general.btnCreate')} User</h4>
                                </div>
                                
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">{this.props.t('removal.table.label1')}</label>
                                                <input id="name" name="name" type="text" className="form-control" placeholder={this.props.t('removal.table.label1')} value={this.state.inputName} onChange={(e) => this.onInputName(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label">{this.props.t('user.label1')}</label>
                                                <input id="email" name="email" type="text" className="form-control" placeholder={this.props.t('user.label1')} value={this.state.inputEmail} onChange={(e) => this.onInputEmail(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input id="password" name="password" type="password" className="form-control" placeholder="Password" value={this.state.inputPassword} onChange={(e) => this.onInputPassword(e)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="confirm" className="form-label">{this.props.t('user.label2')}</label>
                                                <input id="confirm" name="confirm" type="password" className="form-control" placeholder={this.props.t('user.label2')} value={this.state.inputConfirm} onChange={(e) => this.onInputConfirmPassword(e)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="text-end">
                                                <button onClick={this.onSaveRecord} type="submit" className="btn btn-primary">{this.props.t('general.btnSave')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
  count: state.counter.value,
  loggedIn: state.loggedIn.value,
  account: state.account.value,
  company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserCreate));