import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import DataTable from 'react-data-table-component';

import { connect } from "react-redux"
import { increment, decrement } from "../../features/counter/counterSlice"
import { setLoggedIn } from "../../features/account/loginSlice"
import { setAccount } from "../../features/account/accountSlice"
import { setCompany } from "../../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import Swal from 'sweetalert2'

import { hidePreloader, showPreloader } from '../../library/helper';
import { withTranslation } from 'react-i18next';

export class LocationSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumn: [],
            tableData: [],
            tableFilter: "",
            tableResetPagination: false,

            listYear: [],
            selectedYear: 0,
        }
    }

    componentDidMount = async () => {
        showPreloader();
        const data = {}
        let listYear = [];

        const optionsGetYearRecorded = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/year_recorded`
        };
        const responseGetYearRecorded = await axios(optionsGetYearRecorded);
        if (responseGetYearRecorded.data.success) {
            listYear = responseGetYearRecorded.data.data;
        }

        this.setState({
            listYear: listYear,
        })

        /*const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            console.log(responseGetOffice.data.data)
            const columns = [
                {
                    name: this.props.t('menu.label623'),
                    selector: row => row.office_name,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: this.props.t('menu.label622'),
                    selector: row => row.company_name,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: this.props.t('location.label1'),
                    selector: row => row.office_address,
                    sortable: true,
                    filterable: true,
                },
            ];
            const record = responseGetOffice.data.data;
            this.setState({
                tableColumn: columns,
                tableData: record,
                tableFiltered: record,
            })
        }*/

        hidePreloader();
    }

    onFilter = async (e) => {
        // eslint-disable-next-line
        if (e.target.value != "") {
            let filterValue = e.target.value;
            let baseData = this.state.tableData;
            console.log(baseData);
            let filteredData = baseData.filter(
                item =>
                    item.office_name.toLowerCase().includes(this.state.tableFilter.toLowerCase()) ||
                    item.company_name.toLowerCase().includes(this.state.tableFilter.toLowerCase())
            )
            this.setState({
                tableFilter: filterValue,
                tableFiltered: filteredData,
            })
        } else {
            this.setState({
                tableFilter: "",
                tableFiltered: this.state.tableData,
            })
        }
        console.log(this.state.tableFilter)
        console.log(this.state.tableFiltered)
    }

    onClear = async () => {
        // eslint-disable-next-line
        if (this.state.tableFilter != "") {
            this.setState({
                tableFilter: "",
            })
        }
    }

    onDeleteCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office/delete`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onDelete = async (id) => {
        let invalidData = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    'id': id,
                };
                const result = this.onDeleteCall(data);
                if (result) {
                    invalidData = false;
                }

                if (invalidData) {
                    Swal.fire({
                        title: '',
                        html: '<p>Cannot delete data, please try again later</p>',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    Swal.fire({
                        title: 'Deleted',
                        text: "Your data has been deleted.",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            }
        })
    }

    onSelectYear = async (e) => {
        let yearSelected = e.target.value;

        this.setState({
            selectedYear: yearSelected,
        }, () => {
            this.onFilterApply();
        })
    }

    onFilterApply = async () => {
        console.log(this.state.selectedYear)

        const data = {
            'year': this.state.selectedYear
        };

        if (this.state.selectedYear > 0) {
            const optionsGetOffice = {
                withCredentials: true,
                method: 'GET',
                params: {
                    ...data,
                    gwp: new URLSearchParams(window.location.search).get('gwp'),
                },
                url: `${process.env.REACT_APP_BASE_SERVER}/analytics-location-summary-emission`
            };
            const responseGetOffice = await axios(optionsGetOffice);
            if (responseGetOffice.data.ok) {
                console.log(responseGetOffice.data.data)
                const amountSort = (rowA, rowB) => {
                    const a = rowA.office_total;
                    const b = rowB.office_total;

                    if (a > b) {
                        return 1;
                    }

                    if (b > a) {
                        return -1;
                    }

                    return 0;
                };

                const columns = [
                    {
                        name: this.props.t('menu.label622'),
                        selector: row => row.company_name,
                        sortable: true,
                        filterable: true,
                    },
                    {
                        name: this.props.t('menu.label623'),
                        selector: row => row.office_name,
                        sortable: true,
                        filterable: true,
                    },
                    {
                        name: this.props.t('emission.table.label5'),
                        selector: row => parseFloat(row.office_total).toFixed(2) + " tCO\u2082e",
                        sortable: true,
                        filterable: true,
                        sortFunction: amountSort,
                    },
                ];
                const record = responseGetOffice.data.data;
                this.setState({
                    tableColumn: columns,
                    tableData: record,
                    tableFiltered: record,
                })
            }
        }
    }

    render() {
        const { t } = this.props;

        const { tableColumn, tableFilter, tableFiltered } = this.state;
        const paginationComponentOptions = {
            rowsPerPageText: this.props.t('table.paginationResult'),
            rangeSeparatorText: this.props.t('table.paginationResultPage'),
        };

        let optionYear = [];
        optionYear.push(this.state.selectedYear > 0 ? null : <option key={-1} value={0}>{t('analytics.location.filter.year')}</option>)
        this.state.listYear.map(async (item, index) => {
            optionYear.push(
                <option key={index} value={item.year}>{item.year}</option>
            )
        })

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className='align-items-center d-flex justify-content-between mb-4'>
                                <h4 className='mb-sm-0' style={{ color: '#09564B' }}>
                                    {this.props.t('menu.label46')}
                                </h4>
                                <div className="d-sm-flex align-items-center justify-content-between gap-3">
                                    <select
                                        style={{ width: "auto" }}
                                        className="form-select"
                                        id="selectedYearPicker"
                                        value={this.state.selectedYear}
                                        onChange={e => this.onSelectYear(e)}
                                    >
                                        {optionYear}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div
                                        className="col-lg-4"
                                        style={{ float: "right" }}
                                    >
                                        <div className="input-group">
                                            <span
                                                className="input-group-text"
                                                id="search"
                                            >
                                                <i className="ri-search-line"></i>
                                            </span>
                                            <input
                                                value={tableFilter}
                                                onChange={e => this.onFilter(e)}
                                                type="text"
                                                className="form-control"
                                                placeholder={this.props.t('table.search')}
                                                aria-describedby="search"
                                            />
                                        </div >
                                    </div >
                                </div >
                                <div className="card-body">
                                    <DataTable
                                        columns={tableColumn}
                                        data={tableFiltered}
                                        noDataComponent={this.props.t('table.empty')}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={[10, 25, 50]}
                                    />
                                </div>
                            </div >
                        </div >
                    </div >

                </div >
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LocationSummary));