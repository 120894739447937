import React, {
  useState,
  useEffect,
} from 'react'
import {
  Navigate,
  useParams,
  useLocation,
} from 'react-router-dom'
import axios from "axios"
import DatePicker from "react-datepicker"
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_PATHS,
} from '../../../library/helper'

import Attachment from '../../../components/Attachment'
import NotFound from '../../../components/NotFound'
import LoadingCard from '../../../components/LoadingCard'

const upperCaseFirstLetter = (str) => {
  if (!str) return ''
  return str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

const AttachmentChildren = ({
  isLoading,
  attachmentData,
  setSelectedFile,
  onAttachFile,
  selectedFile,
  onChangeFile,
}) => {
  const { t } = useTranslation()
  const [attachment, setAttachment] = useState({})

  useEffect(() => {
    if (attachmentData.type) {
      setAttachment(attachmentData)
    } else {
      setAttachment({})
    }
  }, [attachmentData])

  useEffect(() => {
    if (attachment.blob) {
      const file = new File(
        [attachment.blob],
        attachment.name,
        { type: attachment.type },
      )
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      document.getElementById('formFile').files = dataTransfer.files
      setSelectedFile(file)
    }
  }, [attachment])

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '7rem auto',
        }}
      >
        <div
          style={{
            padding: '5px 0',
          }}
        >
          <button
            onClick={onAttachFile}
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{
              width: '100%',
            }}
            disabled={isLoading}
          >
            {t('emission.form.label17')}
          </button>
        </div>
        <div
          style={{
            padding: '5px 8px 5px',
            alignSelf: 'center',
          }}
        >
          {isLoading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="text-muted">
              {selectedFile?.name}
            </span>
          )
          }
        </div>
        <div
          style={{
            padding: '5px 0',
          }}
        >
          {attachment?.url ? (
            <a
              className={`btn btn-info ${isLoading ? 'btn-soft-dark pe-none' : ''}`}
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              href={attachment.url}
              target="_blank"
              rel="noreferrer"
              download={attachment.name}
            >
              {t('dashboard.btnDownload')}
            </a>
          ) : (
            <p>{t('emission.noAttachment')}</p>
          )}
        </div>
      </div>
      <input
        onChange={onChangeFile}
        style={{ display: "none" }}
        className="form-control"
        type="file"
        id="formFile"
        accept="image/*,.pdf,.doc"
      />

    </>
  )
}

export default function EmissionScope3Category4And9Update() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const location = useLocation()

  const [redirectEmission, setRedirectEmission] = useState(false)

  const [companyList, setCompanyList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [transportationModeList, setTransportationModeList] = useState([])
  const [transportationRouteList, setTransportationRouteList] = useState([])
  const [transportationModeListByRoute, setTransportationModeListByRoute] = useState([])

  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedCompanyId, setSelectedCompanyId] = useState(0)
  const [selectedLocationId, setSelectedLocationId] = useState(0)
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)
  const [selectedTransportationModeId, setSelectedTransportationModeId] = useState('')
  const [selectedTransportationRoute, setSelectedTransportationRoute] = useState('')

  const [product, setProduct] = useState('')
  const [supplierOrCustomer, setSupplierOrCustomer] = useState('')
  const [mass, setMass] = useState(0)
  const [distance, setDistance] = useState(0)

  const [selectedRecordDate, setSelectedRecordDate] = useState('')

  const [attachmentId, setAttachmentId] = useState('')

  const [isNotFound, setIsNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getIntitialData = async () => {

      try {
        setIsLoading(true)
        let scope3CategoryId
        let supplierOrCustomerKey

        const upstreamPageUrl = ROUTE_PATHS.scope3category4Update?.split(':')?.[0]
        const downstreamPageUrl = ROUTE_PATHS.scope3category9Update?.split(':')?.[0]

        if (location.pathname.startsWith(upstreamPageUrl)) {
          scope3CategoryId = 4
          supplierOrCustomerKey = 'supplier_name'
        } else if (location.pathname.startsWith(downstreamPageUrl)) {
          scope3CategoryId = 9
          supplierOrCustomerKey = 'customer_name'
        }

        const responseInitialData = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            uuid: id,
            scope3CategoryId,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/transportation-distribution-emission-record-lookup`
        })
        if (responseInitialData.data.ok) {
          const {
            record,
            company_list,
            location_list,
            emission_category,
            transportation_mode_list,
            routes,
            factors,
          } = responseInitialData.data.data

          setSelectedTransportationModeId(record.record_factor)
          setTransportationRouteList(routes)
          setTransportationModeList(transportation_mode_list)

          setProduct(record.record_assets)
          setSupplierOrCustomer(record.record_misc[supplierOrCustomerKey])
          setMass(record.record_amount)
          setDistance(record.record_amount_2)

          const datePickerRecord = new Date(
            new Date(record.record_date).getFullYear(),
            new Date(record.record_date).getMonth(),
            new Date(record.record_date).getDate(),
          )
          setSelectedRecordDate(datePickerRecord || null)

          setCompanyList(company_list)
          setLocationList(location_list)
          setCategoryList([emission_category])
          setTransportationModeListByRoute(factors)

          setSelectedCompanyId(record.company_id)
          setSelectedLocationId(record.office_id)
          setSelectedCategoryId(emission_category.category_id)
          setSelectedTransportationRoute(record.tag)

          setAttachmentId(record.attachment_id)
        }
      } catch (error) {
        if (error?.response?.data?.status === 404) {
          setIsNotFound(true)
        } else {
          let message
          if (typeof error.response?.data?.message === 'string') {
            message = error.response.data.message
          } else if (error.message) {
            message = error.message
          }
          Swal.fire({
            icon: 'error',
            title: '',
            html: `<p style="font-size:0.75rem">${message}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    getIntitialData()
  }, [location.pathname])

  const onSaveRecord = async (e) => {
    try {
      let data = new FormData()
      data.append('uuid', id)
      data.append('companyId', selectedCompanyId)
      data.append('locationId', selectedLocationId)
      data.append('categoryId', selectedCategoryId)
      data.append('product', product)
      data.append('supplierOrCustomer', supplierOrCustomer)
      data.append('transportationModeRoute', selectedTransportationRoute)
      data.append('transportationModeId', selectedTransportationModeId)
      data.append('mass', mass)
      data.append('distance', distance)
      data.append('recordDate', selectedRecordDate)

      if (selectedFile) data.append('attachment', selectedFile)

      const upstreamPageUrl = ROUTE_PATHS.scope3category4Update?.split(':')?.[0]

      if (location.pathname.startsWith(upstreamPageUrl)) {
        data.append('scope3CategoryId', 4)
      } else {
        const downstreamPageUrl = ROUTE_PATHS.scope3category9Update?.split(':')?.[0]
        if (location.pathname.startsWith(downstreamPageUrl)) {
          data.append('scope3CategoryId', 9)
        }
      }

      const response = await axios({
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: data,
        url: `${process.env.REACT_APP_BASE_SERVER}/transportation-distribution-emission-record-submission`
      })

      if (response.data.ok) {
        Swal.fire({
          title: '',
          html: '<p>Successfully record emissions</p>',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            setRedirectEmission(true)
          }
        })
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onAttachFile = async () => {
    document.getElementById('formFile').click()
  }

  const onChangeFile = async (e) => {
    const files = e.target.files
    setSelectedFile(files?.[0])
  }

  const onSelect = async (e) => {
    try {
      const selectedValue = Number(e.target.value)
      if (e.target.name === 'companyId') {
        setSelectedCompanyId(selectedValue)
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            companyId: selectedValue,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/client-office-lookup`
        })
        if (response.data.ok) {
          setLocationList(response.data.data)
          setSelectedLocationId(0)
        }
      } else if (e.target.name === 'locationId') {
        setSelectedLocationId(selectedValue)
      } else if (e.target.name === 'categoryId') {
        setSelectedCategoryId(selectedValue)
        setSelectedTransportationModeId('')
      } else if (e.target.name === 'transportationRoute') {
        setSelectedTransportationRoute(e.target.value)
        setTransportationModeList(transportationModeListByRoute.find(factor => {
          return factor[0].tag === e.target.value
        }))
        setSelectedTransportationModeId(0)
      } else if (e.target.name === 'transportationModeId') {
        setSelectedTransportationModeId(e.target.value)
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onInputChange = (e) => {
    if (e.target.name === 'product') {
      setProduct(e.target.value)
    } else if (e.target.name === 'supplierOrCustomer') {
      setSupplierOrCustomer(e.target.value)
    }
  }

  const onInputNumericFromatChange = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      if (sourceInfo.event.target.name === 'mass') {
        setMass(values.value)
      } else if (sourceInfo.event.target.name === 'distance') {
        setDistance(values.value)
      }
    }
  }

  let pageTitle
  let customerOrSupplierLabel
  let massLabel
  let distanceLabel
  let navigateTo
  const upstreamPageUrl = ROUTE_PATHS.scope3category4Update?.split(':')?.[0]
  const downstreamPageUrl = ROUTE_PATHS.scope3category9Update?.split(':')?.[0]
  if (location.pathname.startsWith(upstreamPageUrl)) {
    pageTitle = t("menu.scope3Category4Title")
    customerOrSupplierLabel = t('emission.form.supplierName')
    massLabel = t('emission.form.massUpstream')
    distanceLabel = t('emission.form.distanceTravelledUpstream')
    navigateTo = ROUTE_PATHS.scope3category4
  } else if (location.pathname.startsWith(downstreamPageUrl)) {
    pageTitle = t("menu.scope3Category9Title")
    customerOrSupplierLabel = t('emission.form.customer')
    massLabel = t('emission.form.massDownstream')
    distanceLabel = t('emission.form.distanceTravelledDownstream')
    navigateTo = ROUTE_PATHS.scope3category9
  }

  if (redirectEmission) {
    return <Navigate to={navigateTo} />
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {pageTitle}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">

            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t('general.btnUpdate')}
                </h4>
              </div>
              {isLoading ? (
                <div className="card-body">
                  <LoadingCard />
                </div>
              ) : (
                <>
                  {isNotFound ? (
                    <div className="card-body">
                      <NotFound />
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="companyId"
                              className="form-label"
                            >
                              {t('emission.form.label1')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="companyId"
                              name="companyId"
                              onChange={onSelect}
                              value={selectedCompanyId}
                              className="form-select mb-3"
                            >
                              {selectedCompanyId === 0 && (
                                <option
                                  key={0}
                                  value={0}
                                >
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {companyList.map(item => {
                                return (
                                  <option
                                    key={item.company_id}
                                    value={item.company_id}
                                  >
                                    {item.company_name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        {selectedCompanyId > 0 && (
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="locationId"
                                className="form-label"
                              >
                                {t('emission.table.label2')} <span className="text-danger">*</span>
                              </label>
                              <select
                                id="locationId"
                                name="locationId"
                                onChange={onSelect}
                                value={selectedLocationId}
                                className="form-select mb-3"
                              >
                                {selectedLocationId === 0 && (
                                  <option
                                    key={0}
                                    value={0}
                                  >
                                    {t('emission.form.select')}
                                  </option>
                                )}
                                {locationList.map(item => {
                                  return (
                                    <option
                                      key={item.office_id}
                                      value={item.office_id}
                                    >
                                      {item.office_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="categoryId"
                              className="form-label"
                            >
                              {t('emission.form.label2')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="categoryId"
                              name="categoryId"
                              onChange={onSelect}
                              value={selectedCategoryId}
                              className="form-select mb-1"
                            >
                              {selectedCategoryId === 0 && (
                                <option
                                  key={0}
                                  value={0}
                                >
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {categoryList.map(item => {
                                return (
                                  <option
                                    key={item.category_id}
                                    value={item.category_id}
                                  >
                                    {
                                      i18n.language === 'id'
                                        ? item.category_name_id
                                        : item.category_name
                                    }
                                  </option>
                                )
                              })}
                            </select>
                            <p>
                              &nbsp;{t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub">EPA 2023</a>
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="date" className="form-label">
                              {t('emission.form.date')} <span className="text-danger">*</span>
                            </label>
                            <div>
                              <DatePicker
                                id="date"
                                name="date"
                                selected={selectedRecordDate}
                                onChange={(date) => setSelectedRecordDate(date)}
                                dateFormat="dd MMMM yyyy"
                                className="form-select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="transportationRoute" className="form-label">
                              {t('emission.form.transportationRoute')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="transportationRoute"
                              name="transportationRoute"
                              onChange={onSelect}
                              value={selectedTransportationRoute}
                              className="form-select mb-0"
                            >
                              {(
                                selectedCategoryId === 0 ||
                                !selectedTransportationRoute
                              ) && (
                                  <option value={0} key={0}>
                                    {t('emission.form.select')}
                                  </option>
                                )}
                              {
                                transportationRouteList.length > 0 &&
                                transportationRouteList.map(item => {
                                  return (
                                    <option
                                      key={item}
                                      value={item}
                                    >
                                      {upperCaseFirstLetter(item)}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="transportationModeId" className="form-label">
                              {t('emission.form.transportationMode')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="transportationModeId"
                              name="transportationModeId"
                              onChange={onSelect}
                              value={selectedTransportationModeId}
                              className="form-select mb-0"
                            >
                              {(
                                selectedCategoryId === 0 ||
                                !selectedTransportationModeId
                              ) && (
                                  <option value={0} key={0}>
                                    {t('emission.form.select')}
                                  </option>
                                )}
                              {
                                transportationModeList.length > 0 &&
                                transportationModeList.map(item => {
                                  return (
                                    <option
                                      key={item.emission_factor_id}
                                      value={item.emission_factor_id}
                                    >
                                      {item.emission_name}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="product" className="form-label">
                              {t('emission.form.product')} <span className="text-danger">*</span>
                            </label>
                            <input
                              id="product"
                              name="product"
                              onChange={onInputChange}
                              value={product}
                              className="form-control"
                              placeholder={t('emission.form.enter')}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="supplierOrCustomer" className="form-label">
                              {customerOrSupplierLabel} <span className="text-danger">*</span>
                            </label>
                            <input
                              id="supplierOrCustomer"
                              name="supplierOrCustomer"
                              onChange={onInputChange}
                              value={supplierOrCustomer}
                              className="form-control"
                              placeholder={t('emission.form.enter')}
                            >
                            </input>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="mass" className="form-label">
                              {massLabel} <span className="text-danger">*</span>
                            </label>
                            <NumericFormat
                              id="mass"
                              name="mass"
                              className="form-control"
                              value={mass}
                              onValueChange={onInputNumericFromatChange}
                              decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                              thousandSeparator={i18n.language === 'id' ? '.' : ','}
                              placeholder={t('emission.form.enter')}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="distance" className="form-label">
                              {distanceLabel} <span className="text-danger">*</span>
                            </label>
                            <NumericFormat
                              id="distance"
                              name="distance"
                              className="form-control"
                              value={distance}
                              onValueChange={onInputNumericFromatChange}
                              decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                              thousandSeparator={i18n.language === 'id' ? '.' : ','}
                              placeholder={t('emission.form.enter')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="formFile" className="form-label">
                            {t('emission.form.label15')}
                          </label>
                          <p className="text-muted">
                            {t('emission.form.label16')}
                          </p>
                          <div className="col-md-6">
                            <Attachment id={attachmentId}>
                              {({ attachment, isLoading }) => {
                                return <AttachmentChildren
                                  setSelectedFile={setSelectedFile}
                                  onAttachFile={onAttachFile}
                                  selectedFile={selectedFile}
                                  onChangeFile={onChangeFile}
                                  attachmentData={attachment}
                                  isLoading={isLoading}
                                />
                              }}
                            </Attachment>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="text-end">
                          <button
                            onClick={onSaveRecord}
                            type="submit"
                            className="btn btn-primary"
                          >
                            {t('general.btnSave')}
                          </button>
                        </div>
                      </div>
                    </div >
                  )}
                </>
              )}
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}
