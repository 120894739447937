import styles from './CardHeader.module.css'

function CardHeader({
  children,
  hideBorderBottom = false,
}) {
  const className = hideBorderBottom ? (
    `${styles.cardHeader}`
  ) : (`${styles.cardHeader} ${styles.borderBottom}`)

  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default CardHeader
