import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride'

import {
  setTourStepIndex,
  tourSkip,
} from '../features/tour/tourSlice'

import { ROUTE_PATHS } from '../library/helper'

function TourManager() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const run = useSelector((state) => state.tour.run)
  const stepIndex = useSelector((state) => state.tour.stepIndex)
  const [steps, setSteps] = useState()

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.divisionCreate) {
      setSteps([
        {
          spotlightClicks: true,
          disableBeacon: true,
          target: '#tour__business_division',
          title: t('dashboard.starter.tour.divison.inputName'),
        },
        {
          spotlightClicks: true,
          target: '#tour__business_division_save',
          title: t('dashboard.starter.tour.division.save'),
        },
      ])
    } else if (location.pathname === ROUTE_PATHS.companyCreate) {
      setSteps([
        {
          spotlightClicks: true,
          disableBeacon: true,
          target: '#tour__company_name',
          title: t('dashboard.starter.tour.company.inputName'),
        },
        {
          spotlightClicks: true,
          target: '#tour__company_division',
          title: t('dashboard.starter.tour.company.division'),
        },
        {
          spotlightClicks: true,
          target: '#tour__company_shareholding',
          title: t('dashboard.starter.tour.company.shareholding'),
        },
        {
          spotlightClicks: true,
          target: '#tour__company_location',
          title: t('dashboard.starter.tour.company.location'),
        },
        {
          spotlightClicks: true,
          target: '#tour__company_address',
          title: t('dashboard.starter.tour.company.address'),
        },
        {
          spotlightClicks: true,
          target: '#tour__company_save',
          title: t('dashboard.starter.tour.company.save'),
        },
      ])
    }
  }, [t, location])

  const handleCallback = (data) => {
    const { action, index, type, status } = data

    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      dispatch(tourSkip())
    } else if (type === EVENTS.STEP_AFTER) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      dispatch(setTourStepIndex(nextStepIndex))
    }
  }

  return (
    <Joyride
      callback={handleCallback}
      continuous
      run={run}
      scrollToFirstStep
      showSkipButton={true}
      showProgress
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        options: {
          primaryColor: '#000',
          width: 500,
          zIndex: 10000,
        }
      }}
      locale={{
        back: t('emission.tour.back'),
        close: t('emission.tour.close'),
        last: t('emission.tour.last'),
        next: t('emission.tour.next'),
        open: t('emission.tour.open'),
        skip: t('emission.tour.skip'),
      }}
    />
  )
}

export default TourManager
