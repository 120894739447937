import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'

import Download from '../Download'
import BarChartLegend from '../ApexCharts/BarChartLegend'

const emptySeries = [
  {
    "name": "Series-1",
    "nameId": "Series-1",
    "data": [
      18,
      13,
      15,
      6.57,
      0.87,
      3.2,
      1,
      7,
      6.5,
      9,
      12,
      6,
    ]
  },
]

function MonthlyEmission(props) {
  const {
    customLabelName,
    chartHeight,
    chartType,
    apiRoute,
    chartId,
    filterLabel,
    onDownload,
    options: chartOptions,
    emptyOptions: emptyChartOptions,
    selectedCompanyId,
    selectedYear,
    title,
  } = props
  const { i18n } = useTranslation()

  const lookupByScopeRoute = '/record-by-scope-monthly-summaries-lookup'
  const lookupByCompanyRoute = '/record-by-company-monthly-summaries-lookup'

  const [keys, setKeys] = useState()
  const [data, setData] = useState([])
  const [rawData, setRawData] = useState([])
  const [hiddenSeries, setHiddenSeries] = useState([])
  const [axisBottom, setAxisBottom] = useState()

  const series = rawData.length > 0
    ? keys.map(key => {
      return {
        name: key.categoryName,
        nameId: key.categoryNameId || key.categoryName,
        data: axisBottom.map(month => {
          const findData = rawData.find(d => d.axisBottom === month && d.categoryId === key.categoryId)
          if (findData) {
            return parseFloat(findData.total.toFixed(2))
          }
          return 0
        })
      }
    })
    : []

  const options = {
    ...chartOptions,
  }
  options.tooltip.y.title = {
    formatter: function (seriesName) {
      if (series.length && i18n.language === 'id') {
        const seriesByName = series.find(s => s.name === seriesName)
        if (seriesByName) {
          return `${seriesByName.nameId}:`
        } else {
          return `${seriesName}:`
        }
      } else {
        return `${seriesName}:`
      }
    },
  }

  useEffect(() => {
    const controller = new AbortController()

    const getData = async () => {
      try {
        const year = Number(selectedYear)
        const companyId = Number(selectedCompanyId)
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            year,
            companyId,
            gwp: new URLSearchParams(window.location.search).get('gwp'),
          },
          url: `${process.env.REACT_APP_BASE_SERVER}${apiRoute}`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setData(response.data.data.dataChart)
          setRawData(response.data.data.rawData)
          setKeys(response.data.data.categories)
          setAxisBottom(response.data.data.monthList.map(({ axisBottom }) => axisBottom))
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (
      selectedYear &&
      (selectedCompanyId || (apiRoute === lookupByCompanyRoute)) &&
      apiRoute
    ) {
      getData()
    }

    return () => controller.abort()
  }, [selectedYear, selectedCompanyId, apiRoute, lookupByCompanyRoute])

  const FilterSeries = (seriesName = "") => {
    if (series.every(item => item?.name === undefined)) return;

    ApexCharts.getChartByID(options?.chart?.id).toggleSeries(seriesName);

    let currentData = [...hiddenSeries];
    const checkIndex = currentData.findIndex(item => item === seriesName);

    if (checkIndex < 0) currentData.push(seriesName);
    else currentData.splice(checkIndex, 1);

    setHiddenSeries(currentData);
  }

  return (
    <div
      className='bg-white d-flex flex-column gap-3 position-relative'
      style={{
        borderRadius: '8px',
      }}
      id={chartId}
    >
      <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
        <p
          className={`fw-semibold m-0 ${series.length === 0 ? 'text-muted' : ''}`}
          style={{ fontSize: 12 }}
        >
          {title}
        </p>
        <Download
          onClick={onDownload}
          isDisabled={series.length === 0}
        />
      </div>
      <div className='px-3'>
        <ReactApexChart
          options={series.length > 0 ? options : emptyChartOptions}
          series={series.length > 0 ? series : emptySeries}
          type={chartType}
          height={chartHeight}
        />
      </div>
      {
        series.length > 0 && (
          <BarChartLegend
            series={series}
            filterSeries={FilterSeries}
            hiddenSeries={hiddenSeries}
          />
        )
      }
      {
        series.length === 0 && (
          <div style={{
            position: 'absolute',
            borderStyle: 'solid',
            borderWidth: '1px',
            padding: '0.5rem',
            borderRadius: 14,
            fontSize: '11px',
            fontWeight: 'bold',
            backgroundColor: 'white',
            alignSelf: 'center',
            top: 'calc(50% - 21px)',
          }}>
            No Data
          </div>
        )
      }
    </div>
  )
}

export default MonthlyEmission
