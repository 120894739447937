import {
    useEffect,
    useState,
} from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { jsPDF } from 'jspdf'
import Swal from 'sweetalert2'
import html2canvas from 'html2canvas'

import { useTranslation } from 'react-i18next'

import Download from '../components/Download'

const titleStyles = {
    fontWeight: 'bold',
}

const thTitleStyles = {
    ...titleStyles,
    fontSize: '12px',
}

const subtitleStyle = {
    fontSize: '11px',
}

const thSubtitleTextStyles = {
    ...subtitleStyle,
    backgroundColor: '#438375',
}

const thSubtitleStyles = {
    ...subtitleStyle,
    ...thSubtitleTextStyles,
    ...titleStyles,
    backgroundColor: '#09564b',
}

const tdGasStyles = {
    width: '93px',
    minWidth: '93px',
}

const LABEL = {
    scopeOneRows: 'Scope 1',
    scopeTwoRows: 'Scope 2',
    scopeThreeRows: 'Scope 3',
    biogenicRows: 'Biogenic emission',
    scopeOneScopeTwoTotal: 'Total Emission Scope 1 + 2',
    scopeOneScopeTwoScopeThreeTotal: 'Total Emission Scope 1 + 2 + 3',
    intensityPerTotalRevenue: 'GHG Intensity Scope 1 + 2 / revenue',
    intensityThreeScopesPerTotalRevenue: 'GHG Intensity Scope 1 + 2 + 3 / revenue',
    intensityPerProductionUnit: 'GHG Intensity Scope 1 + 2 / unit of production',
    intensityPerTotalEnergy: 'GHG Intensity Scope 1 + 2 / total energy',
    scopeOneActivityRows: 'Total Emission from Energy Scope 1',
    scopeTwoActivityRows: 'Total Emission from Energy Scope 2',
    scopeThreeActivityRows: 'Total Emission from Energy Scope 3',
    additionalGasesCount: 'Other gasess',
}

const toLocaleString = (number, locales) => {
    const float = number || 0
    return float.toLocaleString(locales, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

const parseUnit = (string) => {
    if (string === 'tCO2e') {
        return <span>tCO<sub>2</sub>e</span>
    } else if (string === 'm3') {
        return <span>m<sup>3</sup></span>
    } else if (string === 'tonCO2e') {
        return <span>tonCO<sub>2</sub>e</span>
    } else {
        return string
    }
}

const TRScope = ({
    scope = 'Scope 1',
    hasTotal = true,
    data,
    additionalColumn,
}) => {
    const { i18n } = useTranslation()
    const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

    if (Array.isArray(data) && data.length > 0) {
        const items = data.map(item => {
            const newItem = [
                { column: 1, value: item.category_name },
                { column: 2, value: toLocaleString(item.total_CO2, locales) },
                { column: 3, value: toLocaleString(item.total_CH4, locales) },
                { column: 4, value: toLocaleString(item.total_N2O, locales) },
                { column: 5, value: toLocaleString(item.total_HFCs, locales) },
                { column: 6, value: toLocaleString(item.total_PFCs, locales) },
                { column: 7, value: toLocaleString(item.total_SF6, locales) },
            ]
            if (additionalColumn === 1) {
                newItem.push({ column: 8, value: toLocaleString(item.total_NF3, locales) })
            } else if (additionalColumn === 2) {
                newItem.push({ column: 8, value: toLocaleString(item.total_NF3, locales) })
                newItem.push({ column: 9, value: toLocaleString(item.total_unspecified, locales) })
            }
            newItem.push({ column: newItem.length + 1, value: toLocaleString(item.record_total, locales) })
            return newItem
        })
        return (
            <>
                <tr>
                    <th
                        colSpan={9 + additionalColumn}
                    >
                        {scope}
                    </th>
                </tr>
                {items.map((row, i, arr) => {
                    return (
                        <tr key={i}>
                            {row.map(({ column, value }) => {
                                return (
                                    <td
                                        key={column}
                                        colSpan={column === 1 ? 2 : 1}
                                        style={{
                                            ...tdGasStyles,
                                            textAlign: column === 1 ? 'left' : 'right',
                                        }}
                                    >
                                        {value}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </>
        )
    } else {
        return null
    }
}

const EmptyTableBody = () => {
    return (
        <tbody>
            <tr>
                <td
                    colSpan={9}
                >
                    &nbsp;
                </td>
            </tr>
        </tbody>
    )
}

const TRActivity = ({
    scope,
    rows,
    additionalColumn,
}) => {
    const { t, i18n } = useTranslation()
    const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
    if (Array.isArray(rows) && rows.length > 0) {
        const count = {}
        const categoryNames = [...new Set(rows.map(item => item.category_name))]
        categoryNames.forEach((item) => {
            const len = rows.filter(row => row.category_name === item).length
            count[item] = {
                rowCount: len,
                startIndex: rows.findIndex(row => row.category_name === item),
            }
        })
        const items = rows.map((item, index) => {
            return [
                {
                    column: 1,
                    value: item.category_name,
                    rowSpan: count[item.category_name].rowCount,
                    colSpan: scope === LABEL.scopeThreeRows ? 4 : 1,
                    hide: index !== count[item.category_name].startIndex,
                    textAlign: 'left',
                },
                {
                    column: 2,
                    value: item.emission_name,
                    rowSpan: 1,
                    colSpan: 3 + additionalColumn,
                    hide: scope === LABEL.scopeThreeRows,
                    textAlign: 'left',
                },
                {
                    column: 3,
                    value: toLocaleString(item.record_amount, locales),
                    rowSpan: 1,
                    colSpan: 2,
                    textAlign: 'right',
                },
                {
                    column: 4,
                    value: parseUnit(item.emission_unit),
                    rowSpan: 1,
                    textAlign: 'left',
                    colSpan: 1,
                },
                {
                    column: 5,
                    value: toLocaleString(scope === LABEL.scopeThreeRows ? item.record_amount : item.record_total, locales),
                    rowSpan: 1,
                    colSpan: 1,
                    textAlign: 'right',
                },
                {
                    column: 6,
                    value: toLocaleString(item.total_energy_in_gj, locales),
                    rowSpan: 1,
                    colSpan: 1,
                    textAlign: 'right',
                },
            ]
        })

        let defaultHeaders = [
            { column: 1, label: `${scope}`, colSpan: (scope === LABEL.scopeThreeRows) ? 4 : 1 },
            { column: 2, label: t('report.source'), colSpan: 3 + additionalColumn },
            { column: 3, label: t('report.activityData'), colSpan: 2 },
            { column: 4, label: t('report.unit'), colSpan: 1 },
            { column: 5, label: <span>{t('report.total')} (tCO<sub>2</sub>e)</span>, colSpan: 1 },
            { column: 6, label: `${t('report.totalEnergy')} (GJ)`, colSpan: 1 },
        ]
        const headers = (scope === LABEL.scopeThreeRows) ? defaultHeaders.toSpliced(1, 1) : defaultHeaders

        return (
            <>
                <tr>
                    {headers.map(th => {
                        return (
                            <th
                                key={th.column}
                                colSpan={th.colSpan}
                            >
                                {th.label}
                            </th>

                        )
                    })}
                </tr>
                {items.map((row, i) => {
                    return (
                        <tr key={i}>
                            {row.map(({
                                column,
                                value,
                                rowSpan,
                                colSpan,
                                textAlign,
                                hide = false,
                            }) => {
                                if (hide) {
                                    return null
                                } else {
                                    return (
                                        <td
                                            key={column}
                                            rowSpan={rowSpan}
                                            colSpan={colSpan}
                                            style={{
                                                textAlign: textAlign,
                                            }}
                                        >
                                            {value}
                                        </td>
                                    )
                                }
                            })}
                        </tr>
                    )
                })}
            </>
        )
    } else {
        return null
    }
}

function ReportGHGInventory() {
    const { t, i18n } = useTranslation()
    const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'
    const account = useSelector((state) => state.account.value)


    const [optionYear, setOptionYear] = useState([])
    const [selectedYear, setSelectedYear] = useState('')
    const [ghg, setGHG] = useState({})
    const [additionalGassesCount, setAdditionalGassesCount] = useState(0)
    const tableHeaderDefault = [
        {
            key: 1,
            label: t('report.scopeOrEmissionSource'),
        },
        {
            key: 2,
            label: <span>CO<sub>2</sub></span>,
        },
        {
            key: 3,
            label: <span>CH<sub>4</sub></span>,
        },
        {
            key: 4,
            label: <span>N<sub>2</sub>O</span>,
        },
        {
            key: 5,
            label: 'HFCs',
        },
        {
            key: 6,
            label: 'PFCs',
        },
        {
            key: 7,
            label: <span>SF<sub>6</sub></span>,
        },
        {
            key: 8,
            label: <span>{t('report.total')}<br />(tCO<sub>2</sub>e)</span>
        }
    ]
    const [tableHeader, setTableHader] = useState(tableHeaderDefault)


    useEffect(() => {
        const controller = new AbortController()

        const getYears = async () => {
            try {
                const response = await axios({
                    withCredentials: true,
                    method: 'GET',
                    headers: { 'content-type': 'application/json' },
                    url: `${process.env.REACT_APP_BASE_SERVER}/record-year-and-company-summaries-lookup`,
                    signal: controller.signal,
                })
                if (response.data.ok) {
                    if (Array.isArray(response.data.data.years)) {
                        const lastYear = new Date().getFullYear() - 1
                        setOptionYear(response.data.data.years)
                        if (response.data.data.years.length > 0) {
                            const findLastYear = response.data.data.years.find(d => d.year === lastYear)
                            if (findLastYear) {
                                setSelectedYear(findLastYear.year)
                            } else {
                                setSelectedYear(response.data.data.years[0].year)
                            }
                        }
                    }
                    if (Array.isArray(response.data.data.companies)) {
                        //setCompanyOptions(response.data.data.companies)
                    }
                }
            } catch (e) {
                console.error(e)
                let message = ''
                if (typeof e.response?.data?.message === 'string') {
                    message = e.response.data.message
                } else if (e.message) {
                    message = e.message
                }
                // Prevent abort controller error from being shown
                if (e.code && e.code !== 'ERR_CANCELED') {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        html: `<p>${message}</p>`,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        }
        getYears()

        return () => controller.abort()
    }, [])

    useEffect(() => {
        const controller = new AbortController()

        const getGHGReport = async () => {
            try {
                const config = {
                    withCredentials: true,
                    method: 'GET',
                    headers: { 'content-type': 'application/json' },
                    url: `${process.env.REACT_APP_BASE_SERVER}/ghg-report-lookup`,
                    signal: controller.signal,
                    params: {
                        gwp: new URLSearchParams(window.location.search).get('gwp'),
                        year: selectedYear,
                    }
                }
                const responseGetReportGHG = await axios(config)
                if (responseGetReportGHG.data.success) {
                    setGHG(responseGetReportGHG.data.data)
                    const otherGassesCount = responseGetReportGHG.data.data[LABEL.additionalGasesCount]
                    setAdditionalGassesCount(otherGassesCount)
                    if (otherGassesCount === 0) {
                        setTableHader(tableHeaderDefault)
                    } else if (otherGassesCount === 1) {
                        const nF3 = {
                            key: 8,
                            label: <span>NF<sub>3</sub></span>
                        }
                        setTableHader(tableHeaderDefault.toSpliced(7, 1, nF3, {
                            key: 9,
                            label: <span>Total<br />(tCO<sub>2</sub>e)</span>
                        }))
                    }
                }
            } catch (e) {
                let message = ''
                if (typeof e.response?.data?.message === 'string') {
                    message = e.response.data.message
                } else if (e.message) {
                    message = e.message
                }
                // Prevent abort controller error from being shown
                if (e.code && e.code !== 'ERR_CANCELED') {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        html: `<p>${message}</p>`,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        }
        if (selectedYear) getGHGReport()

        return () => controller.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear])

    const onSelectYear = (e) => {
        setSelectedYear(e.target.value)
    }

    const onGenerateReport = async () => {
        const canvas = await html2canvas(document.getElementById('reportBody'))
        const image = canvas.toDataURL("image/png", 1.0)

        var imgWidth = 210
        var pageHeight = 300
        var imgHeight = (canvas.height - 30) * imgWidth / canvas.width
        var heightLeft = imgHeight
        var doc = new jsPDF('p', 'mm')
        var position = 8

        doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
            position += heightLeft - imgHeight + 5 // top padding for other pages
            doc.addPage()
            doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
        }
        doc.save(`${selectedYear} ${account.client_name} Consolidated Statement of Greenhouse Gas Emissions.pdf`)
    }

    return (
        <div className="page-content" >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">
                                {t('report.label1')}
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div
                                className="card-body"
                                id="reportBody"
                                style={{
                                    overflowX: "auto",
                                }}
                            >
                                <div className="row px-3">
                                    <div className="align-items-start d-flex justify-content-between mb-3 px-0 gap-3 flex-wrap">
                                        <div className="position-relative">
                                            <h4
                                                className='fw-bold m-0'
                                                style={{ fontSize: '24px' }}
                                            >
                                                {account.client_name}
                                            </h4>
                                            <p className='m-0'>{selectedYear}</p>
                                        </div>
                                        <div className="d-flex flex-wrap gap-2">
                                            <select
                                                style={{
                                                    width: "fit-content",
                                                    // TODO: Set up form-select padding at the app level instead
                                                    padding: '.3rem 2.7rem .3rem 0.9rem',
                                                }}
                                                onChange={onSelectYear}
                                                value={selectedYear}
                                                id="company"
                                                name="company"
                                                className="form-select"
                                            >
                                                {optionYear.map(({ year }) => <option key={year} value={year}>{year}</option>)}
                                            </select>
                                            <Download
                                                onClick={onGenerateReport}
                                                style={{
                                                    width: "31px",
                                                    height: "31px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {(
                                        !!ghg[LABEL.scopeOneRows] ||
                                        !!ghg[LABEL.scopeTwoRows] ||
                                        !!ghg[LABEL.scopeThreeRows] ||
                                        !!ghg[LABEL.biogenicRows]
                                    ) && (
                                            <div className="col-lg-12 overflow-auto p-0">
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colSpan={9 + additionalGassesCount}
                                                                style={thTitleStyles}
                                                            >
                                                                {t('report.GHGInventorySummary')} {t('report.GHGBasedOn')} {t('report.GHGProtocol')}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            {tableHeader.map(({ key, label }) => {
                                                                return (
                                                                    <th
                                                                        key={key}
                                                                        colSpan={key === 1 ? 2 : 1}
                                                                        style={thSubtitleTextStyles}
                                                                    >
                                                                        {label}
                                                                    </th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>

                                                    <tbody className='no-border'>
                                                        <TRScope
                                                            scope={LABEL.scopeOneRows}
                                                            data={ghg[LABEL.scopeOneRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        <TRScope
                                                            scope={LABEL.scopeTwoRows}
                                                            data={ghg[LABEL.scopeTwoRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        {account['client_scope'] == 1 ? <TRScope
                                                            scope={LABEL.scopeThreeRows}
                                                            data={ghg[LABEL.scopeThreeRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        /> : ""}
                                                        <TRScope
                                                            scope='Biogenic emission'
                                                            data={ghg[LABEL.biogenicRows]}
                                                            hasTotal={false}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                    </tbody>

                                                    <EmptyTableBody />

                                                    <tbody>
                                                        {[
                                                            {
                                                                th: <span>{t('report.totalEmissionScopeOneAndTwo')} (tCO<sub>2</sub>e)</span>,
                                                                td: toLocaleString(ghg[LABEL.scopeOneScopeTwoTotal], locales),
                                                            },
                                                            {
                                                                th: <span>{t('report.totalEmissionScopeOneTwoAndThree')} (tCO<sub>2</sub>e)</span>,
                                                                td: toLocaleString(ghg[LABEL.scopeOneScopeTwoScopeThreeTotal], locales),
                                                                hide: account['client_scope'] !== 1,
                                                            },
                                                            {
                                                                th: `${t('report.totalRevenue')} (${ghg.revenue.unit})`,
                                                                td: toLocaleString(ghg.revenue.total, locales),
                                                            },
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneAndTwo')} (tCO<sub>2</sub>e) / {t('report.revenue')} ({ghg.revenue.unit})</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityPerTotalRevenue], locales)} tCO<sub>2</sub>e {t('report.per')} {ghg.revenue.unit}</span>,
                                                            },
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneTwoAndThree')} (tCO<sub>2</sub>e) / {t('report.revenue')} ({ghg.revenue.unit})</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityThreeScopesPerTotalRevenue], locales)} tCO<sub>2</sub>e {t('report.per')} {ghg.revenue.unit}</span>,
                                                                hide: account['client_scope'] !== 1,
                                                            },
                                                            // TODO: Update unit of production
                                                            // {
                                                            //     th: <span>{t('report.GHGIntensityScopeOneAndTwo')} (tCO<sub>2</sub>e) / {t('report.unitOfProduction')}</span>,
                                                            //     td: <span>{toLocaleString(ghg[LABEL.intensityPerProductionUnit], locales)} tCO<sub>2</sub>e {t('report.per')} {ghg.revenue.unit}</span>,
                                                            // },
                                                            {
                                                                th: <span>{t('report.GHGIntensityScopeOneAndTwo')} (tCO<sub>2</sub>e) / {t('report.totalEnergy')} (GJ)</span>,
                                                                td: <span>{toLocaleString(ghg[LABEL.intensityPerTotalEnergy], locales)} tCO<sub>2</sub>e {t('report.per')} GJ</span>,
                                                            },
                                                            // TODO: Update GHG Intensity Scope 1 + 2 + 3 (tCO2e) / Total Energy (GJ)
                                                            // when data is available
                                                            // {
                                                            //   th: <span>{t('report.GHGIntensityScopeOneTwoAndThree')} (tCO<sub>2</sub>e) / {t('report.totalEnergy')} (GJ)</span>,
                                                            //   td: <span>{toLocaleString(ghg[LABEL.intensityPerTotalEnergy], locales)} tCO<sub>2</sub>e {t('report.per')} GJ</span>,
                                                            //   hide: account['client_scope'] !== 1,
                                                            // },
                                                        ].map(({ th, td, hide = false }, i) => {
                                                            if (hide) {
                                                                return null
                                                            } else {
                                                                return (
                                                                    <tr key={i}>
                                                                        <th
                                                                            colSpan={6}
                                                                        >
                                                                            {th}
                                                                        </th>
                                                                        <td
                                                                            colSpan={3 + additionalGassesCount}
                                                                            style={{
                                                                                textAlign: 'right',
                                                                            }}
                                                                        >
                                                                            {td}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })}
                                                    </tbody>

                                                    <EmptyTableBody />

                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colSpan={9 + additionalGassesCount}
                                                                style={thSubtitleStyles}
                                                            >
                                                                {t('report.totalEmissionFromEnergy')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <TRActivity
                                                            scope={LABEL.scopeOneRows}
                                                            rows={ghg?.[LABEL.scopeOneActivityRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        <TRActivity
                                                            scope={LABEL.scopeTwoRows}
                                                            rows={ghg?.[LABEL.scopeTwoActivityRows]}
                                                            additionalColumn={additionalGassesCount}
                                                        />
                                                        {/*
                              TODO: Show the component below if scope 3 energy calculation is available
                              {account['client_scope'] == 1 ? <TRActivity
                                scope={LABEL.scopeThreeRows}
                                rows={ghg?.[LABEL.scopeThreeActivityRows]}
                                additionalColumn={additionalGassesCount}
                              /> : ""}
                            */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ReportGHGInventory
