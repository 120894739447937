import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import DataTable from 'react-data-table-component';

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';

import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

export class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumn: [],
            tableData: [],
            tableFilter: "",
            tableResetPagination: false,
        }
    }

    componentDidMount = async () => {
        showPreloader();

        const data = {};
        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/activity`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            //console.log(responseGetCompany.data.data)
            const columns = [
                {
                    name: 'Activity',
                    selector: row => row.activity_label,
                    sortable: true,
                    filterable: true,
                },
            ];
            const record = responseGetCompany.data.data;
            this.setState({
                tableColumn: columns,
                tableData: record,
                tableFiltered: record,
            })
        }

        hidePreloader();
    }

    onFilter = async (e) => {
        // eslint-disable-next-line
        if (e.target.value != "") {
            let filterValue = e.target.value;
            let baseData = this.state.tableData;
            //console.log(baseData);
            let filteredData = baseData.filter(
                item =>
                    item.company_name.toLowerCase().includes(this.state.tableFilter.toLowerCase())
            )
            this.setState({
                tableFilter: filterValue,
                tableFiltered: filteredData,
            })
        } else {
            this.setState({
                tableFilter: "",
                tableFiltered: this.state.tableData,
            })
        }
        //console.log(this.state.tableFilter)
        //console.log(this.state.tableFiltered)
    }

    onClear = async () => {
        // eslint-disable-next-line
        if (this.state.tableFilter != "") {
            this.setState({
                tableFilter: "",
            })
        }
    }

    render() {
        const { tableColumn, tableFiltered } = this.state;

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Decarbonization Roadmap</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Manage Activity</h5>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={tableColumn}
                                        data={tableFiltered}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Activity));