import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'

import TableHead from '../components/TableHead'
import TableBody from '../components/TableBody'

const tableStyle = {
  borderCollapse: 'collapse',
  fontSize: "10px",
  width: '100%',
}

const borderStyle = {
  border: '0.5px solid black',
  padding: '0 0.3rem',
}

const thStyles = {
  ...borderStyle,
  textAlign: 'center',
  backgroundColor: "#8EA9DB",
  width: '12%',
}

const tdStyles = {
  ...borderStyle,
  textAlign: 'right',
}

const tdSecondaryStyles = {
  ...borderStyle,
  backgroundColor: "#C7D0E5",
  fontWeight: 'bold',
}

const toLocaleString = (number, locales) => {
  const float = number || 0
  return float.toLocaleString(locales, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

const parseUnit = (string) => {
  if (string === 'tCO2e') {
    return <span>tCO<sub>2</sub>e</span>
  } else if (string === 'm3') {
    return <span>m<sup>3</sup></span>
  } else {
    return string
  }
}

function ReportSummary() {
  const { t, i18n } = useTranslation()
  const account = useSelector((state) => state.account.value)

  const [optionYear, setOptionYear] = useState([])
  const [selectedYear, setSelectedYear] = useState('')

  const [ghgTotalHeaders, setGhgTotalHeaders] = useState([])
  const [ghgTotalRows, setGhgTotalRows] = useState([])
  const [recordedYears, setRecordedYear] = useState([])
  const [ghgDetailsHeaders, setGhgDetailsHeaders] = useState([])
  const [ghgDetailsScope1Rows, setGhgDetailsScope1Rows] = useState([])
  const [ghgDetailsScope2Rows, setGhgDetailsScope2Rows] = useState([])
  const [ghgDetailsScope3Rows, setGhgDetailsScope3Rows] = useState([])

  const onSelectYear = (e) => {
    setSelectedYear(e.target.value)
  }

  useEffect(() => {
    const controller = new AbortController()

    const getYearSummaries = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/record-year-summaries-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          if (Array.isArray(response.data.data)) {
            const lastYear = new Date().getFullYear() - 1
            setOptionYear(response.data.data)
            if (response.data.data.length > 0) {
              const findLastYear = response.data.data.find(d => d.year === lastYear)
              if (findLastYear) {
                setSelectedYear(findLastYear.year)
              } else {
                setSelectedYear(response.data.data[0].year)
              }
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    getYearSummaries()

    return () => controller.abort()
  }, [])


  useEffect(() => {
    const controller = new AbortController()

    const getGRIReport = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/report-gri-lookup`,
          signal: controller.signal,
          params: {
            selectedYears: [selectedYear],
            gwp: new URLSearchParams(window.location.search).get('gwp'),
            hideGRICode: 1, // 1 means hide, 0 means show
          },
        })
        if (response.data.ok && response.data.data.gHGTotalRows.length > 0) {
          const ghgTotalHeaderItems = [
            {
              key: 1,
              label: 'Scope',
              labelId: 'Cakupan',
            },
            {
              key: 2,
              label: 'Unit',
              labelId: 'Satuan',
            },
          ]
          ghgTotalHeaderItems.push(...response.data.data.recordedYears.map((d, index) => {
            return {
              key: index + 3,
              label: d,
              labelId: d,
            }
          }))
          setGhgTotalHeaders(ghgTotalHeaderItems)
          setGhgTotalRows(response.data.data.gHGTotalRows)
          setRecordedYear(response.data.data.recordedYears)
          const ghgDetailsHeaderItems = [
            {
              key: 1,
              label: 'Scope/Emission Source',
              labelId: 'Cakupan/Sumber Emisi',
            },
          ]
          ghgDetailsHeaderItems.push(...response.data.data.recordedYears.map((year, index) => {
            return {
              key: index + 2,
              label: <span>Total Emission {year} <br />({parseUnit('tCO2e')})</span>,
              labelId: <span>Total Emisi {year} <br />({parseUnit('tCO2e')})</span>,
            }
          }))
          setGhgDetailsHeaders(ghgDetailsHeaderItems)
          setGhgDetailsScope1Rows(response.data.data.emissionScope1Rows)
          setGhgDetailsScope2Rows(response.data.data.emissionScope2Rows)
          setGhgDetailsScope3Rows(response.data.data.emissionScope3Rows)

          // Scope 2
        } else {
          setGhgTotalHeaders([])
          setGhgTotalRows([])
          setRecordedYear([])
          setGhgDetailsScope1Rows([])
          setGhgDetailsScope2Rows([])
          setGhgDetailsScope3Rows([])
        }
      } catch (e) {
        console.log(e)
      }

    }
    if (selectedYear) {
      getGRIReport()
    }

    return () => controller.abort()
  }, [selectedYear])

  return (
    <div className="page-content" >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {t('report.label1')}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12" style={{ maxWidth: "1200px" }}>
            <div className="card">
              <div className="card-header d-flex justify-content-between col-sm align-items-center">
                <h4 className="card-title mb-0 flex-grow-1 ms-1">
                  {t('menu.label5')}
                </h4>
                <div className="d-flex flex-wrap gap-2">
                  <select
                    style={{ width: "fit-content" }}
                    onChange={onSelectYear}
                    value={selectedYear}
                    id="selectedYear"
                    name="selectedYear"
                    className="form-select mb-3"
                  >
                    {optionYear.map(({ year }) => <option key={year} value={year}>{year}</option>)}
                  </select>
                </div>
              </div>

              <div
                className="card-body"
                id="reportBody"
                style={{
                  maxWidth: "1200px",
                  overflowX: "auto",
                }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <table style={{ fontSize: "10px" }}>
                      <tbody>
                        <tr>
                          <td style={{ padding: "2px 10px", fontWeight: "bold" }}>
                            {t('report.reportingEntity')}
                          </td>
                          <td style={{ padding: "2px 10px" }}>: {account.client_name}</td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>

                  {selectedYear && (
                    <div className="col-lg-12">
                      <table
                        style={{
                          ...tableStyle,
                        }}
                      >
                        <TableHead
                          style={{
                            ...thStyles,
                          }}
                          colSpan={10}
                        >
                          <tr>
                            <th
                              colSpan={10}
                              style={{
                                ...thStyles,
                              }}
                            >
                              {t('report.gri.totalOfGhgEmissions')}
                            </th>
                          </tr>
                          <tr>
                            {ghgTotalHeaders.map(({ key, label, labelId }) => {
                              let colSpan = 1
                              if (key === 1) {
                                colSpan = 6
                              }
                              return (
                                <th
                                  key={key}
                                  style={{
                                    ...thStyles,
                                    width: '12%'
                                  }}
                                  colSpan={colSpan}
                                >
                                  {i18n.language === 'id' ? labelId : label}
                                </th>
                              )
                            })}
                          </tr>
                        </TableHead>
                        <TableBody
                        >
                          {ghgTotalRows.map((row) => {
                            return (
                              <tr key={row.label}>
                                <td
                                  style={{
                                    ...tdStyles,
                                    textAlign: 'center',
                                    fontWeight: row.label === 'Total' ? 'bold' : 'inherit',
                                  }}
                                  colSpan={6}
                                >
                                  {i18n.language === 'id' ? row.labelId : row.label}
                                </td>
                                <td
                                  style={{
                                    ...tdStyles,
                                    textAlign: 'center',
                                    fontWeight: row.label === 'Total' ? 'bold' : 'inherit',
                                  }}
                                >
                                  tCO<sub>2</sub>e
                                </td>
                                {recordedYears.map((yearRecorded) => {
                                  const findRecord = row.years.find(data => data.year === yearRecorded)
                                  const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                  let value = '-'
                                  if (findRecord) {
                                    value = toLocaleString(findRecord.total, locales)
                                  }
                                  return (
                                    <td
                                      key={yearRecorded}
                                      style={{
                                        ...tdStyles,
                                        textAlign: 'right',
                                        fontWeight: row.label === 'Total' ? 'bold' : 'inherit',
                                      }}
                                    >
                                      {value}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </TableBody>

                        <thead data-html2canvas-ignore="true">
                          <tr>
                            <td colSpan={9}><br /><br /><br /></td>
                          </tr>
                        </thead >

                        <TableHead>
                          <tr>
                            <th
                              style={{
                                ...thStyles,
                              }}
                              colSpan={10}
                            >
                              {t('report.gri.detailsOfGhgEmissions')}
                            </th>
                          </tr>
                        </TableHead>
                        <TableBody>
                          <tr>
                            {ghgDetailsHeaders.map(({ key, label, labelId }) => {
                              let colSpan = 1
                              if (key === 1) {
                                colSpan = 7
                              }
                              return (
                                <th
                                  key={key}
                                  style={{
                                    ...thStyles,
                                  }}
                                  colSpan={colSpan}
                                >
                                  {i18n.language === 'id' ? labelId : label}
                                </th>
                              )
                            })}
                          </tr>
                        </TableBody>
                        <TableBody>
                          {ghgDetailsScope1Rows.length > 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={10}
                                  style={{
                                    ...tdSecondaryStyles,
                                  }}
                                >
                                  {i18n.language === 'id' ? 'Cakupan 1' : 'Scope 1'}
                                </td>
                              </tr>
                              {ghgDetailsScope1Rows.map((row) => {
                                return (
                                  <tr key={row.label}>
                                    <td
                                      style={{
                                        ...tdStyles,
                                        textAlign: 'center',
                                        fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                      }}
                                      colSpan={7}
                                    >
                                      {i18n.language === 'id' ? row.labelId : row.label}
                                    </td>
                                    {recordedYears.map((yearRecorded) => {
                                      const findRecord = row.years.find(data => data.year === yearRecorded)
                                      const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                      let value = toLocaleString('-', locales)
                                      if (findRecord) {
                                        value = toLocaleString(findRecord.total, locales)
                                      }
                                      return (
                                        <td
                                          key={yearRecorded}
                                          style={{
                                            ...tdStyles,
                                            textAlign: 'right',
                                            fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                          }}
                                        >
                                          {value}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )}
                          {ghgDetailsScope2Rows.length > 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={10}
                                  style={{
                                    ...tdSecondaryStyles,
                                  }}
                                >
                                  {i18n.language === 'id' ? 'Cakupan 2' : 'Scope 2'}
                                </td>
                              </tr>
                              {ghgDetailsScope2Rows.map((row) => {
                                return (
                                  <tr key={row.label}>
                                    <td
                                      style={{
                                        ...tdStyles,
                                        textAlign: 'center',
                                        fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                      }}
                                      colSpan={7}
                                    >
                                      {i18n.language === 'id' ? row.labelId : row.label}
                                    </td>
                                    {recordedYears.map((yearRecorded) => {
                                      const findRecord = row.years.find(data => data.year === yearRecorded)
                                      const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                      let value = toLocaleString('-', locales)
                                      if (findRecord) {
                                        value = toLocaleString(findRecord.total, locales)
                                      }
                                      return (
                                        <td
                                          key={yearRecorded}
                                          style={{
                                            ...tdStyles,
                                            textAlign: 'right',
                                            fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                          }}
                                        >
                                          {value}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )}
                          {ghgDetailsScope3Rows.length > 0 && (
                            <>
                              <tr>
                                <td
                                  colSpan={10}
                                  style={{
                                    ...tdSecondaryStyles,
                                  }}
                                >
                                  {i18n.language === 'id' ? 'Cakupan 3' : 'Scope 3'}
                                </td>
                              </tr>
                              {ghgDetailsScope3Rows.map((row) => {
                                return (
                                  <tr key={row.label}>
                                    <td
                                      style={{
                                        ...tdStyles,
                                        textAlign: 'center',
                                        fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                      }}
                                      colSpan={7}
                                    >
                                      {i18n.language === 'id' ? row.labelId : row.label}
                                    </td>
                                    {recordedYears.map((yearRecorded, index) => {
                                      const findRecord = row.years.find(data => data.year === yearRecorded)
                                      const locales = i18n.language === 'en' ? 'en-US' : 'id-ID'

                                      let value = toLocaleString('-', locales)
                                      if (findRecord) {
                                        value = toLocaleString(findRecord.total, locales)
                                      }
                                      return (
                                        <td
                                          key={yearRecorded}
                                          style={{
                                            ...tdStyles,
                                            textAlign: 'right',
                                            fontWeight: row.label.includes('Total') ? 'bold' : 'inherit',
                                          }}
                                        >
                                          {value}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )}
                        </TableBody>

                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div >

        </div >
      </div >
    </div >
  )
}

export default ReportSummary