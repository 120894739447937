import { useTranslation } from 'react-i18next'

export default function NotFound() {
  const { t } = useTranslation()
  return (
    <div
      className="text-center"
      style={{
        height: '62vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <i
        className="ri-search-2-line text-muted"
        style={{ fontSize: '40px' }}
      >
      </i>
      <h5
        className="mt-3 text-muted"
        style={{
          fontWeight: 400,
          fontStyle: 'italic',
        }}
      >
        {t('noResultFound')}
      </h5>
    </div>
  )
}
