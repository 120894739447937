import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import html2canvas from 'html2canvas'

import LowerSection from '../components/Dashboard/Sections/LowerSection'
import MiddleSection from '../components/Dashboard/Sections/MiddleSection'
import UpperSection from '../components/Dashboard/Sections/UpperSection'
import { D3StackedBarGraph } from '../components/D3/StackedBarChart'

const onDownloadImage = async (element, label) => {
  const canvas = await html2canvas(document.getElementById(element))
  const image = canvas.toDataURL('image/png', 1.0)

  const fakeLink = window.document.createElement('a')
  fakeLink.style = 'display:none'
  fakeLink.download = label

  fakeLink.href = image

  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)

  fakeLink.remove()
}

function Dashboard() {
  const { t } = useTranslation()

  const [selectedYear, setSelectedYear] = useState()
  const [yearOptions, setYearOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])

  useEffect(() => {
    const controller = new AbortController()

    const getYears = async () => {
      const response = await axios({
        withCredentials: true,
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        url: `${process.env.REACT_APP_BASE_SERVER}/record-year-and-company-summaries-lookup`,
        signal: controller.signal,
      })
      if (response.data.ok) {
        if (Array.isArray(response.data.data.years)) {
          const lastYear = new Date().getFullYear() - 1
          setYearOptions(response.data.data.years)
          if (response.data.data.years.length > 0) {
            const findLastYear = response.data.data.years.find(d => d.year === lastYear)
            if (findLastYear) {
              setSelectedYear(findLastYear.year)
            } else {
              setSelectedYear(response.data.data.years[0].year)
            }
          }
        }

        if (Array.isArray(response.data.data.companies)) {
          setCompanyOptions(response.data.data.companies)
        }
      }
    }
    getYears()

    return () => controller.abort()
  }, [])

  const onChangeYear = (event) => {
    setSelectedYear(Number(event.target.value))
  }

  return (
    <div className='d-flex flex-column gap-3' style={{ padding: '94px 24px 84px' }}>
      <div className='align-items-center d-flex justify-content-between mb-4'>
        <h4 className='mb-sm-0' style={{ color: '#09564B' }}>
          {t('menu.label1')}
        </h4>
        {selectedYear && (
          <select
            style={{ width: 'auto' }}
            className='form-select'
            id='selectedEmissionYear'
            value={selectedYear}
            onChange={onChangeYear}
          >
            {yearOptions.map(yearOpt => {
              return (
                <option key={yearOpt.year} value={yearOpt.year}>
                  {yearOpt.year}
                </option>
              )
            })}
          </select>
        )}
      </div>

      <UpperSection
        companyOptions={companyOptions}
        selectedYear={selectedYear}
      />

      <MiddleSection
        companyOptions={companyOptions}
        onDownloadImage={onDownloadImage}
        selectedYear={selectedYear}
      />

      <LowerSection
        companyOptions={companyOptions}
        onDownloadImage={onDownloadImage}
        selectedYear={selectedYear}
      />

      <D3StackedBarGraph />
    </div>
  )
}

export default Dashboard
