import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import { connect } from "react-redux"
import { increment, decrement } from "../../features/counter/counterSlice"
import { setLoggedIn } from "../../features/account/loginSlice"
import { setAccount } from "../../features/account/accountSlice"
import { setCompany } from "../../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import "react-tooltip/dist/react-tooltip.css";

import { hidePreloader, showPreloader } from '../../library/helper';
import { withTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";

import InputNumeric from '../../components/InputNumeric'

export class ReductionCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            redirectEmission: false,
            selectedCompany: 0,
            selectedOffice: 0,
            selectedDate: new Date(Date.now()),
            selectedScope: 0,
            selectedCategory: 0,
            selectedItem: 0,
            amountItem: 0,

            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: 0,
                    amount_2: 0,
                    amount_3: 0,
                    amount_4: 0,
                    amount_5: 0,
                    amount_6: 0,
                    amount_7: 0,
                    amount_8: 0,
                    unit: 0,
                    multiplier: 1,
                }
            ],

            listCategory: [],
            listFactor: [],
            itemUnit: '',

            amountEmission: 0,
            countRecords: 1,

            selectedID: "",

            listReductionCategory: [
                {
                    category_id: 1,
                    category_name: "Energy Efficiency",
                },
                {
                    category_id: 2,
                    category_name: "Energy Substitution",
                },
                {
                    category_id: 3,
                    category_name: "Renewable Energy",
                },
                {
                    category_id: 4,
                    category_name: "Carbon Capture",
                },
            ],
            listReductionCategoryType: [],
            listReductionCategoryTypeFiltered: [],
            selectedReductionCategory: 0,

            bauFactor: 0,
            bauUnit: 0,
            bauMultiplier: 0,
            bauAmount: 0,

            actualFactor: 0,
            actualUnit: 0,
            actualMultiplier: 0,
            actualAmount: 0,
        }
    }

    componentDidMount = async () => {
        showPreloader();

        let listCompany = [];
        let listCategory = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];

        let listReductionCategory = [];
        let listReductionCategoryType = [];

        console.log("emission create");
        const data = {}

        const optionsGetReductionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/reduction/category`
        };
        const responseGetReductionCategory = await axios(optionsGetReductionCategory);
        if (responseGetReductionCategory.data.success) {
            listReductionCategoryType = responseGetReductionCategory.data.data
        }
        console.log(listReductionCategoryType)

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }
        console.log(listCompany)

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }
        console.log(listCategory)

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }
        console.log(listEmissionFactor)

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }
        console.log(listEmissionFactorUnit)

        this.setState({
            listCompany: listCompany,
            listCategory: listCategory,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
            listReductionCategoryType: listReductionCategoryType,
        })

        console.log(this.state.selectedDate)
        hidePreloader();
    }

    onSelectReductionCategory = async (e) => {
        let categoryValue = e.target.value;
        console.log(categoryValue)
        console.log(this.state.listReductionCategoryType)
        let filteredData = this.state.listReductionCategoryType.filter(
            item => item.crc_category == categoryValue
        )
        console.log(filteredData)
        this.setState({
            listReductionCategoryTypeFiltered: filteredData,
            selectedReductionCategory: categoryValue,
        })
    }

    onSelectCompany = async (e) => {
        let companyValue = e.target.value;
        console.log(companyValue)
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                selectedOffice: 0,
            })
        }
    }

    onLoadCompany = async (companyValue) => {
        console.log(companyValue)
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                //selectedOffice: 0,
            })
        }
    }

    onSelectCategory = async (e) => {
        /*let categoryValue = e;
        console.log(categoryValue)
        this.setState({
            selectedCategory: categoryValue,
        })*/
        let categoryValue = e.target.value;

        this.setState({
            selectedCategory: categoryValue,
            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: "",
                    unit: 0,
                    multiplier: 1,
                }
            ],
        })
    }

    onSelectOffice = async (e) => {
        let officeValue = e.target.value;
        this.setState({
            selectedOffice: officeValue,
        })
    }

    onSelectDate = async (e) => {
        console.log(new Date(e).getFullYear());
        console.log(new Date(e).getMonth());
        var datePick = new Date(new Date(e).getFullYear(), new Date(e).getMonth(), 7);
        console.log(datePick)
        this.setState({
            selectedDate: datePick,
        })
    }

    onInsertRow = async () => {
        let data = this.state.listEmissionRecord;
        data.push({
            assets: "",
            factor: 0,
            amount: "",
            unit: 0,
            multiplier: 1,
        })
        this.setState({
            countRecords: this.state.countRecords + 1,
        })
    }

    onDeleteRow = async (index) => {
        let data = this.state.listEmissionRecord;
        data.splice(index, 1)
        this.setState({
            countRecords: this.state.countRecords - 1,
        })
    }

    onInputAsset = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].assets = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onSelectEmission = async (e, index) => {
        if (index == 0) {
            this.setState({
                bauFactor: e.target.value
            })
        } else {
            this.setState({
                actualFactor: e.target.value
            })
        }
    }

    onSelectUnit = async (e, index) => {
        let listEmissionFactorUnit = this.state.listEmissionFactorUnit;
        let filteredUnit = listEmissionFactorUnit.filter(
            // eslint-disable-next-line
            item => item.unit_id == e.target.value
        )
        if (index == 0) {
            this.setState({
                bauUnit: e.target.value,
                bauMultiplier: filteredUnit[0].unit_multiplier,
            })
        } else {
            this.setState({
                actualUnit: e.target.value,
                actualMultiplier: filteredUnit[0].unit_multiplier,
            })
        }
    }

    onInputAmount = (values, sourceInfo) => {
        if (sourceInfo?.event?.target?.name && values) {
            this.setState({
                [sourceInfo.event.target.name]: values.value,
            })
        }
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/reduction/create`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onSaveRecord = async (e) => {
        let invalidData = true;

        let listCategory = this.state.listCategory;
        let filteredCategory = listCategory.filter(
            // eslint-disable-next-line
            item => item.category_id == this.state.selectedCategory
        )
        //console.log(filteredCategory)

        console.log(this.state.selectedReductionCategory)
        console.log(this.state.selectedCategory)
        console.log(this.state.selectedDate)

        console.log(this.state.bauFactor)
        console.log(this.state.bauUnit)
        console.log(this.state.bauMultiplier)
        console.log(this.state.bauAmount)

        console.log(this.state.actualFactor)
        console.log(this.state.actualUnit)
        console.log(this.state.actualMultiplier)
        console.log(this.state.actualAmount)

        let listEmissionFactor = this.state.listEmissionFactor;
        let filteredEmissionFactorBau = listEmissionFactor.filter(
            // eslint-disable-next-line
            item => item.emission_factor_id == this.state.bauFactor && item.cf_category == this.state.selectedCategory
        )

        let filteredEmissionFactorActual = listEmissionFactor.filter(
            // eslint-disable-next-line
            item => item.emission_factor_id == this.state.actualFactor && item.cf_category == this.state.selectedCategory
        )

        let data = null;
        if (this.state.selectedReductionCategory == 2) {
            data = {
                'reductionCategory': this.state.selectedReductionCategory,
                'category': this.state.selectedCategory,
                'bauFactor': this.state.bauFactor,
                'bauUnit': this.state.bauUnit,
                'bauAmount': this.state.bauAmount,
                'bauTotal': parseFloat(this.state.bauAmount) * parseFloat(this.state.bauMultiplier) * parseFloat(filteredEmissionFactorBau[0].emission_factor),
                'actualFactor': this.state.actualFactor,
                'actualUnit': this.state.actualUnit,
                'actualAmount': this.state.actualAmount,
                'actualTotal': parseFloat(this.state.actualAmount) * parseFloat(this.state.actualMultiplier) * parseFloat(filteredEmissionFactorActual[0].emission_factor),
                'date': this.state.selectedDate,
            };
        } else {
            data = {
                'reductionCategory': this.state.selectedReductionCategory,
                'category': this.state.selectedCategory,
                'bauFactor': this.state.bauFactor,
                'bauUnit': this.state.bauUnit,
                'bauAmount': this.state.bauAmount,
                'bauTotal': parseFloat(this.state.bauAmount),
                'actualFactor': this.state.actualFactor,
                'actualUnit': this.state.actualUnit,
                'actualAmount': this.state.actualAmount,
                'actualTotal': parseFloat(this.state.actualAmount),
                'date': this.state.selectedDate,
            };
        }
        console.log(data)
        const result = this.onSaveRecordCall(data);
        //const result = false;
        if (result) {
            invalidData = false;
        }

        /*this.state.listEmissionRecord.forEach(element => {
            let listEmissionFactor = this.state.listEmissionFactor;
            let filteredEmissionFactor = listEmissionFactor.filter(
                // eslint-disable-next-line
                item => item.emission_factor_id == element.factor && item.cf_category == this.state.selectedCategory
            )

            let data = null;
            console.log(filteredEmissionFactor)
            if(filteredEmissionFactor.length > 0){
                //console.log(parseFloat(filteredEmissionFactor[0].emission_factor))
                data = {
                    'id': this.state.selectedID,
                    'token': jwtDecode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)).token,
                    'scope': filteredCategory[0].category_scope,
                    'category': this.state.selectedCategory,
                    'factor': element.factor,
                    'unit': element.unit,
                    'company': this.state.selectedCompany,
                    'location': this.state.selectedOffice,
                    'assets': element.assets,
                    'date': this.state.selectedDate,
                    'amount': element.amount,
                    'total': parseFloat(element.amount) * parseFloat(element.multiplier) * parseFloat(filteredEmissionFactor[0].emission_factor),
                };
            }else{
                data = {
                    'id': this.state.selectedID,
                    'token': jwtDecode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)).token,
                    'scope': filteredCategory[0].category_scope,
                    'category': this.state.selectedCategory,
                    'factor': element.factor,
                    'unit': element.unit,
                    'company': this.state.selectedCompany,
                    'location': this.state.selectedOffice,
                    'assets': element.assets,
                    'date': this.state.selectedDate,
                    'amount': element.amount,
                    'total': parseFloat(element.amount) * parseFloat(element.multiplier),
                };
            }
            console.log(data)
            const result = this.onSaveRecordCall(data);
            //const result = false;
            if(result){
                invalidData = false;
            }
        });*/
        if (invalidData) {
            Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        } else {
            Swal.fire({
                title: '',
                html: '<p>Successfully record emissions</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        redirectEmission: true
                    })
                }
            })
        }
    }

    render() {
        const { redirectLogin, redirectEmission } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { selectedDate } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor, listEmissionFactorUnit, listEmissionRecord } = this.state;

        if (redirectLogin) {
            return <Navigate to={'/login'} />
        }

        if (redirectEmission) {
            return <Navigate to={'/reduction'} />
        }

        let optionReductionCategory = [];
        optionReductionCategory.push(this.state.selectedReductionCategory > 0 ? null : <option key={0} value={0}>Select Category</option>)
        // eslint-disable-next-line
        if (this.state.listReductionCategory != undefined) {
            if (this.state.listReductionCategory.length > 0) {
                this.state.listReductionCategory.map(async (item, index) => {
                    optionReductionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
                })
            }
        }

        let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>Select Source</option>)
        // eslint-disable-next-line
        if (this.state.listReductionCategoryTypeFiltered != undefined) {
            if (this.state.listReductionCategoryTypeFiltered.length > 0) {
                this.state.listReductionCategoryTypeFiltered.map(async (item, index) => {
                    optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
                })
            }
        }

        let optionCompany = [];
        optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
        // eslint-disable-next-line
        if (listCompany != undefined) {
            if (listCompany.length > 0) {
                listCompany.map(async (item, index) => {
                    optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
                })
            }
        }

        let optionOffice = [];
        optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>Select Location</option>)
        // eslint-disable-next-line
        if (listOffice != undefined) {
            if (listOffice.length > 0) {
                listOffice.map(async (item, index) => {
                    optionOffice.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
                })
            }
        }

        {/*let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>Select Source</option>)
        // eslint-disable-next-line
        if(listCategory != undefined) {
            if(listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    optionCategory.push(<option key={index+1} value={item.category_id}>{item.category_name}</option>)
                    // eslint-disable-next-line
                    /*if(item.category_id == selectedCategory){
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }else{
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }*
                })
            }
        }*/}

        let optionEmissionFactor = [];
        // eslint-disable-next-line
        if (selectedCategory != 0) {
            // eslint-disable-next-line
            if (listEmissionFactor != undefined) {
                if (listEmissionFactor.length > 0) {
                    listEmissionFactor.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.cf_category == selectedCategory) {
                            optionEmissionFactor.push(
                                <option key={index + 1} value={item.emission_factor_id}>{item.emission_name}</option>
                            )
                        }
                    })
                }
            }
        }

        //let emissionRows = [];
        //for (let i = 0; i < countRecords; i++) {
        let optionEmissionFactorUnit = [];
        // eslint-disable-next-line
        if (this.state.bauFactor != 0) {
            // eslint-disable-next-line
            if (listEmissionFactorUnit != undefined) {
                if (listEmissionFactorUnit.length > 0) {
                    listEmissionFactorUnit.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.unit_factor == this.state.bauFactor) {
                            optionEmissionFactorUnit.push(
                                <option key={index + 1} value={item.unit_id}>{item.unit_label}</option>
                            )
                        }
                    })
                }
            }
        }

        /*if(optionEmissionFactor.length > 0) {
            emissionRows.push(
                <tr key={i}>
                    <td>
                        <input
                            onChange={e => this.onInputAsset(e, i)}
                            value={listEmissionRecord[i].assets}
                            className="form-control"
                            placeholder="assets"
                        ></input>
                    </td>
                    <td>
                        <select onChange={e => this.onSelectEmission(e, i)} value={listEmissionRecord[i].factor} className="form-select mb-0">
                            {listEmissionRecord[i].factor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "Grid Name" : selectedCategory == 3 ? "Refrigerant Type" : "Fuel Type"}</option>}
                            {optionEmissionFactor}
                        </select>
                    </td>
                    <td>
                        <select onChange={e => this.onSelectUnit(e, i)} value={listEmissionRecord[i].unit} className="form-select mb-0">
                            {listEmissionRecord[i].unit > 0 ? "" : <option value="0">Select Unit</option>}
                            {optionEmissionFactorUnit}
                        </select>
                    </td>
                    <td>
                        <input
                            type="number"
                            onChange={e => this.onInputAmount(e, i)}
                            value={listEmissionRecord[i].amount}
                            className="form-control"
                            placeholder="amount"
                        ></input>
                    </td>
                    <td>
                        <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                    </td>
                </tr>
            )
        }else{
            emissionRows.push(
                <tr key={i}>
                    <td>
                        <input
                            onChange={e => this.onInputAsset(e, i)}
                            value={listEmissionRecord[i].assets}
                            className="form-control"
                            placeholder="assets"
                        ></input>
                    </td>
                    <td>
                        <input
                            type="number"
                            onChange={e => this.onInputAmount(e, i)}
                            value={listEmissionRecord[i].amount}
                            className="form-control"
                            placeholder="amount"
                        ></input>
                    </td>
                    <td>
                        <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                    </td>
                </tr>
            )
        }*/
        //}

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t("menu.label31")}</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{this.props.t('general.btnCreate')}</h4>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company" className="form-label">Reduction Category</label>
                                                <select onChange={e => this.onSelectReductionCategory(e)} value={this.state.selectedReductionCategory} id="company" name="company" className="form-select mb-3">
                                                    {optionReductionCategory}
                                                </select>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company" className="form-label">Company</label>
                                                <select onChange={e => this.onSelectCompany(e)} value={selectedCompany} id="company" name="company" className="form-select mb-3">
                                                    {optionCompany}
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.selectedCompany > 0 ? <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="office" className="form-label">Location</label>
                                                <select onChange={e => this.onSelectOffice(e)} value={selectedOffice} id="company" name="company" className="form-select mb-3">
                                                    {optionOffice}
                                                </select>
                                            </div>
                                        </div> : ""}*/}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="category" className="form-label">GHG Emission Source</label> {/*<i id="ghg-emission" className="ri-question-line"></i>
                                                <ReactTooltip anchorId="ghg-emission" place="bottom" content="GHG Emission Source are ..............." />*/}
                                                <select onChange={e => this.onSelectCategory(e)} value={selectedCategory} id="category" name="category" className="form-select mb-1">
                                                    {optionCategory}
                                                </select>
                                                {/* eslint-disable-next-line */}
                                                {selectedCategory == 1 || selectedCategory == 2 ?
                                                    <p>&nbsp;References : <a target="_blank" rel="noreferrer" href="https://ditjenppi.menlhk.go.id/reddplus/images/adminppi/dokumen/igrk/Buku-EFDB.pdf">EFDB National KLHK 2017</a></p>
                                                    /* eslint-disable-next-line */
                                                    : selectedCategory == 7 ?
                                                        <p>&nbsp;References : <a target="_blank" rel="noreferrer" href="https://gatrik.esdm.go.id/assets/uploads/download_index/files/96d7c-nilai-fe-grk-sistem-ketenagalistrikan-tahun-2019.pdf">GRK National ESDM 2019</a></p>
                                                        : ""}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="date" className="form-label">Month</label>
                                                <div>
                                                    <DatePicker
                                                        id="date"
                                                        selected={selectedDate}
                                                        onChange={(date) => this.onSelectDate(date)}
                                                        dateFormat="MMMM yyyy"
                                                        className="form-select mb-3"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {this.state.selectedReductionCategory > 1 ?
                                            optionEmissionFactor.length > 0 ? <div className="col-md-6">
                                                <div className="mb-3">
                                                    {/* eslint-disable-next-line */}
                                                    <label htmlFor="category" className="form-label">{selectedCategory == 7 ? "BaU Grid Name" : selectedCategory == 3 ? "BaU Refrigerant Type" : "BaU Fuel Type"}</label>
                                                    <select onChange={e => this.onSelectEmission(e, 0)} value={this.state.bauFactor} className="form-select mb-0">
                                                        {/* eslint-disable-next-line */}
                                                        {this.state.bauFactor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "BaU Grid Name" : selectedCategory == 3 ? "BaU Refrigerant Type" : "BaU Fuel Type"}</option>}
                                                        {optionEmissionFactor}
                                                    </select>
                                                </div>
                                            </div> : "" : ""}
                                        {this.state.selectedReductionCategory > 1 ?
                                            optionEmissionFactor.length > 0 ? <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="category" className="form-label">BaU Unit</label>
                                                    <select onChange={e => this.onSelectUnit(e, 0)} value={this.state.bauUnit} className="form-select mb-0">
                                                        {this.state.bauUnit > 0 ? "" : <option value="0">Select Unit</option>}
                                                        {optionEmissionFactorUnit}
                                                    </select>
                                                </div>
                                            </div> : "" : ""}
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                {/* eslint-disable-next-line */}
                                                <label
                                                    htmlFor="category"
                                                    className="form-label"
                                                >
                                                    {this.state.selectedReductionCategory == 1 ? "BaU Amount (tCO\u2082e)" : "BaU Amount Usage"}
                                                </label>
                                                <InputNumeric
                                                    id="bauAmount"
                                                    name="bauAmount"
                                                    value={this.state.bauAmount}
                                                    onValueChange={this.onInputAmount}
                                                    placeholder={this.props.t('emission.form.enter')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {this.state.selectedReductionCategory > 1 ?
                                            optionEmissionFactor.length > 0 ? <div className="col-md-6">
                                                <div className="mb-3">
                                                    {/* eslint-disable-next-line */}
                                                    <label htmlFor="category" className="form-label">{selectedCategory == 7 ? "Actual Grid Name" : selectedCategory == 3 ? "Actual Refrigerant Type" : "Actual Fuel Type"}</label>
                                                    <select onChange={e => this.onSelectEmission(e, 1)} value={this.state.actualFactor} className="form-select mb-0">
                                                        {/* eslint-disable-next-line */}
                                                        {this.state.actualFactor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "Actual Grid Name" : selectedCategory == 3 ? "Actual Refrigerant Type" : "Actual Fuel Type"}</option>}
                                                        {optionEmissionFactor}
                                                    </select>
                                                </div>
                                            </div> : "" : ""}
                                        {this.state.selectedReductionCategory > 1 ?
                                            optionEmissionFactor.length > 0 ? <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="category" className="form-label">Actual Unit</label>
                                                    <select onChange={e => this.onSelectUnit(e, 1)} value={this.state.actualUnit} className="form-select mb-0">
                                                        {this.state.actualUnit > 0 ? "" : <option value="0">Select Unit</option>}
                                                        {optionEmissionFactorUnit}
                                                    </select>
                                                </div>
                                            </div> : "" : ""}
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                {/* eslint-disable-next-line */}
                                                <label
                                                    htmlFor="category"
                                                    className="form-label"
                                                >
                                                    {this.state.selectedReductionCategory == 1 ? "Actual Amount (tCO\u2082e)" : "Actual Amount"}
                                                </label>
                                                <InputNumeric
                                                    id="actualAmount"
                                                    name="actualAmount"
                                                    value={this.state.actualAmount}
                                                    onValueChange={this.onInputAmount}
                                                    placeholder={this.props.t('emission.form.enter')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-end">
                                                <button onClick={this.onSaveRecord} type="submit" className="btn btn-primary">{this.props.t('general.btnSave')}</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/*this.state.selectedCategory > 0 ? <div className="row">
                                        <div className="col-md-12">
                                            <div className="mt-3 mb-3">
                                                <label htmlFor="companyCategory" className="form-label">Emission Records</label>
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-striped align-middle table-nowrap mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{selectedCategory == 6 || selectedCategory == 4 || selectedCategory == 5 ? "Process Name" : "Asset Name"}</th>
                                                                {optionEmissionFactor.length > 0 ? <th scope="col">{selectedCategory == 7 ? "Grid Name" : selectedCategory == 3 ? "Refrigerant Type" : "Fuel Type"}</th> : ""}
                                                                {optionEmissionFactor.length > 0 ? <th scope="col">Unit</th> : ""}
                                                                <th scope="col">{selectedCategory == 6 || selectedCategory == 4 || selectedCategory == 5 ? "GHG Emissions (tCO\u2082e)" : "Amount"}</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {emissionRows}
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <button onClick={() => this.onInsertRow()} type="button" className="btn btn-ghost-success">+ Add new record</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""*/}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReductionCreate));