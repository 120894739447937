import styles from './CardTitle.module.css'

function Row({
  children,
}) {
  return (
    <h5 className={styles.title}>
      {children}
    </h5>
  )
}

export default Row
