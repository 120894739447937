import { createSlice } from '@reduxjs/toolkit'

export const tourSlice = createSlice({
    name: 'tour',
    initialState: {
        run: false,
        stepIndex: 0,
        tourActive: false,
        emissionTourFromDashboardRun: false,
    },
    reducers: {
        setTourStepIndex: (state, action) => ({
            ...state,
            stepIndex: action.payload,
        }),
        tourComplete: (state) => ({
            ...state,
            run: false,
            stepIndex: 0,
            tourActive: false,
        }),
        tourRestart: (state) => ({
            ...state,
            run: true,
            stepIndex: 0,
        }),
        tourSkip: (state) => ({
            ...state,
            run: false,
            stepIndex: 0,
        }),
        tourStart: (state) => ({
            ...state,
            run: true,
            tourActive: true,
        }),
        tourEmissionFromDashboardStart: (state, action) => ({
            ...state,
            emissionTourFromDashboardRun: action.payload,
        }),
        tourStartAgain: (state, action) => ({
            ...state,
            run: true,
            stepIndex: action.payload,
        }),
        tourStop: (state) => ({
            ...state,
            run: false,
        }),
    }
})

export const {
    setTourStepIndex,
    tourComplete,
    tourRestart,
    tourSkip,
    tourStart,
    tourEmissionFromDashboardStart,
    tourStartAgain,
    tourStop,
} = tourSlice.actions

export default tourSlice.reducer