import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import cookies from 'js-cookie'
import { parseDomain } from 'parse-domain'
import { setDefaultLocale } from 'react-datepicker'

import { setSidebarSize } from '../features/layout/sidebarSizeSlice'
import { setLoggedIn } from '../features/account/loginSlice'

import flagUS from '../images/flags/us.svg'
import flagID from '../images/flags/id.svg'
import avatar from '../images/avatar.png'
import logo from '../images/dark-logo.webp'
import logoSmall from '../images/short-logo.png'

import {
  horizontalHeaderLayout,
} from '../library/helper'

const HeaderSection = ({
  user,
}) => {
  const { t, i18n } = useTranslation()
  const account = useSelector((state) => state.account.value)
  const dispatch = useDispatch()

  useEffect(() => {
    // Change header layout at OnboardFreeTier page
    if (user && user.id && !user.clientId) {
      horizontalHeaderLayout()
    }

    if (user && user.id && !user.clientOnboard) {
      horizontalHeaderLayout()
    }
  }, [user])

  const onUpdateLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setDefaultLocale(lang)
  }

  const onClickHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth

    if (windowSize > 767) {
      document.querySelector(".hamburger-icon").classList.toggle("open")
    }

    // To collapse or expand the vertical sidebar
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize <= 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable")
        if (document.documentElement.getAttribute("data-sidebar-size") === "sm") {
          dispatch(setSidebarSize(''))
        } else {
          dispatch(setSidebarSize('sm'))
        }

      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable")
        if (document.documentElement.getAttribute("data-sidebar-size") === "lg") {
          dispatch(setSidebarSize('sm'))
        } else {
          dispatch(setSidebarSize('lg'))
        }
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable")
        dispatch(setSidebarSize('lg'))
      }
    }
  }

  const selectedLanguageFlag = i18n.language === "id" ? flagID : flagUS
  const baseUrl = `${process.env.REACT_APP_BASE_SERVER}/uploads/client`
  // eslint-disable-next-line
  const avatarSrc = account.client_image != undefined ? `${baseUrl}/${account.client_image}` : avatar

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box horizontal-logo">
              <Link to={"/dashboard"} className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="25" />
                </span>
              </Link>

              <Link to={"/dashboard"} className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="25" />
                </span>
              </Link>
            </div>
            {!!account.client_id && (
              <button
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
                onClick={onClickHamburgerIcon}
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

            )}
          </div>

          <div className="d-flex align-items-center">

            {/*<div className="ms-1 header-item d-none d-sm-flex">
                          <button type="button"
                              className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                              <i className='bx bx-question-mark fs-22'></i>
                          </button>
                      </div>*/}

            <div className="dropdown ms-1 topbar-head-dropdown header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  id="header-lang-img"
                  src={selectedLanguageFlag}
                  alt="Header Language"
                  height="20"
                  className="rounded"
                />
              </button>
              <div className="dropdown-menu dropdown-menu-end">

                <button
                  onClick={() => onUpdateLanguage("en")}
                  className="dropdown-item notify-item language py-2"
                  data-lang="en"
                  title="English"
                >
                  <img
                    src={flagUS}
                    alt="us flag"
                    className="me-2 rounded"
                    height="18"
                  />
                  <span className="align-middle">English</span>
                </button>
                <button
                  onClick={() => onUpdateLanguage("id")}
                  className="dropdown-item notify-item language py-2"
                  data-lang="id"
                  title="Indonesia"
                >
                  <img
                    src={flagID}
                    alt="id flag"
                    className="me-2 rounded"
                    height="18"
                  />
                  <span className="align-middle">Indonesia</span>
                </button>
              </div>
            </div>

            <div className="dropdown ms-sm-3 header-item topbar-user topbar-user-custom">
              <button
                type="button"
                className="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    style={{ objectFit: 'scale-down' }}
                    src={avatarSrc}
                    alt="Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text user-name-text-custom">
                      {/* eslint-disable-next-line */}
                      {account.user_name != undefined ? account.user_name : ((user && user.name) ? user.name : "")}
                    </span>
                    <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                      {/* eslint-disable-next-line */}
                      {account.client_name !== undefined ? account.client_name : ""}
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">
                  {/* eslint-disable-next-line */}
                  {t('menu.account.welcome')} {account.user_name != undefined ? account.user_name : ((user && user.name) ? user.name : "")}!
                </h6>
                <a className="dropdown-item" href="#profile">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">
                    {t('menu.account.label1')}
                  </span>
                  &nbsp;<sup style={{ color: "#4c638c" }}>
                    {t('menu.soon')}
                  </sup>
                </a>
                <a
                  className="dropdown-item"
                  href="https://truclimate.notion.site/truclimate/TruCount-Platform-Guide-9c296e43cfdb425e8892a9050f62301b"
                  target='_blank'
                  rel="noreferrer"
                >
                  <i className="mdi mdi-book-open text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">
                    {t('menu.account.label4')}
                  </span>
                </a>
                <a
                  className="dropdown-item"
                  href="https://truclimate.notion.site/truclimate/TruCount-FAQ-5cee53fee3634a139deba333523eb52d"
                  target='_blank'
                  rel="noreferrer"
                >
                  <i className="mdi mdi-help-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">
                    {t('menu.account.label2')}
                  </span>
                </a>
                <div className="dropdown-divider"></div>
                <button
                  onClick={async () => {
                    const url = process.env.REACT_APP_BASE_SERVER
                    const signedInKey = `${new URL(url).hostname}_signedIn`
                    cookies.remove(signedInKey)
                    const hostname = window.location.hostname
                    const parsedDomain = parseDomain(hostname)
                    const domain = parsedDomain.domain ?? ''
                    const tlds = parsedDomain.topLevelDomains ?? []
                    cookies.remove(signedInKey, {
                      domain: [domain, ...tlds].join('.')
                    })
                    dispatch(setLoggedIn(false))
                  }}
                  className="dropdown-item"
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle" data-key="t-logout">
                    {t('menu.account.label3')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderSection
