import {
  useState,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'

import SingleRowWrapper from '../../components/Dashboard/Layouts/Row'
import MultilineChartCard from '../../components/MultilineChartCard'
import BoxItem from '../../components/Dashboard/Cards/BoxItem'
import Grid from '../../components/Dashboard/Layouts/Grid'

import { twoDecimalFormat } from '../../library/helper'

const Value = ({
  value,
}) => {
  if (!value) {
    return <span style={{ fontSize: '1rem' }}>No Data</span>
  } else {
    return (
      <span>
        {value}
      </span>
    )
  }
}
const ValueAndUnit = ({
  value,
  divisor = null,
  unit = null,
}) => {
  if (!value) {
    return (
      <span style={{ fontSize: '1rem' }}>
        No Data
      </span>
    )
  } else {
    return (
      <>
        <span>
          {value}
        </span>{' '}
        <span
          style={{
            fontSize: "var(--vz-body-font-size)",
            textWrap: "nowrap",
          }}
          className="text-muted"
        >
          {unit ? unit : <span>tCO<sub>2</sub>e{divisor && `/${divisor}`}</span>}
        </span>
      </>
    )
  }
}

const UpperCards = () => {
  const { i18n } = useTranslation()
  const [data, setData] = useState({})

  useEffect(() => {
    const controller = new AbortController()

    const getUpperCardsData = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/carbon-intensity-summaries-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          const {
            revenueUnit,
            year,
            carbonIntensity,
            percentChangeCIYearOverYear,
            tCO2e,
            percentChangeC02YearOverYear,
          } = response.data.data
          setData({
            revenueUnit,
            labelCI: {
              en: `${year} Carbon Intensity`,
              id: `Intensitas Karbon ${year}`,
            },
            valueCI: {
              en: carbonIntensity ? twoDecimalFormat(carbonIntensity, 'en') : null,
              id: carbonIntensity ? twoDecimalFormat(carbonIntensity, 'id') : null,
            },
            labelPercentChangeCI: {
              en: `${year} CI % Change Y/Y`,
              id: `% Perubahan CI Y/Y ${year}`,
            },
            valuePercentChangeCI: {
              en: percentChangeCIYearOverYear ? twoDecimalFormat(percentChangeCIYearOverYear, 'en') : null,
              id: percentChangeCIYearOverYear ? twoDecimalFormat(percentChangeCIYearOverYear, 'id') : null,
            },
            labeltCO2e: {
              en: <span>{year} tCO<sub>2</sub>e</span>,
              id: <span>tCO<sub>2</sub>e {year}</span>,
            },
            valuetCO2e: {
              en: tCO2e ? twoDecimalFormat(tCO2e, 'en') : null,
              id: tCO2e ? twoDecimalFormat(tCO2e, 'id') : null,
            },
            labelPercentChangeCO2: {
              en: <span>{year} CO<sub>2</sub> % Change Y/Y</span>,
              id: <span>% Perubahan CO<sub>2</sub> Y/Y {year}</span>,
            },
            valuePercentChangeCO2: {
              en: percentChangeC02YearOverYear ? twoDecimalFormat(percentChangeC02YearOverYear, 'en') : null,
              id: percentChangeC02YearOverYear ? twoDecimalFormat(percentChangeC02YearOverYear, 'id') : null,
            },
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    getUpperCardsData()

    return () => controller.abort()
  }, [])

  return (
    <Grid
      itemCount={4}
    >
      <BoxItem
        label={data.labelCI?.[i18n.language]}
        value={(
          <ValueAndUnit
            value={data.valueCI?.[i18n.language]}
            divisor={data.revenueUnit}
          />
        )}
      />
      <BoxItem
        label={data.labelPercentChangeCI?.[i18n.language]}
        value={(
          <ValueAndUnit
            value={data.valuePercentChangeCI?.[i18n.language]}
            unit="%"
          />
        )}
      />
      <BoxItem
        label={data.labeltCO2e?.[i18n.language]}
        value={(
          <Value
            value={data.valuetCO2e?.[i18n.language]}
          />
        )}
      />
      <BoxItem
        label={data.labelPercentChangeCO2?.[i18n.language]}
        value={(
          <ValueAndUnit
            value={data.valuePercentChangeCO2?.[i18n.language]}
            unit="%"
          />
        )}
      />
    </Grid>
  )
}

const onDownloadImage = async (element, label) => {
  const canvas = await html2canvas(document.getElementById(element))
  const image = canvas.toDataURL('image/png', 1.0)

  const fakeLink = window.document.createElement('a')
  fakeLink.style = 'display:none'
  fakeLink.download = label

  fakeLink.href = image

  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)

  fakeLink.remove()
}

function ScenarioAnalysis() {
  const account = useSelector((state) => state.account.value)
  const { t } = useTranslation()

  const [startYear, setStartYear] = useState(null)
  const [endYear, setEndYear] = useState(null)

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartYear(newStartDate)
    setEndYear(newEndDate)
    if (
      moment(newStartDate, 'YYYY-MM-DD').isValid() &&
      moment(newEndDate, 'YYYY-MM-DD').isValid() &&
      newStartDate < newEndDate
    ) {
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    const getYearRange = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/scenario-analysis-initial-year-range-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setStartYear(new Date(`${response.data.data.startYear}/01/01`))
          setEndYear(new Date(`${response.data.data.startYear + 10}/01/01`))
        }
      } catch (e) {
        if (
          !e.response?.data?.ok &&
          e.response?.data?.notFound
        ) {
          Swal.fire({
            title: '',
            text: e.response.data.message,
            icon: 'error',
            confirmButtonColor: '#4c638c',
          })
        }
      }
    }
    getYearRange()

    return () => controller.abort()
  }, [])

  return (
    <div className='page-content'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-12'>
            <div className='align-items-center d-flex justify-content-between mb-4'>
              <h4 className='mb-sm-0' style={{ color: '#09564B' }}>
                {t('analytic.carbonIntensityCI')}
              </h4>
              <div style={{ width: '8.5rem' }}>
                <DatePicker
                  style={{ width: 'auto' }}
                  onChange={handleChange}
                  selectsRange
                  startDate={startYear}
                  endDate={endYear}
                  dateFormat="yyyy"
                  className="form-select"
                  showYearPicker
                  onChangeRaw={e => {
                    if (e.target.value) {
                      const dates = e.target.value.split(' - ').map(date => {
                        const d = new Date(date);
                        return !isNaN(d) ? d : null;
                      });
                      if (
                        moment(dates[0], 'YYYY-MM-DD').isValid() &&
                        moment(dates[1], 'YYYY-MM-DD').isValid() &&
                        dates[0] < dates[1]
                      ) {
                        setStartYear(dates[0])
                        setEndYear(dates[1])
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {account['client_subscription'] > 1 && (
          <>
            <UpperCards />
            <SingleRowWrapper>
              <MultilineChartCard
                chartId='carbonIntensityVsTarget'
                onDownloadImage={onDownloadImage}
                startYear={startYear ? new Date(startYear).getFullYear() : null}
                endYear={endYear ? new Date(endYear).getFullYear() : null}
              />
            </SingleRowWrapper>
          </>
        )}

      </div>
    </div >
  )
}

export default ScenarioAnalysis
