import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'

import BarChartLegend from '../ApexCharts/BarChartLegend'
import Download from '../Download'

import {
  CHART_COLORS,
  EMPTY_CHART_COLORS,
  twoDecimalFormat,
} from '../../library/helper'

const emptySeries = [
  {
    "name": "Actual Emission",
    "nameId": "Emisi Aktual",
    "data": [
      117.26,
      55.11,
      78.56,
      80,
      120.03,
      58.92,
      null,
      null,
      null,
      58.92,
      180.83,
      150.05,
    ]
  },
  {
    "name": "Reduction",
    "nameId": "Penurunan",
    "data": [
      null,
      -55.11,
      -78.56,
      -80,
      -120.03,
      null,
      -4,
      -120.03,
      -80,
      -78.56,
      null,
      null,
    ]
  },
]

const emptyXaxisCategories = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]

const NoData = () => {
  return (
    <div className='d-flex flex-column gap-2 pb-3 px-3'
    >
      <div style={{
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '0.5rem',
        borderRadius: 14,
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        alignSelf: 'center',
        top: '66%',
        left: '50%',

      }}>
        No Data
      </div>
    </div>
  )
}

function EmissionVsReduction(props) {
  const {
    chartId,
    filterLabel,
    onDownloadImage,
    startYear,
    endYear,
  } = props
  const { t, i18n } = useTranslation()

  const [keys, setKeys] = useState([])
  const [data, setData] = useState()
  const [axisBottom, setAxisBottom] = useState([])
  const [rawData, setRawData] = useState([])
  const [hiddenSeries, setHiddenSeries] = useState([])

  useEffect(() => {
    const controller = new AbortController()

    const getData = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            startYear,
            endYear,
            gwp: new URLSearchParams(window.location.search).get('gwp'),
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/record-vs-reduction-summaries-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setData(response.data.data.dataChart)
          setKeys(response.data.data.categories)
          setAxisBottom(response.data.data.axisBottom)
          setRawData(response.data.data.rawData)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (startYear && endYear) {
      getData()
    }

    return () => controller.abort()
  }, [startYear, endYear])

  const onDownload = () => {
    onDownloadImage('emissionVsReduction', 'actual-emission-vs-reduction')
  }

  const options = {
    chart: {
      id: 'chartEmissionVsReduction',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: [CHART_COLORS[0], CHART_COLORS[1]],
    xaxis: {
      type: 'year',
      categories: axisBottom,
      labels: {
        show: true,
      },
    },
    yaxis: {
      title: {
        text: 'tCO₂e',
      },
      tickAmount: 4,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val ? twoDecimalFormat(val, i18n.language) + " tCO₂e" : null
        },
      }
    },
    fill: {
      opacity: 1
    },
  }
  const emptyOptions = {
    ...options,
    colors: EMPTY_CHART_COLORS,
    xaxis: {
      ...options.xaxis,
      categories: emptyXaxisCategories,
      labels: {
        show: false,
      },
    },
    tooltip: {
      ...options.tooltip,
      enabled: false,
    },
    chart: {
      ...options.chart,
      zoom: {
        enable: false,
      }
    }
  }

  const series = (
    keys?.length > 0 &&
    axisBottom.length > 0 &&
    (
      rawData.emission.length > 0 ||
      rawData.reduction.length > 0
    )
  )
    ? [
      {
        name: keys.find(k => k.categoryId === 1)?.categoryName || 'series-1',
        nameId: keys.find(k => k.categoryId === 1)?.categoryNameId || 'series-1',
        data: axisBottom.map(year => {
          const findAxisBottom = rawData.emission.find(emission => emission.axisBottom === year)
          if (findAxisBottom) {
            return parseFloat(findAxisBottom.total.toFixed(2))
          } else {
            return null
          }
        }),
      },
      {
        name: keys.find(k => k.categoryId === 2)?.categoryName || 'series-2',
        nameId: keys.find(k => k.categoryId === 2)?.categoryNameId || 'series-2',
        data: axisBottom.map(year => {
          const findAxisBottom = rawData.reduction.find(emission => emission.axisBottom === year)
          if (findAxisBottom) {
            return parseFloat(findAxisBottom.total.toFixed(2)) * -1
          } else {
            return null
          }
        }),
      },
    ]
    : []

  const FilterSeries = (seriesName = "") => {
    if (series.every(item => item?.name === undefined)) return

    ApexCharts.getChartByID(options?.chart?.id).toggleSeries(seriesName)

    let currentData = [...hiddenSeries]
    const checkIndex = currentData.findIndex(item => item === seriesName)

    if (checkIndex < 0) currentData.push(seriesName)
    else currentData.splice(checkIndex, 1)

    setHiddenSeries(currentData)
  }

  return (
    <div
      className='bg-white d-flex flex-column gap-3'
      style={{
        borderRadius: '8px',
        paddingLeft: '0.5rem',
        width: '100%',
      }}
      id={chartId}
    >
      <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
        <p
          className='fw-semibold m-0'
          style={{ fontSize: 12 }}
        >
          {t('scenario.label2')}
        </p>
        <Download
          onClick={onDownload}
          isDisabled={!Array.isArray(data) || !data.length}
        />
      </div>
      <div className='px-3 position-relative'>
        <ReactApexChart
          options={series.length > 0 ? options : emptyOptions}
          series={series.length > 0 ? series : emptySeries}
          type='bar'
          height={465}
        />
        {series.length === 0 && <NoData />}
      </div>
      {
        series.length > 0 && (
          <BarChartLegend
            series={series}
            filterSeries={FilterSeries}
            hiddenSeries={hiddenSeries}
          />
        )
      }
    </div>
  )
}

export default EmissionVsReduction
