import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { MRT_Localization_EN } from "material-react-table/locales/en"
import { MRT_Localization_ID } from "material-react-table/locales/id"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ImNewTab } from "react-icons/im"
import useHttpRequest from "../hooks/useRequest"

const EmissionSources = () => {
  const { t, i18n } = useTranslation()
  const { response, setRequest } = useHttpRequest({
    url: `${process.env.REACT_APP_BASE_SERVER}/own-emission-sources-lookup`,
    method: "GET",
  })
  useEffect(() => setRequest({}), [setRequest])
  // const [emissionSources, setEmissionSources] = useState(
  //   Array.from({ length: 9 }, (_, index) => ({
  //     cfId: index,
  //     categoryId: index,
  //     categoryName: 'lessgoo',
  //     categoryNameId: 'lessgoo',
  //     emissionFactorId: index,
  //     emissionName: 'lessgoo',
  //     references: [
  //       { label: 'lessgoo' },
  //       { label: 'lessgoo' },
  //       { label: 'lessgoo' },
  //       { label: 'lessgoo' }
  //     ]
  //   }))
  // )
  const emissionSources = response?.data ?? []
  // const [filterKeyword, setFilterKeyword] = useState("")
  // const filteredEmissionSources =
  //   emissionSources?.filter((item) => {
  //     return (
  //       (item.category_name &&
  //         item.category_name
  //           .toLowerCase()
  //           .includes(filterKeyword.toLowerCase())) ||
  //       (item.category_name_id &&
  //         item.category_name_id
  //           .toLowerCase()
  //           .includes(filterKeyword.toLowerCase())) ||
  //       (item.emission_name &&
  //         item.emission_name
  //           .toLowerCase()
  //           .includes(filterKeyword.toLowerCase())) ||
  //       (item.references &&
  //         item.references.length &&
  //         item.references.some((reference) => {
  //           return reference.label
  //             ?.toLowerCase()
  //             ?.includes(filterKeyword.toLowerCase())
  //         }))
  //     )
  //   }) ?? []

  const columns = useMemo(() => {
    return [
      {
        header: t("emission.sources.table.source"),
        id: 'source',
        accessorKey: "emission_name", // simple recommended way to define a column
        size: 100,
      },
      {
        header: t("emission.sources.table.category"),
        id: 'category',
        accessorKey:
          i18n.language === "id" ? "category_name_id" : "category_name",
      },
      {
        header: t("emission.sources.table.conversion"),
        id: 'conversion',
        accessorKey: "conversion_rate",
      },
      {
        header: t("emission.sources.table.reference"),
        id: 'references',
        accessorFn: (row) => {
          return row.references.map(reference => reference.label).join(' ')
        },
        Cell: ({ row }) => {
          return <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {row.original.references
              ?.filter((reference) => {
                return reference.label?.trim() && reference.link?.trim()
              })
              ?.map((reference, index) => {
                return (
                  <a
                    key={index}
                    href={reference.link}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <span
                      style={{
                        display: "flex",
                        gap: "0.25rem",
                        alignItems: "center",
                      }}
                    >
                      {
                        i18n.language === "id"
                          ? reference.label
                          : reference.label
                      }
                      <ImNewTab />
                    </span>
                  </a>
                )
              })}
          </div>
        },
      }
    ]
  }, [t, i18n.language])

  const table = useMaterialReactTable({
    columns,
    data: emissionSources,
    enableGrouping: true,
    groupedColumnMode: 'reorder',
    enableColumnOrdering: true, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? MRT_Localization_ID : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
    },
  })

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{t("emission.sources.title")}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row gy-5">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-bill-info"
                    role="tabpanel"
                    aria-labelledby="v-pills-bill-info-tab"
                  >
                    <MaterialReactTable table={table} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmissionSources
