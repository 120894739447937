import { useState, useEffect } from 'react'
import axios from 'axios'

function useHttpRequest({ url, method }) {
  const [response, setResponse] = useState(null)
  const [request, setRequest] = useState(null)

  useEffect(() => {
    setResponse({
      ...response,
      loading: true,
    })

    const source = axios.CancelToken.source()
    axios({
      url,
      method,
      withCredentials: true,
      ...(method.toLowerCase() === 'get' && { params: request }),
      ...(method.toLowerCase() === 'post' && { data: request }),
      cancelToken: source.token,
    }).then(({ status, data }) => {
      setResponse({
        ...response,
        loading: false,
        status,
        ...data,
        cause: undefined,
      })
    }).catch(error => {
      setResponse({
        ...response,
        loading: false,
        status: 500,
        message: 'sorry, something went wrong',
        cause: error,
      })
    })

    return () => {
      source.cancel()
    }
  }, [url, method])

  return { response, setRequest }
}

export default useHttpRequest