import {
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  AREA_CHART_COLORS,
  ROUTE_PATHS,
} from '../../library/helper'


const ScenarioLegendItem = ({
  index,
  filterSeries,
  hiddenSeries,
  item,
}) => {
  return (
    <div
      key={index}
      className='d-flex gap-2 cursor-pointer pb-1 px-1 gap-2'
      onClick={() => filterSeries(item?.name)}
    >
      <div
        style={{
          backgroundColor: hiddenSeries?.includes(item?.name)
            ? 'transparent'
            : AREA_CHART_COLORS?.[index],
          border: hiddenSeries?.includes(item?.name)
            ? `1px solid ${AREA_CHART_COLORS?.[index]}`
            : 'none',
          borderRadius: '4px',
          height: 12,
          marginTop: 2,
          minHeight: 12,
          minWidth: 12,
          width: 12,
        }}
      >
      </div>
      <p
        className='flex-grow-1 m-0'
        style={{ fontSize: 10 }}
      >
        {item?.name}
      </p>
    </div>
  )
}
export default function MultilineChartLegend({
  series,
  filterSeries,
  hiddenSeries,
  onDashboard,
  lines,
  averageGrowthRate,
  setGrowthRate,
}) {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const account = useSelector((state) => state.account.value)
  const [localGrowthRate, setLocalGrowthRate] = useState(averageGrowthRate)

  const onInputPercentage = (values) => {
    setLocalGrowthRate(values.value)
  }

  const onSubmitGrowthRate = () => {
    setGrowthRate(localGrowthRate)
  }

  if (
    onDashboard ||
    location.pathname === ROUTE_PATHS.analyticsCarbonIntensity
  ) {
    return (
      <div
        className='d-flex justify-content-center gap-2 pb-3 px-3'
      >
        {
          series.map((item, index) => {
            return (
              <div
                key={index}
                className='d-flex gap-2 cursor-pointer'
                onClick={() => filterSeries(item?.name)}
              >
                <div
                  style={{
                    backgroundColor: hiddenSeries?.includes(item?.name)
                      ? 'transparent'
                      : AREA_CHART_COLORS?.[index],
                    border: hiddenSeries?.includes(item?.name)
                      ? `1px solid ${AREA_CHART_COLORS?.[index]}`
                      : 'none',
                    borderRadius: '4px',
                    height: 12,
                    marginTop: 2,
                    minHeight: 12,
                    minWidth: 12,
                    width: 12,
                  }}
                >
                </div>
                <p
                  className='flex-grow-1 m-0'
                  style={{ fontSize: 10 }}
                >
                  {item?.name}
                </p>
              </div>
            )
          })
        }
      </div>

    )
  } else {
    const group1 = lines.filter(line => line.group === 1)
    const group2 = lines.filter(line => line.group === 2)
    const group3 = lines.filter(line => line.group === 3)
    const seriesGroup1 = series.filter(s => group1.some(line1 => line1.label === s.name))
    const seriesGroup2 = series.filter(s => group2.some(line1 => line1.label === s.name))
    const seriesGroup3 = series.filter(s => group3.some(line1 => line1.label === s.name))
    return (
      <div
        className='pb-3 px-3 gap-2'
        style={{
          display: 'flex',
          gap: '1rem',
          margin: '1rem 0',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              fontSize: '11px',
            }}
          >
            <div className="d-flex">
              <div className='d-flex gap-2 pb-1 px-1 gap-2'>
                <p
                  className='flex-grow-1 m-0'
                  style={{ fontSize: 10, fontWeight: 500 }}
                >
                  {account.client_baseline} {t('setting.scenario.baselineYear')}
                </p>
              </div>
            </div>
          </div>
          {
            seriesGroup1.map((item, index) => {
              const colorIndex = series.findIndex(s => s.name === item.name)
              return <ScenarioLegendItem
                item={item}
                key={index}
                index={colorIndex}
                filterSeries={filterSeries}
                hiddenSeries={hiddenSeries}
              />
            })
          }
        </div>
        <div>
          {
            seriesGroup2.map((item, index) => {
              const colorIndex = series.findIndex(s => s.name === item.name)
              return <ScenarioLegendItem
                item={item}
                key={index}
                index={colorIndex}
                filterSeries={filterSeries}
                hiddenSeries={hiddenSeries}
              />
            })
          }
        </div>
        {
          seriesGroup3.length > 0 && (
            <div>
              {
                seriesGroup3.map((item, index) => {
                  const colorIndex = series.findIndex(s => s.name === item.name)
                  return <ScenarioLegendItem
                    item={item}
                    key={index}
                    index={colorIndex}
                    filterSeries={filterSeries}
                    hiddenSeries={hiddenSeries}
                  />
                })
              }
            </div>
          )
        }
        <div className="row justify-content-end" style={{ position: 'relative' }}>
          <div style={{ top: '-3px' }}>
            <div className='d-flex align-items-end gap-2'>
              <div>
                <label htmlFor="amount" className="form-label" style={{ fontSize: '10px' }}>
                  {t('scenario.growthRate')} (%)
                </label>
                <NumericFormat
                  value={localGrowthRate} // 10003.21 would be 10.003,21 in ID and 10,003.21 in US format
                  allowNegative
                  className="form-control"
                  style={{ width: '100%', padding: '0.3rem 1.1rem' }}
                  onValueChange={onInputPercentage}
                  decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                  thousandSeparator={i18n.language === 'id' ? '.' : ','}
                />
              </div>
              <button
                onClick={onSubmitGrowthRate}
                type="button"
                className="btn btn-icon btn-soft-primary"
                data-toggle="tooltip"
                data-placement="top"
                title={t('scenario.showProjection')}
                style={{
                  padding: '0.3rem 1.1rem',
                  height: 'calc(1rem + 1.1em + 2px)',
                  width: 'calc(1rem + 1.1em + 2px)',
                }}
              >
                <i className="ri-line-chart-line"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}